import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { LEFTRIGHT_PADDING } from "../config/style.config";
import TitleLeftSvg from '../svgs/TitleLeftSvg'
//svg
import BluePlus from '../svgs/permit/BluePlus'
//componets
import SectionCard from '../components/permit/SectionCard'
import FieldCard from '../components/permit/FieldCard';
import GenerateForm from '../components/permit/display/GenerateForm';
import FormStatus from '../components/permit/display/FormStatus';
import Loading from '../components/Loading'
//
import {postTemplateData, postTemplateDocument, putTemplateData} from '../services/permit.service'
import {deleteFileByName} from '../services/httpApiTracking'
import { PERMIT_FIELD_TYPE } from '../dto/permit';
const formSection = () => {
    return {
    sectionTitle:'',
    id:'',
    sectionDescription:'',
    permission:'',
    data:[]
    }
}
const formField = () => {
    return {
    fieldId:'',
    type:'',
    required:false,
    fullWidth:true,
    fieldData:{},
    }
}
function Screen(props) {
    const navigate = useNavigate();
    const param = useLocation()
    const [templateData, setTemplateData] = useState((param?.state?.data))
    const [formData, setFormData] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)
    const [previewPop, setPreviewPop] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    useEffect(() => {
        if(!param?.state) return navigate('/permit/template')    
        setTemplateData((param.state.data))
        if(param.state?.isEdit){
            setFormData(param.state.data.form)
        }else if(firstLoad){
            handleAddSection()
        }
        setFirstLoad(false)
    },[])
    // useEffect(() => {
    //     console.log(templateData)
    //     console.log(formData)
    // },[formData])
    function whenSubmitMissing(msg){
        setErrorMsg(msg)
        setIsLoading(false)
        setPreviewPop(false)
    }
    async function sumbitData(){
        const imgPostArr = []
        setIsLoading(true)
        const data = {...templateData}
        data.form = formData
        data.projectId = `${data.projectId}`
        let secIdx = 0
        let fieldIdx = 0
        for(const section of data.form){
            if(section.permission === ''){
                return whenSubmitMissing(`Section ${secIdx + 1} heading missing permission.`)
            }
            if(section.sectionTitle === ''){
                return whenSubmitMissing(`Section ${secIdx + 1} heading missing title.`)
            }
            for(const field of section.data){
                if(field.type === 'text input'){
                    if(field.fieldData.textInputTitle === ''){ 
                        return whenSubmitMissing(`Section ${secIdx + 1} Text input field missing title `)
                    }
                }else if(field.type === 'Table'){
                    if(field.tableTitle === ''){
                        return whenSubmitMissing(`Section ${secIdx + 1} table missing title `)
                    } 
                }else if(field.type === 'Image'){
                    if(field.fieldData.imageTitle === '' || field.fieldData.imageTitle === undefined) {
                        return whenSubmitMissing(`Section ${secIdx + 1} image missing title `)
                    }
                }else if(field.type === 'Dropdown' || field.type === 'Select Box'){
                    if(field.fieldData.title === '' || field.fieldData.title === undefined) {
                        return whenSubmitMissing(`Section ${secIdx + 1} ${field.type} missing title `)
                    }
                    if(field.fieldData.options === '' || field.fieldData.options === undefined){
                        return whenSubmitMissing(`Section ${secIdx + 1} ${field.type} missing options`)
                    }
                }else if(field.type === 'Title & Description' || field.type === 'Date'){
                    if(field.fieldData.title === '' || field.fieldData.title === undefined) {
                        return whenSubmitMissing(`Section ${secIdx + 1} ${field.type} missing title `)
                    } 
                }else if(field.type === PERMIT_FIELD_TYPE[6]){
                    if(field.fieldData.title === ''){
                        return whenSubmitMissing(`Section ${secIdx + 1} worker information list missing title `)
                    } 
                }
                //handle delete image and combine new uploads to old
                if(field.type === 'Image'){
                    if(param.state?.isEdit){
                        if(field.fieldData?.toDelete.length > 0){
                            field.fieldData.toDelete.forEach(async (obj) => {
                                const res = await deleteFileByName(props.token.token,obj.id+'.'+obj.type.split('/')[1])
                                if(res.errno) return console.log('error deleting img')
                            })
                        }
                    }
                    if(field.fieldData.newImgId.length > 0){
                        let k = 0
                        for(const file of field.fieldData.formData.entries()){
                            const {id, type} = field.fieldData.newImgId[k]
                            const res = await postTemplateDocument(props.token.token,file,id,type)
                            k++
                            if(res.errno)console.log('error posting file')
                        }
                    }
                    field.fieldData.imgId = [...field.fieldData.imgId, ...field.fieldData.newImgId]
                    delete field.fieldData.newImgId
                    delete field.fieldData.toDelete
                }
                fieldIdx++
            }
            fieldIdx = 0
            secIdx++
        }
        const finalData = {}
        finalData.form = data.form
        finalData.name = data.name
        finalData.projectId = data.projectId
        finalData.type = data.type
        let res = undefined
        if(param.state?.isEdit){
            res = await putTemplateData(props.token.token, finalData, data._id)
        }else{
            res = await postTemplateData(props.token.token, finalData)
        }
        if(res.errno) return console.log('error posting')
        // console.log('imgPostArr', imgPostArr)
        // console.log('final data', finalData)
        setIsLoading(false)
        navigate('/permit/template')
    }
    function handleAddSection(i){
        const newSection = formSection()
        // console.log(newSection, 'newSection')
        const time = new Date()
        newSection.id = time.getTime()
        if(i === undefined) return setFormData([...formData,newSection])
        const temp = [...formData]
        temp.splice(i + 1, 0, newSection)
        setFormData(temp)
    }
    function handleAddFieldSection(i, type){
        const temp = [...formData]
        const newField = formField()
        const time = new Date()
        newField.type = type
        newField.fieldId = time.getTime()
        temp[i].data.push(newField)
        setFormData(temp)
    }
    function handleSectionChange(e, i, key){
        const temp = [...formData]
        if(key === 'permission'){
            // e here is just a string look in sectionCard componet
            temp[i].permission = e
        }else{
            temp[i][key] = e.target.value
        }
        setFormData(temp)
    }
    function handleUpdateData(i, k, key, val){
        const temp = [...formData]
        temp[i].data[k][key] = val
        if(key === 'type' && val === 'Table'){
            temp[i].data[k].fieldData = []
        }
        if(key === 'type'){
            // defult setting if changing type
            temp[i].data[k].fullWidth = true
            temp[i].data[k].required = false
        }
        if(key === 'tableTitle'){
            temp[i].data[k] = val
        }
        setFormData(temp)
    }
    //
    function handleSectionReorder(direction, i){
        const temp = [...formData]
        const copy = {...temp[i]}
        let targetIdx = direction === 'up' ? i-1 : i+1
        const target = {...temp[targetIdx]}
        temp[i] = target
        temp[targetIdx] = copy
        setFormData(temp)
    }
    function handleFieldReorder(direction, i, k){
        const temp = [...formData]
        const targetSection = temp[i]
        const copy = {...targetSection.data[k]}
        const targetIdx = direction === 'up' ? k-1 : k+1
        const targetObj = {...targetSection.data[targetIdx]}
        targetSection.data[k] = targetObj
        targetSection.data[targetIdx] = copy
        temp[i] = targetSection
        setFormData(temp)
    }
    function handleSectionDelete(idx){
        const temp = [...formData]
        temp.splice(idx, 1)
        setFormData(temp)

    }
    function handleFieldDelete(i, k){
        const temp = [...formData]
        temp[i].data.splice(k,1)
        setFormData(temp)
    }
    return(       
        <div className='w-full h-full bg-[#0C0D14] text-white pb-12' style={{overflowY:'auto'}}>
            {!previewPop && <div style={{...LEFTRIGHT_PADDING}} className='w-full max-w-[1265px]'>
                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[40px]">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold">Template Title: {(templateData?.templateName || templateData?.name)}</div>
                    </div>
                    <div className='flex flex-row items-center justify-center gap-4'>
                        <div onClick={() => navigate('/permit/template')} className='py-[9px] px-[16px] bg-[#FFFFFF26] text-white rounded-full select-none cursor-pointer'>Cancel</div>
                        <div onClick={() => {setPreviewPop(true)}} className='py-[9px] px-[16px] bg-[#00202A] text-[#63BDFF] rounded-full select-none cursor-pointer'>Next</div>
                    </div>
                </div>
                <div className='flex flex-col pt-[16px]'>
                {errorMsg !== '' && <div className='text-[16px] text-[#ff0000]'>{errorMsg}</div>}
                    {formData && formData.map((obj, i) => (
                        <div key={obj.id + i + 'section'} className='pb-[24px] mb-[24px]' style={{borderBottomWidth:0, borderBottomColor:'#FFFFFF80'}}>
                            <SectionCard handleSectionDelete={handleSectionDelete} i={i} len = {formData.length} handleSectionChange={handleSectionChange} obj={obj} handleSectionReorder={handleSectionReorder}/>
                            {/* below is the field within the section */}
                            {obj.data && obj.data.map((fieldObj, k) => {
                                return <FieldCard isEdit={param.state?.isEdit} sectionId={obj.id} key={i + k + 'fieldCard'} handleFieldDelete={handleFieldDelete} i={i} k={k} len={obj.data.length} fieldObj={fieldObj} handleUpdateData={handleUpdateData} handleFieldReorder={handleFieldReorder}/>
                            })}
                            {/* control to add field within section */}
                            <div className='flex flex-row gap-2 mt-[48px]'>
                                <div onClick={() => {handleAddSection(i)}} className='py-[2px] px-[16px] bg-[#00202A] flex flex-row items-center rounded-full select-none cursor-pointer'><BluePlus fill={'#FFFFFF'}/>Add Section</div>
                                <div onClick={() => {handleAddFieldSection(i, 'Text input')}} className='py-[2px] px-[16px] bg-[#00202A] flex flex-row items-center rounded-full select-none cursor-pointer'><BluePlus fill={'#FFFFFF'}/>Add Field</div>
                                <div onClick={() => {handleAddFieldSection(i, 'Image')}} className='py-[2px] px-[16px] bg-[#00202A] flex flex-row items-center rounded-full select-none cursor-pointer'><BluePlus fill={'#FFFFFF'}/>Add Image</div>
                            </div>
                            <div className='px-[24px] my-[48px]'><div className='h-[1px] bg-[#FFFFFF80]'></div></div>
                        </div>
                    ))}
                </div>
                <div onClick={() => {handleAddSection()}} className='px-[16px] bg-[#00202A] text-[#63BDFF] flex flex-row items-center rounded-full max-w-[150px] select-none cursor-pointer'><BluePlus />Add Section</div>
            </div>}
            {previewPop && <div style={{...LEFTRIGHT_PADDING}} className='flex flex-col'>
                {isLoading && <div className='select-none opacity-40 w-[100vw] h-[100vh] fixed inset-0 bg-[#000000] flex flex-row items-center justify-center' style={{zIndex:99}}>
                    <Loading />
                </div>}
                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row items-center justify-between mt-[40px] w-full">
                        <div className='flex flex-row items-center'>
                            <TitleLeftSvg className='mr-[9px]'/>
                            <div className="text-[24px] text-white font-bold">Preview - {(templateData?.templateName || templateData?.name)}<span className='text-[14px] text-[#949494]'> | {templateData.type}</span></div>
                        </div>
                        <div className='flex flex-row items-center justify-center gap-4'>
                            <div onClick={() => setPreviewPop(false)} className='py-[2px] px-[16px] bg-[#FFFFFF26] text-white rounded-full select-none cursor-pointer'>Back to edit</div>
                            <div onClick={() => {sumbitData()}} className='py-[2px] px-[16px] bg-[#00202A] text-[#63BDFF] rounded-full select-none cursor-pointer'>Confirm</div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex flex-row'>
                    <div className='w-full max-w-[1265px]'>
                        <GenerateForm form={formData} isPreview={true}/>
                    </div>
                    <div className='opacity-40 flex-1 h-full border border-[#FFFFFF80] py-[24px] px-[20px] mt-[38px]'>
                        <FormStatus isPreview={true}/>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)