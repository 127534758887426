import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
function SmartWatch(){
    const navigate = useNavigate();
    return(
        <div>
            Live View
            <div className="cursor-pointer" onClick={() => navigate('/live/alert')} style={{border: '1px solid red'}}>Smart lock p2</div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartWatch)