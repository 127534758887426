import { useEffect, useState } from "react"
import WindySvg from '../../svgs/map/Windy';
import RainFall from '../../svgs/weather/RainFall'
import HumiditySvg from '../../svgs/map/Humidity';
import {weatherConfig} from '../../config/wather.config'
const weatherStatusObj = {
    50:'Sunny',
    51:'Sunny Periods',
    52:'Sunny Intervals',
    53:'Sunny With Few Showers',
    54:'Sunny With Showers',
    60:'Cloudy',
    61:'Overcast',
    62:'Light Rain',
    63:'Rain',
    64:'Heavy Rain',
    65:'Thunderstorm',
    70:'Fine',
    71:'Fine',
    72:'Fine',
    73:'Fine',
    74:'Fine',
    75:'Fine',
    76:'Mainly Cloudy',
    77:'Mainly Fine',
    80:'Windy',
    81:'Dry',
    82:'Humid',
    83:'Fog',
    84:'Mist',
    85:'Haze',
}

export default function Component(props){
    const [isExpanded,setisExpanded]=useState(true)
    const [weatherData, setWeatherData] = useState(undefined)
    const [now,setNow]=useState(new Date())

    useEffect(()=>{
        function handleWindowResize() {setPosition({x:window.innerWidth-300,y:16})}
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])


    useEffect(()=>{
        const timeInterval= setInterval(()=>{setNow(new Date())},1000*30) 
        return ()=>{clearInterval(timeInterval)}
    },[])
    const [position,setPosition]=useState({x:window.innerWidth-300,y:16})
    const [delta,setDelta]=useState({x:0,y:0})
    const [currRain, setCurrRain] = useState('')
    const [currTemp, setCurrTemp] = useState('')
    const [warning, setWarning] = useState('')
    const [weatherIconsArr, setWeatherIconsArr] = useState([])
    const handleDrag=(e)=>{
        let newX=e.clientX - delta.x  ;
        let newY=e.clientY - delta.y;

        setPosition({
            x:newX<0?0:newX>window.innerWidth-50?window.innerWidth-50:newX ,
            y:newY<0?0:newY>window.innerHeight-50?window.innerHeight-50:newY ,
        })
    }
    const handleDragStart=(e)=>{setDelta({x:e.clientX-position.x,y:e.clientY-position.y})}

    useEffect(() => {
        getWeatherData()
        const timer = setInterval(() => getWeatherData(), 6000000)
        return clearInterval(timer)
    },[])

    async function getWeatherData(){
        const res = await fetch('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en')
        const warningRes = await fetch('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=warningInfo&lang=en')
        const data = await res.json()
        const warnData = await warningRes.json()
        // console.log(data, warnData)
        if(warnData.details !== undefined){
            let messArr = []
            let weatherIcon = []
            warnData.details?.forEach((obj,i) => {
                if(obj.subtype !== undefined){
                    weatherIcon.push(obj.subtype)

                }else if(obj.warningStatementCode!== undefined){
                    weatherIcon.push(obj.warningStatementCode)
                }
                messArr.push(<div key={'warning1' + i} className="mb-2">-{obj.contents.join('. ')}</div>)
            })
            setWarning(messArr)
            setWeatherIconsArr(weatherIcon)
        }
        // if(data.warningMessage){
        //     console.log('here')
        //     let messArr = []
        //     data.warningMessage.forEach((str, i) => {
        //         messArr.push(<div key={'warning' + i} className="mb-2">-›{str}</div>)
        //     })  
        //     setWarning(messArr)
        // }
        for(const [key, val] of Object.entries(data)){
            if(key === 'rainfall'){
                val.data?.forEach((obj) => {
                    if(obj.place === weatherConfig.CURRENT_LOCATION_RAIN) setCurrRain(obj.max)
                })
            }
            if(key === 'temperature'){
                val.data?.forEach((obj) => {
                    if(obj.place === weatherConfig.CURRENT_LOCATION_TEMP) setCurrTemp(obj.value)
                })
            }
        }
        setWeatherData(data)
    }
    if(props.nav === true){
        return(
            <>
                <div className='flex flex-row items-center'>
                    <img className='mr-1' src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${weatherData?.icon[0]}.png`} style={{width:22}}  alt='weather'/>
                    <div className="flex flex-row items-center text-center text-white text-[12px]">{currTemp}°</div>
                </div>
                <div className="text-[10px]">{props.now.getHours()>12?props.now.getHours()-12:props.now.getHours()}:{props.now.getMinutes()} {props.now.getHours()>12?'PM':'AM'}</div>
            </>
        )
    }

    return(
        <div 
            onDragEnd={handleDrag} onDragStart={handleDragStart}
            draggable={true}
            className="absolute w-[260px]  bg-black flex flex-col items-center px-[16px] py-[8px] text-white cursor-pointer" 
            style={{
                left:position.x,top:position.y,
                border:'1px solid #ffffff60',transition:'1s all ease',borderRadius:isExpanded?8:28,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            <div className="w-full flex flex-row items-center">
                <svg width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="2.39372e-08" x2="0.499999" y2="20" stroke="white"/>
                </svg>
                <svg className='ml-[4px]' width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.5" y1="-7.8056e-08" x2="1.5" y2="12" stroke="white" strokeWidth="3"/>
                </svg>

                <div className="flex-1 font-bold ml-[16px] text-xs" style={{}}>Weather</div>

                <div onClick={()=>setisExpanded(!isExpanded)}>
                {isExpanded?
                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.20703 9.39949L9.89941 0.707107L18.5918 9.39949H1.20703Z" stroke="white"/>
                    </svg>
                    :<svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.5919 1.39933L9.89954 10.0917L1.20715 1.39933L18.5919 1.39933Z" stroke="white"/>
                    </svg>
                }
                </div>
            </div>

            {isExpanded&&<div className="flex flex-col w-full items-center">
                <div className="text-[14px] font-bold mt-2">{now.toLocaleDateString()} | {now.getHours()>12?now.getHours()-12:now.getHours()}:{now.getMinutes()<10?`0${now.getMinutes()}`:now.getMinutes()} {now.getHours()>12?'PM':'AM'}</div>
                <div className="flex flex-row items-center gap-2 mt-[4px]">
                    {weatherIconsArr.map((name, i) => <img src={`/weatherIcon/${name}.png`}></img>)}
                </div>   
                <img className='mt-2' src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${weatherData?.icon[0]}.png`} style={{width:90}}  alt='weather'/>
                <div className="text-[20px] font-bold mt-1">{weatherData?.temperature.data[0].value}°C</div>
                <div className="text-[12px] font-bold mt-1">{weatherStatusObj[weatherData?.icon?.[0]]} </div>
                <div className="flex flex-row w-full px-[16px] mt-3">
                    <RainFall />
                    <div className="flex-1 flex items-center justify-center">Rainfall</div>
                    <div className="items-center justify-center">|</div>
                    <div className="flex-1 flex items-baseline justify-center">{(currRain )}<span className="text-[12px] ml-[2px]">mm</span></div>
                </div>
                {/* <div className="flex flex-row w-full px-[16px] mt-3">
                    <WindySvg/>
                    <div className="flex-1 flex items-center justify-center">Wind</div>
                    <div className="items-center justify-center">|</div>
                    <div className="flex-1 flex items-center justify-center">10 km/h</div>
                </div> */}
                <div className="flex flex-row w-full px-[16px] mt-3 mb-5">
                    <HumiditySvg/>
                    <div className="flex-1 flex items-center justify-center">Hum</div>
                    <div className="flex items-center justify-center">|</div>
                    <div className="flex-1 flex items-center justify-center">{weatherData?.humidity.data[0].value}%</div>
                </div>
                <div className="flex flex-col w-full px-[16px] mt-3 mb-5">
                    {/* <div className="flex-1 flex items-center justify-center font-bold">warning</div> */}
                    <div className="flex-1 text-[12px] max-h-[100px]" style={{overflowY:'auto'}}><span className="font-bold">Warning:</span> {(warning || 'No warning')}</div>
                </div>
            </div>}

        </div>
    )
}
