import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//
import DateField from './DateField';
import SelectBoxField from './SelectBoxField';
import ImageField from './ImageField';
import TextInputField from './TextInputField';
import DropdownField from './DropdownField';
import TitleAndDesField from './TitleAndDesField';
import TableField from './TableField';
import { PERMIT_FIELD_TYPE } from '../../dto/permit';
import WorkerInfoListField from './WorkerInfoListField';
//svg
function FieldCard({isEdit, i, k, sectionId, len, handleFieldDelete, fieldObj, handleUpdateData, handleFieldReorder}){
    useEffect(() => {
        if(fieldObj.type !== 'Table')delete fieldObj?.tableTitle
    },[fieldObj.type])
    function localUpdateField(type){
        if(type === fieldObj.type) return
        fieldObj.fieldData = {}
        handleUpdateData(i, k, 'type', type)
    }
    function handleUpdateRequirements(key, val){
        const temp = {...fieldObj}
        temp[key] = val

        handleUpdateData(i, k , key, val)
    }
    function handleDataChangeWithKey(key,val){
        const temp = {...fieldObj.fieldData}
        temp[key] = val
        handleUpdateData(i, k , 'fieldData', temp)
    }
  
    if(fieldObj.type === PERMIT_FIELD_TYPE[0]) return(
        <TextInputField handleUpdateRequirements={handleUpdateRequirements} handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[1]) return(
        <TitleAndDesField handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[2]) return(
        <SelectBoxField handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[3]) return(
        <TableField handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} handleUpdateData={handleUpdateData} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[4]) return(
        <DropdownField handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === 'Image') return(
        <ImageField isEdit={isEdit} handleUpdateRequirements={handleUpdateRequirements} sectionId={sectionId} handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[5]) return(
        <DateField handleUpdateRequirements={handleUpdateRequirements} handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
    if(fieldObj.type === PERMIT_FIELD_TYPE[6]) return(
        <WorkerInfoListField handleFieldDelete={handleFieldDelete} i={i} k={k} fieldObj={fieldObj} len={len} handleDataChangeWithKey={handleDataChangeWithKey} localUpdateField={localUpdateField} handleFieldReorder={handleFieldReorder}/>
    )
        
        return(
    <div key={'section' + i} className='flex flex-row'>
        {fieldObj.type}
    </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FieldCard)