import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||18}
    height={props.width?props.width/18*26:26}
    fill="none"
    viewBox="0 0 18 26"
    {...props}
  >
    <path
      fill="#fff"
      d="m11.17 2.167.443 2.686a8.556 8.556 0 0 0-2.946-.52c-1.03 0-2.026.184-2.936.51l.444-2.676h4.994Zm.443 18.98-.444 2.686H6.175l-.444-2.675c.91.325 1.907.509 2.936.509 1.04 0 2.026-.184 2.946-.52ZM13 0H4.333L3.304 6.207A8.602 8.602 0 0 0 0 13c0 2.762 1.29 5.21 3.304 6.793L4.334 26H13l1.04-6.207A8.64 8.64 0 0 0 17.333 13a8.64 8.64 0 0 0-3.293-6.793L13 0ZM8.667 19.5a6.505 6.505 0 0 1-6.5-6.5c0-3.586 2.914-6.5 6.5-6.5 3.585 0 6.5 2.914 6.5 6.5s-2.915 6.5-6.5 6.5Z"
    />
    <path
      fill="#fff"
      d="M8.667 4.333C3.9 4.333 0 8.233 0 13c0 4.767 3.9 8.667 8.667 8.667 4.766 0 8.666-3.9 8.666-8.667 0-4.767-3.9-8.666-8.666-8.666Zm0 15.6c-3.822 0-6.934-3.11-6.934-6.933 0-3.822 3.112-6.933 6.934-6.933S15.6 9.178 15.6 13c0 3.822-3.111 6.933-6.933 6.933ZM9.1 8.667H7.8v5.2l4.507 2.773.693-1.127-3.9-2.34V8.668Z"
    />
  </svg>
)
export default SvgComponent
