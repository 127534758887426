import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#agridview)">
      <path
        fill={props.color||"#63BDFF"}
        d="M4 8.5h4v-4H4v4Zm6 12h4v-4h-4v4Zm-6 0h4v-4H4v4Zm0-6h4v-4H4v4Zm6 0h4v-4h-4v4Zm6-10v4h4v-4h-4Zm-6 4h4v-4h-4v4Zm6 6h4v-4h-4v4Zm0 6h4v-4h-4v4Z"
      />
    </g>
    <defs>
      <clipPath id="agridview">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
