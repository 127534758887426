import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <g clipPath="url(#RedBin)">
      <path
        fill="#F47070"
        d="M4.5 14.75c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-9h-9v9ZM6 7.25h6v7.5H6v-7.5Zm5.625-3.75-.75-.75h-3.75l-.75.75H3.75V5h10.5V3.5h-2.625Z"
      />
    </g>
    <defs>
      <clipPath id="RedBin">
        <path fill="#fff" d="M0 .5h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
