import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <g clipPath="url(#adevicesetting)">
      <path
        fill="#63BDFF"
        d="M9.571 6.47a3.537 3.537 0 0 0-.005-.94l1.015-.79a.245.245 0 0 0 .06-.306l-.96-1.66c-.06-.11-.185-.145-.295-.11l-1.195.48c-.25-.19-.515-.35-.81-.47l-.18-1.27a.242.242 0 0 0-.24-.205h-1.92a.237.237 0 0 0-.235.205l-.18 1.27a3.61 3.61 0 0 0-.81.47l-1.195-.48a.239.239 0 0 0-.295.11l-.955 1.66c-.06.105-.04.235.06.305l1.015.79c-.025.15-.045.315-.045.47 0 .155.01.32.035.47l-1.015.79a.245.245 0 0 0-.06.305l.96 1.66c.06.11.185.145.295.11l1.195-.48c.25.19.515.35.81.47l.18 1.27c.025.12.12.205.24.205h1.92c.12 0 .22-.085.235-.205l.18-1.27c.295-.12.565-.28.81-.47l1.195.48c.11.04.235 0 .295-.11l.96-1.66c.06-.11.035-.235-.06-.305l-1.005-.79ZM6.001 7.8c-.99 0-1.8-.81-1.8-1.8s.81-1.8 1.8-1.8 1.8.81 1.8 1.8-.81 1.8-1.8 1.8Z"
      />
    </g>
    <defs>
      <clipPath id="adevicesetting">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
