import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <rect width={19} height={19} x={0.5} y={1} stroke="#FFE454" rx={9.5} />
    <g fill="#FFE454" clipPath="url(#awarning)">
      <path d="M10 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9 6h2v6H9V6Z" />
    </g>
    <defs>
      <clipPath id="awarning">
        <path fill="#fff" d="M4 4.5h12v12H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
