import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import {Login} from '../actions/actions'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import LoadingPage from "../components/Loading";
import ToggleButton from '../components/ToggleButton'
import { fetchSmartWatch } from "../services/watches.service";
import { fetchSmartLock } from "../services/locks.service";
import DropDownMenu from '../components/DropdownMenu';
import { triggerAlert } from "../services/request.service";

const WATCH_ALERTS=[
    'WATCH_SOS','WATCH_HEARTRATE','WATCH_BLD_OXYGEN','WATCH_FALLDOWN'
]
const LOCK_ALERTS=[
    'LOCK_INAPR_UNLOCK','LOCK_VIBRATION','LOCK_LOCK',"LOCK_UNLOCK"
]

function Screen(props){
    const navigate = useNavigate();
    const [isLoading,setisLoading] = useState(false)

    const [watchList,setwatchList]=useState([])
    const [lockList,setlockList]=useState([])

    const fetchwatchList=async()=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchSmartWatch(props.token.token,filter,{limit:999})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setwatchList(result?.result||[])
        }
    }
    const fetchLockList=async()=>{
        const filter={ projectId:props.control?.projectId }
        let result = await fetchSmartLock(props.token.token,filter,{limit:999})

        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setlockList(result?.result||[])
        }
    }


    //setting data
    const [settingError,setsettingError]=useState('')
    const [deviceType,setdeviceType]=useState('WATCH')
    const [device,setDevice]=useState(null)
    const [alertType,setalertType]=useState(null)
    const handleSelectDeviceType=(item,index)=>{
        setdeviceType(item)
        setDevice(null)
        setalertType(null)
    }
    const handleSelectDevice=(item,index)=>{setDevice(item)}
    const handleSelectAlertType=(item,index)=>{setalertType(item)}
    //end of setting data
    
    
    useEffect(()=>{
        fetchwatchList()
        fetchLockList()
    },[props.control.projectId])

    const handleApplySetting=async()=>{
        if(!(device&&alertType))return;
        let res= await triggerAlert(
            props.token?.token,props.control?.projectId,
            deviceType,
            device,
            deviceType==='WATCH'?watchList?.filter(w=>w.id===device)?.[0]?.data?.name||'':lockList?.filter(w=>w.id===device)?.[0]?.data?.name||'',
            alertType
        )
        if(res.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(res?.errno){
            alert(`Error: `+ res?.message||'error')
        }else{
            alert('Alert Sent.')
        }

    }

    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-[14px] relative text-white  pb-[100px]" style={{...LEFTRIGHT_PADDING}}>
            <div className='flex flex-row w-full items-center justify-between mt-[40px]'>
                <div className="flex flex-row items-center">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold">Alert Testing</div>
                </div>
                
                <div className="flex flex-row items-center  gap-[8px]">
                    <div className="button_white" onClick={()=>{navigate('/')}}>Cancel</div>
                </div>
            </div>

            <div className="flex flex-row flex-wrap w-full mt-[40px] gap-[24px]">
                {/*left*/}
                <div className="flex flex-[4] flex-col p-[16px] gap-[12px] min-w-[520px] max-w-[100%]" style={{border:'.5px solid #ffffff80'}}>
                    <div className="flex flex-row mb-[24px]">
                        <TitleLeftSvg className='mr-[9px]' width={12} />
                        <div className="text-[16px] text-white font-bold">Alert setting</div>
                    </div>



                    <div className="flex flex-row items-center gap-[12px]">
                        <div>Device Type: </div>
                        <DropDownMenu options={['WATCH','LOCK']} select={handleSelectDeviceType} value={deviceType}/>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <div>Device: </div>
                        <DropDownMenu options={deviceType==='LOCK'?lockList.map(l=>l.id):deviceType==='WATCH'?watchList.map(w=>w.id):[]} select={handleSelectDevice} value={device||'select'}/>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <div>Type: </div>
                        <DropDownMenu options={deviceType==='LOCK'?LOCK_ALERTS:deviceType==='WATCH'?WATCH_ALERTS:[]} select={handleSelectAlertType} value={alertType||'select'}/>
                    </div>

                    <div className="flex flex-row items-center gap-[12px] mt-10">
                        <div className="button_blue" style={{opacity:(device&&alertType)?1:.5}} onClick={handleApplySetting}>Send</div>
                    </div>

          
                    <div className="text-[red] text-[10px]">{settingError}</div>
                </div>
                {/*end of left*/}

            </div>

            


            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)