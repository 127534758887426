import { useEffect, useState } from 'react';
import './MapCluster.css'
import { DEVICE_TYPE } from '../../dto/devices';
import { DEVICE_COLOR_DICT } from '../../config/style.config';
import { sethomepagePopup } from '../../actions/actions';
import { connect } from 'react-redux';

function Screen ({ pointCount,points,items,dispatch,id,control }) {
    const [data,setData]=useState([])


    const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
    useEffect(()=>{
        function handleWindowResize() {setWindowSize(getWindowSize()); }
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
    },[])


    const handleClick=()=>{
        if(control?.homepagePopup?.id===id)
            dispatch(sethomepagePopup(null))
        else
            dispatch(sethomepagePopup({type:'cluster',data,id}))
    }

    useEffect(()=>{
        let array=[]
        let AICam= items.filter(a=>a?.geometry?.type===DEVICE_TYPE.CAMERA)
        if(AICam?.length>0)
            array.push({type:DEVICE_TYPE.CAMERA,count:AICam?.length,data:AICam})

        let Locks= items.filter(a=>a?.geometry?.type===DEVICE_TYPE.LOCK)
        if(Locks?.length>0)
            array.push({type:DEVICE_TYPE.LOCK,count:Locks?.length,data:Locks})

        let Watches= items.filter(a=>a?.geometry?.type===DEVICE_TYPE.WATCH)
        if(Watches?.length>0)
            array.push({type:DEVICE_TYPE.WATCH,count:Watches?.length,data:Watches})

        let CS= items.filter(a=>a?.geometry?.type===DEVICE_TYPE.CONFINED_SPACE)
        if(CS?.length>0)
            array.push({type:DEVICE_TYPE.CONFINED_SPACE,count:CS?.length,data:CS})
        
        let DZ= items.filter(a=>a?.geometry?.type===DEVICE_TYPE.DANGER_ZONE)
        if(DZ?.length>0)
            array.push({type:DEVICE_TYPE.DANGER_ZONE,count:DZ?.length,data:DZ})

        array.sort((a,b)=>{
            if(a.count>b.count) return -1
            if(b.count>a.count) return 1
            else return 0
        })
        setData(array)
    },[items])


    return (
        <div onClick={handleClick} className='relative cluster-circle flex justify-center items-center text-white'
            style={{
                width:60 + pointCount*4,
                height:60 + pointCount*4,
                transform: `translate(${-30-pointCount*2}px, ${-30-pointCount*2}px)`,
            }}
        >
            {pointCount}

            {data?.map((item,index)=>{
                let sum= 0;
                let total=0
                for(let i=index;i<data.length;i++)
                    sum+= data[i]?.count||0
                for(let i=0;i<data.length;i++)
                    total+= data[i]?.count||0
                const percentage= sum/total*100
                return(
                    <div className="progress" style={{
                            height: 80 + pointCount*4,
                            width: 80 + pointCount*4,
                            top: -10,
                            left: (-10),
                    }} key={index}>
                        <svg className="progress-circle" width={(80 + pointCount*4)/160*200} height={(80 + pointCount*4)/160*200}  style={{marginTop:(-20-pointCount)}} xmlns="http://www.w3.org/2000/svg">
                            {/* <circle class="progress-circle-back" cx={(80 + pointCount*4)/2} cy={40 + pointCount*2} r={(40 + pointCount*2)*.8 }></circle> */}
                            <circle 
                                className="progress-circle-prog"
                                style={{
                                    'strokeDasharray': `${(40 + pointCount*2)*.95*3.141658*2 * percentage /100 } 9999`,
                                    stroke: DEVICE_COLOR_DICT[item?.type],
                                }} 
                                cx={(80 + pointCount*4)/2} 
                                cy={40 + pointCount*2}
                                r={(40 + pointCount*2)*.95 }
                            >
                            </circle>
                        </svg>
                        {/* <div class="progress-text" data-progress="0">23%</div> */}
                    </div>
                )}
            )}

            {control.homepagePopup?.type=== 'cluster'&&control.homepagePopup?.id===id&&
                <svg className='absolute top-[50%] right-[100%]' viewBox={`0 0 ${windowSize.innerWidth} 3`} style={{width:windowSize.innerWidth,height:3,marginTop:-1}}>
                    <line x1={0} y1={0} x2={windowSize.innerWidth} y2={0} style={{stroke:'white',strokeWidth:3}}/>
                </svg>
            }
            
        </div>
    )
}
export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)