import './MapCluster.css';

export default function Component(props){
    const percentage = props.percentage||0//Math.floor(Math.random()*100);

    return(
        <div className="w-[80px] h-[80px] flex justify-center items-center relative battery-circle">
            <div className="progress" style={{
                    height: 80 ,
                    width: 80 ,
                    top: 0,
                    left: (0)
            }}>
                <svg className="progress-circle" width={(80 )/160*200} height={(80)/160*200}  style={{marginTop:(-20)}} xmlns="http://www.w3.org/2000/svg">
                    <circle 
                        className="progress-circle-prog"
                        style={{
                            'strokeDasharray': `${(40 )*.95*Math.PI*2 * percentage /100 } 9999`,
                            stroke: percentage<25?'#FF969C':percentage<50?'#FFE454':percentage<75?'#00C2FF':'#19FF91',
                        }} 
                        cx={40} 
                        cy={40}
                        r={(40)*.95 }
                    >
                    </circle>
                </svg>
            </div>
            <div className='absolute text-white flex flex-col items-center'>
                <div className='font-bold text-xs'>Battery</div>
                <div className='font-bold text-base'>{percentage}%</div>
            </div>
        </div>
    )
}