import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color||"#fff"}
      d="M15.81 10.399c.038-.293.065-.587.065-.898 0-.312-.027-.605-.064-.899l1.934-1.512a.462.462 0 0 0 .11-.587l-1.833-3.172a.458.458 0 0 0-.56-.201l-2.282.916a6.698 6.698 0 0 0-1.55-.898L11.284.72a.447.447 0 0 0-.45-.385H7.167a.447.447 0 0 0-.45.385l-.348 2.43c-.559.228-1.072.54-1.549.897L2.538 3.13a.519.519 0 0 0-.165-.028.454.454 0 0 0-.395.23L.145 6.502a.452.452 0 0 0 .11.587L2.19 8.602a7.27 7.27 0 0 0-.064.899c0 .302.028.605.064.898L.255 11.912a.462.462 0 0 0-.11.586l1.833 3.172a.458.458 0 0 0 .56.201l2.282-.916c.477.367.99.669 1.55.898l.348 2.43c.027.22.22.384.449.384h3.666c.23 0 .422-.165.45-.385l.348-2.429c.56-.229 1.072-.54 1.55-.898l2.282.916a.52.52 0 0 0 .165.028.454.454 0 0 0 .394-.23l1.833-3.17a.462.462 0 0 0-.11-.588L15.811 10.4Zm-1.814-1.568c.037.285.046.477.046.67 0 .192-.019.394-.046.669l-.128 1.036.815.641.99.77-.641 1.11-1.164-.468-.954-.385-.825.623c-.394.294-.77.514-1.146.67l-.971.394-.147 1.036-.183 1.237H8.358l-.174-1.237-.146-1.036-.972-.395a5.201 5.201 0 0 1-1.128-.65l-.834-.642-.971.394-1.165.468-.641-1.11.99-.77.816-.641-.129-1.036a7.374 7.374 0 0 1-.046-.678c0-.184.019-.395.046-.67l.129-1.035-.816-.642-.99-.77.641-1.11 1.165.468.953.385.825-.623c.394-.293.77-.513 1.146-.67l.971-.393.147-1.036.183-1.238h1.275l.174 1.238.146 1.036.972.394c.394.165.76.376 1.128.65l.834.642.971-.394 1.165-.467.641 1.109-.98.779-.816.642.128 1.035ZM9 5.834a3.666 3.666 0 0 0-3.667 3.667A3.666 3.666 0 0 0 9 13.167a3.666 3.666 0 0 0 3.667-3.666A3.666 3.666 0 0 0 9 5.834Zm0 5.5a1.839 1.839 0 0 1-1.833-1.833c0-1.009.825-1.834 1.833-1.834s1.833.825 1.833 1.834A1.839 1.839 0 0 1 9 11.334Z"
    />
  </svg>
)
export default SvgComponent
