import React, { useEffect, useReducer, useState, useRef } from "react";
import { connect } from "react-redux";
import { getDateOnly, getTimeOnly } from "../../actions/actionsDateTime";
import DocSvg from '../../svgs/tracking/DocSvg'
import BlueDownloadSvg from '../../svgs/tracking/BlueDownloadSvg'
import { fetchDocumentByName, deleteFileByName } from "../../services/httpApiTracking";
import RedBinSvg from '../../svgs/tracking/RedBinSvg'
import WhiteCloseSvg from '../../svgs/WhiteCloseSvg'
import {createPortal} from 'react-dom'
import PopUpBase from "../PopUpBase";
import { server_ip } from "../../config";
function DocumentList(props){
    const [confirmToggle, setConfirmToggle] = useState(false)
    async function fetchDocument(){
        //const file = await fetchDocumentByName(props.docObj.filename, props.docObj.trackingRecordId)
        // console.log(file)
        window.open(`${server_ip}/api/documents/${props.docObj.filename}`)
    }
    async function handleDeleteFile(){
        const res = await deleteFileByName(props?.token.token, props.docObj.filename, props.docObj.trackingRecordId)
        props.fetchData()
        setConfirmToggle(false)
    }
    return (
        <div className='flex flex-row items-center border border-[#FFFFFF80] p-[24px] mb-[24px]'>
            {confirmToggle && createPortal(<PopUpBase closePopUp={setConfirmToggle}> 
                <div className="w-[25%] min-w-[460px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
                    <div className="flex flex-row justify-between mb-8">
                        <div className="text-[18px] font-bold">Confirm Delete </div>
                        <WhiteCloseSvg className='cursor-pointer' onClick={() => {setConfirmToggle(false)}}/>
                    </div>
                    <div>
                    <div className="font-bold mb-8 text-[18px]">File: {props.docObj.originalname}</div>
                    <div className="flex flex-row items-center w-full gap-4">
                        <div className='flex-1 bg-[#00202A] py-[10px] px-[16px] rounded-full text-[#63BDFF] cursor-pointer select-none text-center' onClick={() => setConfirmToggle(false)}>Cancel</div>
                        <div className='flex-1 bg-[#f5707040] py-[10px] px-[16px] rounded-full text-[#f57070] cursor-pointer select-none text-center' onClick={() => handleDeleteFile()}>Delete</div>
                    </div>

                    </div>
                </div>
            </PopUpBase >, props.popRef)}
            <div className='text-[20px] font-bold text-white py-[12px] px-[14px] border rounded-full border-[#FFFFFF80] mr-[24px]'><DocSvg /></div>
            <div className='flex-1'>
                <div>{props.docObj.originalname}</div>
                <div className='flex flex-row items-center'>
                    <div className='flex flex-row items-center border border-white rounded-full px-[10px] py-[2px] gap-4 mr-[12px]'>
                        <div>{getDateOnly(props.docObj.modifyDate)}</div>
                        <div>{getTimeOnly(props.docObj.modifyDate)}</div>
                    </div>
                    {/* <div className='text-[#A1A1A1]'><span className='mx-2'></span>  0.371 MB</div> */}
                </div>
            </div>
            <div className='bg-[#00202A] py-[2px] px-[16px] rounded-full flex flex-row items-center text-[#63BDFF] cursor-pointer select-none hover:scale-105' onClick={() => fetchDocument()}><BlueDownloadSvg className='mr-[8px]'/>Download</div>
            <div className="ml-2 py-[6px] px-[6px] flex flex-row items-center justify-center bg-[#f5707040] rounded-full cursor-pointer select-none hover:scale-105" onClick={() => setConfirmToggle(true)}><RedBinSvg className=''/></div>
        </div>  
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(DocumentList)