import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import DropdownMenu from '../../components/DropdownMenu';
import { createAccount, deleteAccount, editUserData } from "../../services/users.service";
import LoadingPage from "../Loading";
import {validateEmail}from '../../utils/util';


function Component(props){ 
    const [isLoading,setisLoading]=useState(false)
    const [newRecord,setNewRecord]=useState(JSON.parse(JSON.stringify(props.data)))
    const [createError,setcreateError]=useState('')
    const handleCancel=()=>{props.cancel();}
 
    const handleConfirm=async()=>{
        if(isLoading)return
        setcreateError('')
        setisLoading(true)
        const data={
            phone:newRecord?.data?.phone||'',
            firstname:newRecord?.data?.firstname||'',
            lastname:newRecord?.data?.lastname||'',
        }
        let result = await deleteAccount(props.token?.token,newRecord._id)
        if(result.errno){
            setcreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
           handleCancel()
           props.refresh();
        }
        setisLoading(false)
    }


    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                <div className='flex flex-row items-center space-between w-full'>
                    <div className='font-bold'>Confirm Delete</div>          
                </div>
                <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancel}>X</div>         

                <div className="text-white mt-10 font-bold text-[14px]">Email</div>
                <div className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1'  style={{border:'.5px solid #ffffff80'}}>
                    {newRecord?.username}
                </div>

                <div className='w-full text-[red] text-xs'>{createError}</div>

                <div onClick={handleConfirm} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                    Delete
                </div>
            </div>
            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)