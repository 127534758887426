import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import SmartLockChart2 from '../components/charts/SmartLockChart2';
import DropDown from '../components/DropdownMenu'
import LockAlertList from "../components/LockPage/LockAlertList";
import PageSelector from "../components/PageSelector"
import CopyLink from "../components/CopyLink";
import { handleFilterDate, yearMonthDay } from "../actions/actionsDateTime"; 
import { fetchLockingEvent } from "../services/locks.service";
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import { REFRESH_INTERVAL } from "../config";

const limit = 10
const TypeParamToValue = {
    "Lock" :"LOCK_LOCK",
    "Unlock":"LOCK_UNLOCK",
    "Inappropriate unlock":"LOCK_INAPR_UNLOCK",
    "Vibration":"LOCK_VIBRATION",
}
function SmartLock(props){
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalItem, setTotalItem] = useState(1)
    const [popType, setPopType] = useState(undefined)
    const totalPage = Math.ceil(totalItem/limit)||1
    const page =  parseInt(searchParams.get('page'))||1
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    //locking
    const lockingSearch = searchParams.get('locking_search')
    const lockingType = searchParams.get('locking_type')
    const [lockingData, setLockingData] = useState([])
    const [lockingTotal, setLockingTotal] = useState(1)
    const lockingTotPage = Math.ceil(lockingTotal/limit)||1
    const lockingPage =  parseInt(searchParams.get('locking_page'))||1
    //
    const alertSearch = searchParams.get('alert_search')
    const alertType = searchParams.get('alert_type')
    const [alertData, setAlertData] = useState([])
    const [alertTotal, setAlertTotal] = useState(1)
    const alertTotPage = Math.ceil(alertTotal/limit)||1
    const alertPage =  parseInt(searchParams.get('alert_page'))||1
    //
    const navigate = useNavigate();
    const [isHoverChart,setisHoverChart] = useState(false)


    useEffect(() => {
        const timer = setTimeout(()=>{
            fetchLockingData()
            fetchAlertData()
        }, 500)
        const interval = setInterval(()=>{
            fetchLockingData()
            fetchAlertData()
        },REFRESH_INTERVAL)
        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    },[searchParams])


    async function fetchLockingData(){
        // formatDataForChart()
        const filter = {}
        filter["type"] = {"$in": ["LOCK_LOCK", "LOCK_UNLOCK"]}
        if(lockingSearch) filter["$or"] =  [{'data.deviceName': {"$regex": alertSearch, "$options":'i'}},{deviceID: {"$regex": lockingSearch, "$options":'i'}}]
        if(lockingType) filter["type"] = TypeParamToValue[lockingType]
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        filter["projectId"] = props.control.projectId
        const res = await fetchLockingEvent(props.token.token, filter, {limit,skip:limit*(lockingPage-1)})
        // console.log(res)
        if(res.errno) return
        setLockingTotal(res.count)
        setLockingData(res.result)

        const toPage = Math.ceil(res.count/limit) || 1
        if(lockingPage > toPage) handleParamChange('locking_page', 1)
    }
    async function fetchAlertData(){
        // formatDataForChart()
        const filter = {}
        filter["type"] = {"$in": ["LOCK_VIBRATION", "LOCK_INAPR_UNLOCK"]}
        if(alertSearch) filter["$or"] =  [{'data.deviceName': {"$regex": alertSearch, "$options":'i'}},{deviceID: {"$regex": alertSearch, "$options":'i'}}]
        if(alertType) filter["type"] = TypeParamToValue[alertType]
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        filter["projectId"] = props.control.projectId
        const res = await fetchLockingEvent(props.token.token, filter, {limit,skip:limit*(alertPage-1)})
        if(res.errno) return
        // console.log(res)
        setAlertTotal(res.count)
        setAlertData(res.result)

        const toPage = Math.ceil(res.count/limit) || 1
        if(alertPage > toPage) handleParamChange('locking_page', 1)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(key !== 'page'){
            searchParams.delete('page')
            searchParams.append('page', 1)
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }
    function handleLockingParamType(item){
        searchParams.delete('locking_type')
        if(item !== 'All')searchParams.set('locking_type', item)
        searchParams.delete('locking_page')
        searchParams.append('locking_page', 1)
        setSearchParams(searchParams)
    }
    function handleAlertParamType(item){
        searchParams.delete('alert_type')
        if(item !== 'All')searchParams.set('alert_type', item)
        searchParams.delete('alert_page')
        searchParams.append('alert_page', 1)
        setSearchParams(searchParams)
    }
    const handleAlertSelectPage=(num)=>()=>{
        if(num < 1 || num > alertTotPage) return
        handleParamChange('alert_page', num)
    }
    const handleLockingSelectPage=(num)=>()=>{
        if(num < 1 || num > lockingTotPage) return
        handleParamChange('locking_page', num)
    }
    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-[14px] h-full pb-12" style={{overflowY:isHoverChart?'hidden':'auto'}}>
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
            <div className=''style={{...LEFTRIGHT_PADDING}}>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold "> Smart Lock</div>
                </div>
                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[27px]">
                        <div className="subpage_not_selected" onClick={() => navigate('/smartlock/device-management')}>
                            Device Management
                        </div>
                        <div className="subpage_selected">
                            Alert
                        </div>
                    </div>
                    <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                </div>
                {/*chart*/}
                <div className="flex flex-col w-full mt-[25px] text-[14px]" style={{border: '1px solid #FFFFFF80'}}>
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px]">
                        <div className="text-white text-[18px] font-bold flex items-center gap-2">
                            <TitleLeftSvg className='mr-[9px]'/> Smart Lock Event <CopyLink/>
                        </div>
                    </div>
                    <div className="px-[26px] pt-[13px] w-full" onMouseEnter={()=>setisHoverChart(true)} onMouseLeave={()=>{setisHoverChart(false)}}>
                        <SmartLockChart2/>
                    </div>
                </div>
                {/*event list*/}
                <div className="flex flex-col mt-[48px] w-full text-[14px]" style={{border: '1px solid #FFFFFF80'}}>
                <div className="flex flex-row items-center justify-between py-[15px] px-[24px]">
                    <div className="flex flex-row items-center gap-2">
                            <div className="text-white text-[18px] font-bold flex items-center">Inappropriate & Vibration Lock History</div>
                            <CopyLink/>
                        </div>
                    </div>
                    <div className="text-white  px-[24px] pt-[13px] flex flex-row items-center justify-between">
                        <DropDown options={['All','Vibration', 'Inappropriate unlock']} select={handleAlertParamType} value={ alertType || 'Type'}/>
                        <div className="flex flex-row gap-2">
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input defaultValue={alertSearch || ''} onChange={(e) => {handleParamChange('alert_search', e.target.value)}}  className="bg-transparent h-[21px] text-white min-w-0 outline-none" placeholder="Serial No. or Name"></input>
                            </div>
                        </div>
                    </div>
                    <LockAlertList alertArr={alertData} fetchData={fetchAlertData}/>
                    <PageSelector page={alertPage} total={alertTotal} totalPage={alertTotPage} handleSelectPage={handleAlertSelectPage}/>
                </div>
                {/*Lock & UnLock History*/}
                <div className="flex flex-row items-center gap-4 text-[14px]">
                    <div className="mt-[64px] flex-1 min-h-[410px] relative" style={{border: '1px solid #FFFFFF80'}}>
                        <div className="flex flex-row items-center justify-between py-[15px] px-[24px]">
                            <div className="flex flex-row items-center gap-2">
                                <div className="text-white text-[18px] font-bold flex items-center">Lock & UnLock History</div>
                                <CopyLink/>
                            </div>
                        </div>
                        <div className="">
                            <div className="text-white mt-[24px] flex flex-row items-center justify-between  px-[30px]">
                                <DropDown options={['All','Lock', 'Unlock']} select={handleLockingParamType} value={ lockingType || 'Type'}/>
                                <div className="flex flex-row gap-2 py-[12px]">
                                    <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                        <SmallSearchSvg className='mr-[12px]' />
                                        <input defaultValue={lockingSearch || ''} onChange={(e) => {handleParamChange('locking_search', e.target.value)}} className="bg-transparent h-[21px] text-white min-w-0 outline-none" placeholder="Serial No. or Name"></input>
                                    </div>
                                </div>
                            </div>
                            <LockAlertList alertArr={lockingData} fetchData={fetchLockingData}/>
                            <PageSelector page={lockingPage} total={lockingTotal} totalPage={lockingTotPage} handleSelectPage={handleLockingSelectPage}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartLock)