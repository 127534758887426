export default function ToggleButton({value,onClick}){
    return(
        <div 
            onClick={onClick}
            className="w-[36px] h-[24px] cursor-pointer relative items-center flex flex-row rounded-full px-[2px]" 
            style={{borderWidth:2,borderColor:value?'#63BDFF':'#777676',backgroundColor:value?'#63BDFF':'transparent',justifyContent:value?'flex-end':'flex-start'}}
        >
            <div className="rounded-full absolute" style={{backgroundColor:value?'#00202A':'#777676',transition:'.5s all ease',width:value?18:16,height:value?18:16,left:value?12:2}}/>
        </div>
    )
}