import React from "react"

function LoadingPage({type, valid}){ 
    if(type === 'pending'){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[6px] px-[16px]' style={{backgroundColor:'#00202A', borderWidth: 1, color:'#5FC5FF'}}>
                Pending
            </div>
        </div>
        )
    }
    if(type === 'checked'){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[6px] px-[16px]' style={valid ? {backgroundColor:'#00202A', borderWidth: 1, color:'#5FC5FF'} : {backgroundColor:'#f5707040', borderWidth: 1, color:'#f57070'}}>
                {valid ? 'Valid' : 'Invalid'}
            </div>
        </div>
        )
    }
    if(type === 'warning'){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[6px] px-[16px]' style={{backgroundColor:'#FF969C20', borderWidth: 1, color:'#FF969C'}}>
            Warning
            </div>
        </div>
        )
    }
    if(type === 'active'){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[6px] px-[16px]' style={{backgroundColor:'#00202A', borderWidth: 1, color:'#5FC5FF'}}>
            Active
            </div>
        </div>
        )
    }
    if(type === 'Unlock for too long'){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[6px] px-[16px]' style={{backgroundColor:'#3D344C', borderWidth: 1, color:'#BF96FF'}}>
            Unlock for too long
            </div>
        </div>
        );
    }
}

export default LoadingPage 