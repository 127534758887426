import React from 'react';
import { connect } from 'react-redux';
//svgs
import SmallHeader from '../../../svgs/tracking/SmallHeader'
import TemplateControl from './TemplateControl';
function GenerateForm({inPending, permissionObj ,form, isPreview, updateFieldDataDataWithKey=() => {}, updateFieldData=()=>{}}) {
    // console.log(permissionObj)
    return (<div className='mr-4'>
        {form?.map((sectionObj, sectionIdx) => { 
            let disabling = isPreview
            if(!isPreview && (sectionObj.permission === 'Issuers' && !permissionObj.userIsIssuer)) disabling = true
            if(!isPreview && (sectionObj.permission === 'Approvers' && !permissionObj.userIsApprover)) disabling = true
            if(!isPreview && (sectionObj.permission === 'Receivers' && !permissionObj.userIsReceiver)) disabling = true
            if(inPending) disabling = true
            return(
                <div key={sectionObj.id} className='py-[32px]'>
                    <div className='flex flex-col border-b border-[#FFFFFF80] pb-[16px]'>
                        <div className='flex flex-row text-[18px] font-bold'><SmallHeader className="mr-[4px]"/> {sectionObj.sectionTitle}</div>
                        <div className='text-[12px] ml-[16px] text-[#797979] pl-[16px]'>{sectionObj.sectionDescription}</div>
                    </div>
                    <div className='flex flex-row flex-wrap'>
                        {sectionObj.data.map((fieldObj, fieldIdx) => (
                            <TemplateControl key={'fieldObj' + fieldIdx} isPreview={disabling} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} updateFieldDataDataWithKey={updateFieldDataDataWithKey} updateFieldData={updateFieldData}/>
                        ))}
                    </div>
                </div>
            )}
        )}
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(GenerateForm)