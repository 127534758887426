import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <rect width={33} height={33} fill="#00202A" rx={7} />
    <g clipPath="url(#BigBluePlus)">
      <path
        fill="#007EB5"
        d="M22.92 17.417h-5.5v5.5h-1.834v-5.5h-5.5v-1.833h5.5v-5.5h1.833v5.5h5.5v1.833Z"
      />
    </g>
    <defs>
      <clipPath id="BigBluePlus">
        <path fill="#fff" d="M5.5 5.5h22v22h-22z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
