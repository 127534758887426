import { server_ip } from "../config";

async function loginWithPassword(username, password) {
    try {
        const response = await fetch(`${server_ip}/api/users/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username, password
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchCameras(token, filter) {
    try {
        const response = await fetch(`${server_ip}/api/cameras/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":filter,
                "options":{"limit":99}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchConfinedSpaces(token, filter) {
    try {
        const response = await fetch(`${server_ip}/api/confinedSpaces/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":filter,
                "options":{"limit":99}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchDangerZones(token, filter) {
    try {
        const response = await fetch(`${server_ip}/api/dangerZones/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":filter,
                "options":{"limit":99}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchLatestAlerts(token, filter, options) {
    try {
        const response = await fetch(`${server_ip}/api/events/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function putUpdateAlerts(token,id, body) {
    try {
        const response = await fetch(`${server_ip}/api/events/${id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({...body}),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function putUpdateAllAlerts(token, projectId) {
    try {
        const response = await fetch(`${server_ip}/api/events/acknowledge`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({projectId}),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function triggerAlert(token, projectId,deviceType,deviceId,deviceName,alertType){
    try {
        const response = await fetch(`${server_ip}/api/events/trigger`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({projectId,deviceType,deviceId,deviceName,alertType}),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    loginWithPassword,
    fetchCameras,
    fetchConfinedSpaces,
    fetchDangerZones,
    fetchLatestAlerts,
    putUpdateAlerts,
    putUpdateAllAlerts,
    triggerAlert
}