import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <g clipPath="url(#acheck)">
      <path
        fill="#29FFD9"
        d="m6.499 11.301-2.8-2.8-.933.933 3.733 3.734 8-8-.933-.934-7.067 7.067Z"
      />
    </g>
    <defs>
      <clipPath id="acheck">
        <path fill="#fff" d="M.5.5h16v16H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
