import React, {useState} from 'react';
import { connect } from 'react-redux';
//svg
import SectionBin from '../../svgs/permit/SectionBin'
import SectionArrow from '../../svgs/permit/SectionArrow'
function SectionCard({i, len, handleSectionDelete,  handleFieldDelete, handleSectionChange, obj, handleSectionReorder}){
    const [permissionDrop, setPermissionDrop] = useState(false)
    function handlePermissionSelect(text){
        handleSectionChange(text, i , 'permission')
        return setPermissionDrop(false)
    }
    return(
    <div key={'section' + i} className='flex flex-row select-none'>
        <div className='w-[8px] rounded-full' style={{background: 'linear-gradient(180deg, #63BDFF 0%, #3B7299 100%)'}}></div>
        <div className='flex flex-col flex-1 p-[32px] bg-[#FFFFFF12]'>
            <div className='flex flex-row justify-between'>
                <div>section {i+1}/{len}</div>
                <div className='flex flex-row gap-2'>
                    {i !== 0 && <SectionArrow onClick={() => handleSectionReorder('up', i)}/>}
                    {i !== len - 1 && <SectionArrow className="rotate-180" onClick={()=>handleSectionReorder('down',i)}/>}
                    <SectionBin className='cursor-pointer' onClick={()=>{handleSectionDelete(i)}}/>
                </div>
            </div>
            <div className='flex flex-row justify-between gap-4 mt-[32px]'>
                <div className='flex flex-col' style={{flex:4}}>
                    <div className='mb-[8px]'>Section Title*</div>
                    <input className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Please enter section title' value={obj?.sectionTitle || ''} onChange={(e) => handleSectionChange(e,i,'sectionTitle')}></input>
                </div>
                <div className='relative flex flex-col' style={{flex: 1}}>
                    <div className='mb-[8px]'>Who Can Fill In*</div>
                    <div onClick={() => setPermissionDrop(!permissionDrop)} className='border rounded-full px-[16px] py-[2px] cursor-pointer'>{obj.permission || 'Click to select'}</div>
                    {permissionDrop && 
                    <div className='z-99'>
                        <div className='w-[100vw] h-[100vh] fixed inset-0 bg-[#00000080]' onClick={() => setPermissionDrop(false)}></div>
                        <div className='absolute bg-black w-full top-[100%] rounded border'>
                            {['All User','Issuers','Approvers','Receivers'].map((text) => (
                                <div key={text+'div'} className='py-[2px] px-[16px] cursor-pointer hover:text-black hover:bg-white' onClick={() => handlePermissionSelect(text)}>{text}</div>
                            ))}
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className='flex flex-row mt-[32px]'>
                <div className='flex flex-col' style={{flex:4}}>
                    <div className='mb-[8px]' >Section Description</div>
                    <input className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Please enter section Description' value={obj?.sectionDescription || ''} onChange={(e) => handleSectionChange(e,i,'sectionDescription')}></input>
                </div>
            </div>
        </div>
    </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SectionCard)