import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <g clipPath="url(#PenSvg)">
      <path
        fill="#fff"
        d="m10.545 7.265.69.69L4.44 14.75h-.69v-.69l6.795-6.795Zm2.7-4.515a.75.75 0 0 0-.525.217L11.347 4.34l2.813 2.813 1.373-1.373a.747.747 0 0 0 0-1.058l-1.755-1.755a.736.736 0 0 0-.533-.217Zm-2.7 2.393L2.25 13.437v2.813h2.813l8.294-8.295-2.812-2.813Z"
      />
    </g>
    <defs>
      <clipPath id="PenSvg">
        <path fill="#fff" d="M0 .5h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
