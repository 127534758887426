import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <rect width={30} height={30} fill="#333" rx={7} />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M9.503 21.417c0 1.008.825 1.833 1.833 1.833h7.333a1.839 1.839 0 0 0 1.834-1.833v-11h-11v11Zm1.833-9.167h7.333v9.167h-7.333V12.25Zm6.875-4.583-.917-.917h-4.583l-.917.917H8.586V9.5h12.833V7.667h-3.208Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h22v22H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
