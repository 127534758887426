import '../tracking/CreatePopUp.css'
import React, {useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
//svg
import WhiteCloseSvg from '../../svgs/WhiteCloseSvg'

const baseTemplate = {
    type:null,
    name:null,
    form:[],
    projectId:""
}
//
function CreatePopUp(props){
    const [templateData, setTemplateData] = useState(baseTemplate)
    const [templateTypeDrop, setTemplateTypeDrop] = useState(false)
    const [baseTemplateComplete, setBaseTemplateComplate] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate();
    function changeCreateTemplate(key,e){
        if(key === 'templateType') {
            setTemplateData({...templateData, [key]: e})
            return setTemplateTypeDrop(false)
        }
        // console.log(key, (e?.target?.value || e))
        setTemplateData({...templateData, [key]:(e?.target?.value || e)})
    }
    function handleCreateForm(e){
        e.preventDefault()
        if(!templateData.type || !templateData.name || templateData.name === ''){
            return setErrMsg('Missing information!')
        }
        const time = new Date()
        templateData.projectId = props.control.projectId
        navigate('/permit/template/add', {state:{data: templateData}})
        // console.log(templateData)
    }
    return (
        <div className="w-[25%] min-w-[460px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
            <div className="flex flex-row justify-between mb-8">
                <div className="text-[18px] font-bold">Create Template</div>
                <WhiteCloseSvg className='cursor-pointer' onClick={() => {props.setCreatePopUp(false)}}/>
            </div>
            <form onSubmit={handleCreateForm} className="flex flex-col">
                {/* <div className="flex flex-col mb-4">
                    <label htmlFor="projectId">ProjectId*</label>
                    <input type="text" id="projectId" name="projectId" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('projectId', e)}}/>
                </div> */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="category">Template Title*</label>
                    <input type="text" id="Title" name="Title" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onChange={(e) => {changeCreateTemplate('name', e)}}/>
                </div>
                <div className="relative flex flex-col mb-4">
                    <label htmlFor="category">Template Type*</label>
                    <div onClick={() => setTemplateTypeDrop(true)} type="text" id="TemplateType" name="TemplateType" className='cursor-pointer rounded-full bg-transparent h-[42px] mt-[8px] px-4 flex flex-row items-center' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onChange={(e) => {changeCreateTemplate('type', e)}}>
                        {templateData?.type || 'Click to select a type'}
                    </div>
                    {templateTypeDrop && <div className='absolute bg-black w-full top-[100%] rounded border'>
                        {['Permit to Work','Permit to Move','Permit to Operate','Other Permit'].map((text) => (
                            <div key={text+'div'} className='py-[2px] px-[16px] cursor-pointer hover:font-bold' onClick={(e) => {changeCreateTemplate('type', text);setTemplateTypeDrop(false)}}>{text}</div>
                        ))}
                    </div>}
                </div>
                {/* <div className="flex flex-col mb-4">
                    <label htmlFor="category">Company*</label>
                    <input type="text" id="Company" name="Company" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onChange={(e) => {changeCreateTemplate('company', e)}}/>
                </div> */}
                <div className="flex flex-col mb-4">
                    <input type="submit" className='bg-[#00202A] text-[#63BDFF] border-none h-[40px] rounded-full cursor-pointer hover:scale-105' id="submitBtn" name="submit" value='Add New'/>
                </div>
                {errMsg !== '' && <div className='text-[red]'>{errMsg}</div>}
            </form>
        </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(CreatePopUp)