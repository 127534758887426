import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import BinSvg from '../svgs/camera/BinSvg';
import { LEFTRIGHT_PADDING } from "../config/style.config";
//service
import {createpermitWorkerListsRecord, editpermitWorkerListRecord, fetchPermitWorkerLists} from '../services/permitWorkerLists.service'
///svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import AddEngineSvg from '../svgs/camera/AddEngineSvg'
//componets
import NoPermssion from './NoPermission'
import { isSSM508 } from '../config';
import { Login } from '../actions/actions';


function Screen(props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [record,setRecord]=useState({})

  
    useEffect(() => {
        let timer = setTimeout(() => fetchData(), 500)
        return () => clearTimeout(timer)
    },[searchParams,props.control])
    async function fetchData(){
        if(!props.control?.projectId) return
       
        const res = await fetchPermitWorkerLists(props.token.token, {projectId:props.control?.projectId}, {limit:1})
        if(res?.errno) return console.log('error fetching data')
        if(res?.result){
            setRecord(res?.result?.[0]||[])
        }
    }

    const [newRow,setnewRow]=useState({})
    const handleNewItem=()=>{
        let d= [...(record?.list||[])]
        d.push({...newRow,id:Date.now()})
        d.sort((a,b)=>{return (!a.name)?1:(!b.name)?-1:a.name>=b.name?1:a.name<=b.name?-1:0})
        setRecord({...record,list:d})
        setnewRow({})
        save(d)()
    }

    const save=(d=(record?.list||[]))=>async()=>{
        d.sort((a,b)=>{return (!a.name)?1:(!b.name)?-1:a.name>=b.name?1:a.name<=b.name?-1:0})
        let result = null
        if(record?._id){
            result = await editpermitWorkerListRecord(props.token?.token,record?._id,{ list:d })
        }else{
            result= await createpermitWorkerListsRecord(props.token?.token,{
                projectId:props.control?.projectId,
                list:d
            })
        }

        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            fetchData()
        }
    }
    const handleNewItemChange=(field)=>(e)=>{setnewRow({...newRow,[field]:e.target.value})}
    const handleItemonChange=(item,index,field)=>(e)=>{
        let d= [...(record?.list||[])]
        d[index][field]=e.target.value
        setRecord({...record,list:d})
    }
    const handleDelete=(item,index)=>()=>{
        let d= [...(record?.list||[])]
        d.splice(index, 1);
        setRecord({...record,list:d})
    }
  
    if((!isSSM508) || (!props?.control?.permissions?.PERMIT_TEMPLATE)) return (<NoPermssion/>)
    return(
        <div className='w-full h-full bg-[#0C0D14] pb-12' style={{overflowY:'auto'}}>
            <div style={{...LEFTRIGHT_PADDING}} className='w-full '>
                <div className="flex flex-row mt-[40px] justify-between">
                    <div className='flex flex-row items-center'>
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[24px] text-white font-bold ">Permit System / Worker Information List</div>
                    </div>
                    <div className="flex flex-row items-center  gap-[8px]">
                        <div className="button_white" onClick={()=>{navigate('/permit/template')}}>Cancel</div>
                        <div className="button_blue" style={{}} onClick={save()}>Save</div>
                    </div>
                </div>
               
                <div className="mt-[50px] flex-wrap	flex flex-col gap-[16px] items-start justify-stretch w-full pb-[24px]" style={{borderBottom:'.5px solid #ffffff40'}}>
                    <div className="flex flex-row text-[10px] font-bold w-full items-center">
                        <div className="flex-[1] table_header">Item</div>
                        <div className="flex-[2] table_header">Name</div>
                        <div className="flex-[4] table_header">工人註冊證no. / 合資格人士證明書編號</div>
                        <div className="flex-[2] table_header">Expire Date</div>
                        <div className="w-[100px] table_header"/>
                    </div>
                    {(record?.list||[]).map((item, index) =>
                        <div key={item.id||index} className="text-[14px] table_row w-full" style={{overflowWrap: 'break-word',backgroundColor:index%2===1?'#FFFFFF1D':'transparent'}}>
                            <div className="flex-[1] table_item">{index+1}</div>
                            <div className='flex-[2] table_item'>
                                <input value={item.name||''} onChange={handleItemonChange(item,index,'name')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                            </div>
                            <div className='flex-[4] table_item'>
                                <input value={item.number||''} onChange={handleItemonChange(item,index,'number')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                            </div>
                            <div className='flex-[2] table_item'>
                                <input value={item.expireDate||''} onChange={handleItemonChange(item,index,'expireDate')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                            </div>
                            <div className="w-[100px] flex flex-row items-center table_item">
                                <BinSvg className='cursor-pointer' onClick={handleDelete(item,index)} />
                            </div>
                        </div>
                    )}
                </div>
                <div className='w-full mt-5 mb-10' style={{}}>
                    <div className="text-[14px] table_row" style={{overflowWrap: 'break-word'}}>
                        <div className="flex-[1] table_item">New</div>
                        <div className='flex-[2] table_item'>
                            <input value={newRow.name||''} onChange={handleNewItemChange('name')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                        </div>
                        <div className='flex-[4] table_item'>
                            <input value={newRow.number||''} onChange={handleNewItemChange('number')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                        </div>
                        <div className='flex-[2] table_item'>
                            <input value={newRow.expireDate||''} onChange={handleNewItemChange('expireDate')} className="w-full h-full bg-[transparent]" style={{border:'0.5px solid white',borderRadius:'12px',padding:'8px 8px 8px 8px'}}/>
                        </div>
                        <div className="w-[100px] flex flex-row items-center table_item">
                            <div className='cursor-pointer' onClick={handleNewItem}><AddEngineSvg/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)