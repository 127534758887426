import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={7}
    fill="none"
    {...props}
  >
    <path stroke="#fff" d="m1 1.243 4.242 4.242 4.243-4.242" />
  </svg>
)
export default SvgComponent
