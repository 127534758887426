import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { server_ip } from '../../../../config';

//svgs
function FormImage({isPreview, sectionIdx, fieldIdx, fieldObj}) {
    const {fieldData:{formData,imageDescription,imageTitle,imgId,newImgId}, fieldId, fullWidth, required} = fieldObj
    const [imgEle, setImgEle] = useState([])
    useEffect(() => {
        const imgElement = []
        if(isPreview && newImgId?.length > 0){
            if(imgId?.length > 0){
                imgId?.forEach(async ({id, type}, i) => {
                    if(!type) return
                    const [_, fileType] = type.split('/')
                    imgElement.push(server_ip+'/api/documents/'+id+'.'+fileType)
                })
            }
            for(const obj of formData.entries()){
                const objectURL = URL.createObjectURL(obj[1]);
                imgElement.push(objectURL)
            }
        }else{
            imgId?.forEach(async ({id, type}, i) => {
                if(!type) return
                const [_, fileType] = type.split('/')
                imgElement.push(server_ip+'/api/documents/'+id+'.'+fileType)
            })
        }
        setImgEle(imgElement)
    },[])
    return (
    <div className='mb-[32px] w-full mt-[24px]'>
        <div className='flex flex-col mb-[32px]'>
            <div className='flex flex-row text-[14px]'>{imageTitle}</div>
            <div className='text-[12px] ml-[16px] text-[#797979]'>{imageDescription}</div>
        </div>
        <div key={fieldId} className={'flex flex-row px-[12px] mt-[24px] w-full flex-wrap gap-1 items-center'}>
            {imgEle?.length > 0 && imgEle.map((link, i) => (<div key={link} className='inline-block' style={{width: fullWidth ? '100%' : '49%'}}>
                <img src={link}></img>
            </div>))}
        </div>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormImage)