import { server_ip } from "../config";

async function fetchUsersList(token,option={},filter={}) {
    try {
        const response = await fetch(`${server_ip}/api/users/admin/list?option=${JSON.stringify(option)}&filter=${JSON.stringify(filter)}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchUserData(token) {
    try {
        const response = await fetch(`${server_ip}/api/users/data`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchRolesList(token, filter={}, options={}) {
    try {
        const response = await fetch(`${server_ip}/api/roles/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                filter,
                options
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
async function createNewRole(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/roles`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editRole(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/roles/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createAccount(token,username,password,role,projectId,data){
    try {
        const response = await fetch(`${server_ip}/api/users/admin/createAccount`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                username,
                password,
                role,
                projectId,
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editUserData(token,userId,role,data){
    try {
        const response = await fetch(`${server_ip}/api/users/admin/data`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId,
                role,
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function changeUserPW(token,userId,password){
    try {
        const response = await fetch(`${server_ip}/api/users/admin/changePW`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId,
                password
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function deleteAccount(token,userId){
    try {
        const response = await fetch(`${server_ip}/api/users/admin/deleteAccount`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

export{
    fetchUsersList,
    fetchRolesList,
    createNewRole,
    editRole,
    createAccount,
    fetchUserData,
    editUserData,
    changeUserPW,
    deleteAccount,
}