import {DEVICE_TYPE} from '../../dto/devices';
import WatchSvg from '../../svgs/map/Watch';
import CameraSvg from '../../svgs/map/Camera';
import LockSvg from '../../svgs/map/Lock';
import DangerZoneSvg from '../../svgs/map/DangerZone';
import ConfinedSpaceSvg from '../../svgs/map/ConfinedSpace';
import "./MapPoint.css"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Battery from './BatteryStatus';
import { sethomepagePopup } from '../../actions/actions';
import DetailsSvg from '../../svgs/map/Details';
import UnlockBtn from '../../svgs/map/UnlockButton';
import LatestSnapshot from '../../components/camera/LatestSnapshot'

function MapPointComponent ({type,cluster:data,dispatch,homepagePopup,expandOnInit }) {
    const navigate = useNavigate()
    // const [windowSize, setWindowSize] = useState(getWindowSize());
    // function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
    // useEffect(()=>{
    //     function handleWindowResize() {setWindowSize(getWindowSize()); }
    //     window.addEventListener('resize', handleWindowResize);
    //     return () => {window.removeEventListener('resize', handleWindowResize);};
    // },[])
    function detailNav(type, name){
        if(!name) return
        const obj = {
            'watch': `/smartwatch?search=${name.split(' ').join('+')}`,
            'lock':`/smartlock?alert_search=${name.split(' ').join('+')}&locking_search=${name.split(' ').join('+')}`,
            'camera': `/camera?search=${name.split(' ').join('+')}`
        }
        navigate(`${obj[type]}`)
    }
    function togglePopup(){
        if(!homepagePopup){
            dispatch(sethomepagePopup(data))
        }else if(homepagePopup?.properties?.data?.id === data.properties?.data?.id){
            dispatch(sethomepagePopup(null))
        }else{
            dispatch(sethomepagePopup(data))
        }
    }
    const device= data?.properties?.data
    const isShowDetail =homepagePopup?.properties?.data?.id === device.id

    useEffect(()=>{
        if(expandOnInit)
            dispatch(sethomepagePopup(data))
    },[expandOnInit])

    if(type===DEVICE_TYPE.WATCH)
        return (
            <div className='relative flex flex-col justify-center items-center circle-grandient' style={{zIndex:isShowDetail?10:5}}>
                <div onClick={togglePopup} className='z-[1] w-[60px] h-[60px] flex items-center justify-center'>
                    <WatchSvg/>
                </div>
                {isShowDetail&&<div className='absolute top-[30px] left-[30px]' onClick={() => console.log(data)}>
                    <div className='w-[10px] h-[10px] rounded-[5px]s'></div>
                    <svg className='absolute top-[3px] left-[3px]' viewBox='0 0 50 50' style={{width:50,height:50}}>
                        <line x1={0} y1={0} x2={50} y2={50} style={{stroke:'#919191',strokeWidth:3}}/>
                    </svg>
                    <div className='absolute top-[43px] left-[43px] p-[10px] rounded-[8px]' style={{border: '0.5px solid #FFFFFF40'}}>
                        <div className='p-[12px] rounded-[8px] flex flex-col' style={{border: '0.5px solid #919191',minWidth:400,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',}}>
                            <div className='w-full text-lg text-white font-bold flex flex-row'>{`${device?.data?.name} (${device?.id})`}</div>
                            <div className='w-full mt-[10px] h-[1px] bg-[#919191]'></div>
                            <div className='flex flex-row mt-[10px] items-stretch'>
                                <Battery percentage={device?.data?.battery||0}/>
                                <div className='flex flex-col flex-1 text-white ml-[24px] justify-between'>
                                    <div className='flex flex-row w-full'>
                                        <div className='text-sm font-bold w-[100px]'>Status</div>
                                        {   device?.data?.isConnected?
                                            <div className='text-base font-bold flex-1 text-[#63BDFF]'>Active</div>
                                            :<div className='text-base font-bold flex-1 text-[#ffffffa0]'>Sleep</div>
                                        }
                                    </div>
                                    <div className='flex flex-row w-full'>
                                        <div className='text-sm font-bold w-[100px]'>Heart Rate</div>
                                        <div className='text-base font-bold flex-1'>{device?.data?.heartRate||'-'} BPM</div>
                                    </div>
                                    <div className='flex flex-row w-full'>
                                        <div className='text-sm font-bold w-[100px]'>Body Temp</div>
                                        <div className='text-base font-bold flex-1'>{device?.data?.temperature||'-'} °C</div>
                                    </div>
                                    <div className='flex flex-row w-full'>
                                        <div className='text-sm font-bold w-[100px]'>Standstill</div>
                                        <div className='text-base font-bold flex-1'>{device?.data?.standstill ? 'Yes' : device?.data?.standstill === null ? '-' : 'No'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between text-white mt-[10px]'>
                                <div className='text-sm flex-1'>Last update: {new Date(device?.modifyDate).toLocaleString()}</div>
                                <div className='text-base flex flex-row gap-[8px] items-center cursor-pointer' onClick={() => detailNav(type, device?.data?.name)}>details
                                    <DetailsSvg/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    else if(type===DEVICE_TYPE.LOCK)
        return (
            <div  className='relative flex flex-col justify-center items-center circle-grandient'  style={{zIndex:isShowDetail?10:5}}>
                <div onClick={togglePopup} className='z-[1] w-[60px] h-[60px] flex items-center justify-center'>
                    <LockSvg/>
                </div>
                {isShowDetail&&<div className='absolute top-[30px] left-[30px]' onClick={() => console.log(data)}>
                    <div className='w-[10px] h-[10px] rounded-[5px]s'></div>
                    <svg className='absolute top-[3px] left-[3px]' viewBox='0 0 50 50' style={{width:50,height:50}}>
                        <line x1={0} y1={0} x2={50} y2={50} style={{stroke:'#919191',strokeWidth:3}}/>
                    </svg>
                    <div className='absolute top-[43px] left-[43px] p-[10px] rounded-[8px]' style={{border: '0.5px solid #FFFFFF40'}}>
                        <div className='p-[12px] rounded-[8px] flex flex-col' style={{border: '0.5px solid #919191',minWidth:400,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',}}>
                            <div className='w-full text-lg text-white font-bold flex flex-row justify-between'>
                                {`${device?.data?.name} (${device?.id})`}
                                <div className='cursor-pointer'><UnlockBtn/></div>
                            </div>
                            <div className='w-full mt-[10px] h-[1px] bg-[#919191]'></div>
                            <div className='flex flex-row mt-[10px] items-stretch'>
                                <Battery percentage={device?.data?.battery||0}/>
                                <div className='flex flex-col flex-1 text-white ml-[12px] justify-center items-center'>
                                    <div className='text-sm font-bold'>Status</div>
                                </div>
                                <div className='flex flex-col flex-1 text-white ml-[12px] justify-center items-center'>
                                    {   device?.data?.lock_status?
                                        <div className='text-[16px] text-[#29FFD9]'>Locked</div>
                                        :<div className='text-[16px] text-[#ffffffc0]'>Unlocked</div>
                                    }
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between text-white mt-[10px]'>
                                <div className='text-sm flex-1'>Last update: {new Date(device?.modifyDate).toLocaleString()}</div>
                                <div className='text-base flex flex-row gap-[8px] items-center cursor-pointer' onClick={() => detailNav(type,device?.data?.name)}>details
                                    <DetailsSvg/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    else if(type===DEVICE_TYPE.CAMERA){
        return (
            <div className='relative flex flex-col justify-center items-center diamond-gradient' style={{zIndex:isShowDetail?10:5}}>
                <div onClick={togglePopup} className='z-[1] w-[60px] h-[60px] flex items-center justify-center'>
                    <CameraSvg/>
                </div>
                {isShowDetail&&<div className='absolute top-[30px] left-[30px]' onClick={() => console.log(data)}>
                    <div className='w-[10px] h-[10px] rounded-[5px]s'></div>
                    <svg className='absolute top-[3px] left-[3px]' viewBox='0 0 50 50' style={{width:50,height:50}}>
                        <line x1={0} y1={0} x2={50} y2={50} style={{stroke:'#919191',strokeWidth:3}}/>
                    </svg>
                    <div className='absolute top-[43px] left-[43px] p-[10px] rounded-[8px]' style={{border: '0.5px solid #FFFFFF40'}}>
                        <div className='p-[12px] rounded-[8px] flex flex-col' style={{border: '0.5px solid #919191',minWidth:400,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',}}>
                            <div className='w-full text-lg text-white font-bold flex flex-row justify-between'>
                            {`${device?.data?.name} (${device?.id})`}
                            </div>
                            <div className='w-full mt-[10px] h-[1px] bg-[#919191]'></div>
                            <div className='w-full mt-[10px]'>
                                {/* <img src='./temp1.png' style={{objectFit:'contain',aspectRatio:2880/1654,height:undefined,width:'100%',borderRadius:8}} alt='danger zone'/> */}
                                <LatestSnapshot deviceId={device?.id}/>
                            </div>
                            <div className='flex flex-row mt-[10px] items-stretch'>
                                <div className='flex flex-col flex-1 text-white justify-center items-start'>
                                    <div className='text-sm font-bold'>Status</div>
                                </div>
                                <div className='flex flex-col flex-1 justify-center items-end'>
                                    <div className='text-[16px] text-[#63BDFF]'>{device.data.isConnected ? 'Connected' : 'Asleep'}</div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between text-white mt-[10px]'>
                                <div className='text-sm flex-1'>Last update: {new Date(device.modifyDate).toLocaleString()}</div>
                                <div className='text-base flex flex-row gap-[8px] items-center cursor-pointer' onClick={() => detailNav(type,device?.data?.name)}>details
                                    <DetailsSvg/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                    {/* {cameraPopup?.properties?.data?.data?.id=== data?.properties?.data?.data?.id&&
                        <svg className='absolute top-[50%] left-[100%]' viewBox={`0 0 ${windowSize.innerWidth} 3`} style={{width:windowSize.innerWidth,height:3,marginTop:-1}}>
                            <line x1={0} y1={0} x2={windowSize.innerWidth} y2={0} style={{stroke:'white',strokeWidth:3}}/>
                        </svg>
                    } */}
                </div>
        )
    }
    else if(type===DEVICE_TYPE.DANGER_ZONE)
        return (
            <div className='relative flex flex-col justify-center items-center circle-grandient'  style={{zIndex:isShowDetail?10:5}}>
                <div onClick={togglePopup} className='z-[1] w-[60px] h-[60px] flex items-center justify-center'>
                    <DangerZoneSvg/>
                </div>
                {isShowDetail&&<div className='absolute top-[30px] left-[30px]'>
                    <div className='w-[10px] h-[10px] rounded-[5px]s'></div>
                    <svg className='absolute top-[3px] left-[3px]' viewBox='0 0 50 50' style={{width:50,height:50}}>
                        <line x1={0} y1={0} x2={50} y2={50} style={{stroke:'#919191',strokeWidth:3}}/>
                    </svg>
                    <div className='absolute top-[43px] left-[43px] p-[10px] rounded-[8px]' style={{border: '0.5px solid #FFFFFF40'}}>
                        <div className='p-[12px] rounded-[8px] flex flex-col' style={{border: '0.5px solid #919191',minWidth:400,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',}}>
                            <div className='w-full text-lg text-white font-bold flex flex-row justify-between'>
                                Danger Zone #{device?.id}
                            </div>
                            <div className='w-full mt-[10px] h-[1px] bg-[#919191]'></div>
                            <div className='w-full mt-[10px]'>
                                <img src='./temp2.png' style={{objectFit:'contain',aspectRatio:1672/1512,height:undefined,width:'100%'}} alt='danger zone'/>
                            </div>
                            <div className='flex flex-row mt-[10px] items-stretch'>
                                <div className='flex flex-col flex-1 text-white justify-center items-start'>
                                    <div className='text-sm font-bold'>Status</div>
                                </div>
                                <div className='flex flex-col flex-1 justify-center items-end'>
                                    <div className='text-[16px] text-[#63BDFF]'>Activated</div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between text-white mt-[10px]'>
                                <div className='text-sm flex-1'>Last update: {new Date().toLocaleString()}</div>
                                <div className='text-base flex flex-row gap-[8px] items-center cursor-pointer' onClick={() => detailNav(type,device?.data?.name)}>details
                                    <DetailsSvg/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        )
    else if(type===DEVICE_TYPE.CONFINED_SPACE)
    return (
        <div className='relative flex flex-col justify-center items-center circle-grandient'   style={{zIndex:isShowDetail?10:5}}>
            <div onClick={togglePopup} className='z-[1] w-[60px] h-[60px] flex items-center justify-center'>
                <ConfinedSpaceSvg/>
            </div>
            {isShowDetail&&<div className='absolute top-[30px] left-[30px]'>
                <div className='w-[10px] h-[10px] rounded-[5px]s'></div>
                <svg className='absolute top-[3px] left-[3px]' viewBox='0 0 50 50' style={{width:50,height:50}}>
                    <line x1={0} y1={0} x2={50} y2={50} style={{stroke:'#919191',strokeWidth:3}}/>
                </svg>
                <div className='absolute top-[43px] left-[43px] p-[10px] rounded-[8px]' style={{border: '0.5px solid #FFFFFF40'}}>
                    <div className='p-[12px] rounded-[8px] flex flex-col' style={{border: '0.5px solid #919191',minWidth:400,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',}}>
                        <div className='w-full text-lg text-white font-bold flex flex-row justify-between'>
                           Confined Space #{device?.id}
                        </div>
                        <div className='w-full mt-[10px] h-[1px] bg-[#919191]'></div>
                        <div className='flex flex-row mt-[10px] items-stretch'>
                            <div className='flex flex-col flex-1 text-white justify-center items-start text-sm font-bold'>
                                Status
                            </div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px] text-[#63BDFF]'>
                                Activated
                            </div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>Co2</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.22ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>O2</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.29ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>H2s</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.32ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>H2</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.02ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>N2o</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.02ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>S2o</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.23ppmi</div>
                        </div>
                        <div className='flex flex-row mt-[10px] items-stretch text-white'>
                            <div className='flex flex-col flex-1 justify-center items-start text-[12px] font-bold'>N2</div>
                            <div className='flex flex-col flex-1 justify-center items-start text-[16px]'> 0.12ppmi</div>
                        </div>

                        <div className='w-full flex flex-row justify-between text-white mt-[16px]'>
                            <div className='text-sm flex-1'>Last update: {new Date().toLocaleString()}</div>
                            <div className='text-base flex flex-row gap-[8px] items-center cursor-pointer' onClick={() => detailNav(type,device?.data?.name)}>details
                                <DetailsSvg/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
    return (
        <div className='text-[24px] rounded-[50px] w-[50px] h-[50px] flex justify-center items-center text-[red]'>
            {type}
        </div>
    )
}

export default MapPointComponent;