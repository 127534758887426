import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import DropdownMenu from '../../components/DropdownMenu';
import { createAccount } from "../../services/users.service";
import LoadingPage from "../Loading";
import {validateEmail}from '../../utils/util';

function Mask(){return(
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.9987 3.5013C13.157 3.5013 15.9737 5.2763 17.3487 8.08464C16.857 9.1013 16.1654 9.9763 15.3404 10.6846L16.5154 11.8596C17.6737 10.8346 18.5904 9.5513 19.1654 8.08464C17.7237 4.4263 14.1654 1.83464 9.9987 1.83464C8.94036 1.83464 7.9237 2.0013 6.96536 2.30964L8.34036 3.68464C8.88203 3.5763 9.43203 3.5013 9.9987 3.5013ZM9.10703 4.4513L10.832 6.1763C11.307 6.38464 11.6904 6.76797 11.8987 7.24297L13.6237 8.96797C13.6904 8.68464 13.7404 8.38463 13.7404 8.0763C13.7487 6.00964 12.0654 4.33464 9.9987 4.33464C9.69036 4.33464 9.3987 4.3763 9.10703 4.4513ZM1.6737 1.7263L3.90703 3.95964C2.5487 5.0263 1.4737 6.44297 0.832031 8.08464C2.2737 11.743 5.83203 14.3346 9.9987 14.3346C11.2654 14.3346 12.482 14.093 13.5987 13.6513L16.4487 16.5013L17.6237 15.3263L2.8487 0.542969L1.6737 1.7263ZM7.9237 7.9763L10.0987 10.1513C10.0654 10.1596 10.032 10.168 9.9987 10.168C8.8487 10.168 7.91536 9.23464 7.91536 8.08464C7.91536 8.04297 7.9237 8.01797 7.9237 7.9763ZM5.09036 5.14297L6.5487 6.6013C6.35703 7.05964 6.2487 7.55964 6.2487 8.08464C6.2487 10.1513 7.93203 11.8346 9.9987 11.8346C10.5237 11.8346 11.0237 11.7263 11.4737 11.5346L12.2904 12.3513C11.557 12.5513 10.7904 12.668 9.9987 12.668C6.84036 12.668 4.0237 10.893 2.6487 8.08464C3.23203 6.89297 4.08203 5.90964 5.09036 5.14297Z" fill="white"/>
    </svg>
)}



function Component(props){ 
    const [isLoading,setisLoading]=useState(false)
    const [newRecord,setNewRecord]=useState({isMask:true})
    const [createError,setcreateError]=useState('')
    const handleInput=(field)=>(e)=>{
        setNewRecord({...newRecord,[field]:e.target.value})
    }
    const toggleMask=()=>{ setNewRecord({...newRecord,isMask:!newRecord.isMask})}
    const handleCancel=()=>{props.cancel()}
    const selectRole=(item,index)=>{
        setNewRecord({...newRecord,role:item})
    }
    const handleCreate=async()=>{
        if(isLoading)return
        if(!validateEmail(newRecord.username)){
            setcreateError('Invalid Email')
            return
        }else if(!(newRecord?.password?.length>=5)){
            setcreateError('Invalid Password. Password must be at least 5 characters')
            return
        }else if(newRecord.password!==newRecord.confirmPassword){
            setcreateError('Confirm Password not match')
            return
        }else if(!newRecord.role){
            setcreateError('Please select a role')
            return
        }else{
            setcreateError('')
        }
        setisLoading(true)
        const data={
            phone:newRecord?.phone||'',
            firstname:newRecord?.firstname||'',
            lastname:newRecord?.lastname||'',
        }
        let result = await createAccount(props.token?.token,newRecord.username,newRecord.password,newRecord.role,props.control?.projectId,data)
        if(result.errno){
            setcreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            props.refresh();
            props.cancel();
        }
        setisLoading(false)
    }


    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                <div className='flex flex-row items-center space-between w-full'>
                    <div className='font-bold'>Add User</div>          
                </div>
                <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancel}>X</div>         

                
                <div className="text-white mt-5 font-bold text-[14px]">Email</div>
                <input value={(newRecord?.username)||""} onChange={handleInput('username')} placeholder='Email' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px]">Phone</div>
                <input value={(newRecord?.phone)||""} onChange={handleInput('phone')} placeholder='Phone' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px]">First Name</div>
                <input value={(newRecord?.firstname)||""} onChange={handleInput('firstname')} placeholder='First name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px]">Last Name</div>
                <input value={(newRecord?.lastname)||""} onChange={handleInput('lastname')} placeholder='Last name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px] mb-1">Role</div>
                <div className="w-full flex flex-col">
                    <DropdownMenu select={selectRole} value={(newRecord?.role)||"Select a role"} isFullWidth={true} options={props.roles?.map(i=>i.name)}/>
                </div>

                <div className="text-white mt-5 font-bold text-[14px]">Password</div>
                <div className="w-full flex flex-col relative justify-center">
                    <input value={(newRecord?.password)||""} type={newRecord.isMask?'password':'text'} onChange={handleInput('password')} placeholder='Password' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>
                    <div className="absolute right-[16px] mt-[4px] cursor-pointer" onClick={toggleMask} style={{opacity:newRecord.isMask?1:.5}}>
                        <Mask/>
                    </div>
                </div>

                <div className="text-white mt-5 font-bold text-[14px]">Confirm Password</div>
                <div className="w-full flex flex-col relative justify-center">
                    <input value={(newRecord?.confirmPassword)||""} type={newRecord.isMask?'password':'text'} onChange={handleInput('confirmPassword')} placeholder='Confirm password' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>
                    <div className="absolute right-[16px] mt-[4px] cursor-pointer" onClick={toggleMask} style={{opacity:newRecord.isMask?1:.5}}>
                        <Mask/>
                    </div>
                </div>

                <div className='w-full text-[red] text-xs'>{createError}</div>

                <div onClick={handleCreate} className='button_blue w-full mt-5' style={{padding:'6px 0px'}}>
                    Create
                </div>
            </div>
            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)