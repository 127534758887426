import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#555"
      d="M31.007 15.753c0 8.425-6.83 15.254-15.254 15.254S.5 24.177.5 15.753 7.33.5 15.753.5c8.425 0 15.254 6.83 15.254 15.253Z"
    />
    <path
      stroke="#fff"
      d="M13.922 8.249a3.5 3.5 0 0 1 3.5 0l4.058 2.343a3.5 3.5 0 0 1 1.75 3.031v4.686a3.5 3.5 0 0 1-1.75 3.031l-4.058 2.343a3.5 3.5 0 0 1-3.5 0L9.864 21.34a3.5 3.5 0 0 1-1.75-3.03v-4.687a3.5 3.5 0 0 1 1.75-3.031l4.058-2.343Z"
    />
    <circle cx={15.754} cy={15.966} r={2.535} stroke="#fff" />
  </svg>
)
export default SvgComponent
