import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";

//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'


function Screen(props){

    const navigate = useNavigate();



    return(
        <div className="flex-col bg-[#0C0D14] flex-1 text-[14px] h-full pb-12" style={{overflowY:'auto'}}>

            <div className=''style={{...LEFTRIGHT_PADDING}}>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold "> Setting</div>
                </div>
                
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)