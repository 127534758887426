import { useLayoutEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { GOOGLEMAPAPIKEY } from "../config"
import GoogleMapReact from 'google-map-react';
import { defaultMapProps, DRAW_ZONE, DRAW_ZONE_2, DRAW_ZONE_3, DRAW_ZONE_3_COLOR_1, DRAW_ZONE_3_COLOR_2, ENABLE_DRAW_ZONE } from "../dto/googleMapDefault";
import MapPointComponent from "./home/MapPoint";
import { mapStyleDark } from "../dto/googleMapStyles";

function Component({data,type,dispatch,control,close}){
    const mapRef = useRef();
    const [zoom,setZoom]=useState(defaultMapProps.zoom)
    const [bounds, setBounds] = useState(null);
    const [mapType,setmapType]=useState('hybrid')

    useLayoutEffect(()=>{
        
    },[])

    const closePopup=()=>{if(close)close()}
    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%] flex items-center justify-center' style={{border:'.5px solid #FFFFFF80'}}>

                <div className='relative w-full h-full overflow-hidden' style={{opacity:.8}}>
                    <div className='w-full h-full' style={{}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLEMAPAPIKEY ,libraries: ['drawing']}}
                            defaultCenter={(data?.data?.latitude&&data?.data.longitude)?{lat: Number(data?.data?.latitude),lng: Number(data?.data.longitude)}:defaultMapProps?.center}
                            defaultZoom={17}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map ,maps}) => { 
                                if(!ENABLE_DRAW_ZONE)return;
                                mapRef.current = map;
                                let polygon= new maps.Polygon({
                                    paths:DRAW_ZONE,
                                    strokeColor:'#29FFD9',
                                    fillColor:'#29FFD980'
                                })
                                polygon.setMap(map)

                                if(DRAW_ZONE_2){
                                    let polygon2= new maps.Polygon({paths:DRAW_ZONE_2,strokeColor:'#29FFD9', fillColor:'#29FFD980'})
                                    polygon2.setMap(map)
                                }
                                if(DRAW_ZONE_3){
                                    let polygon3= new maps.Polygon({ paths:DRAW_ZONE_3,strokeColor:DRAW_ZONE_3_COLOR_1||'#29FFD9',fillColor:DRAW_ZONE_3_COLOR_2||'#29FFD980'})
                                    polygon3.setMap(map)
                                }
                            }}
                            onChange={(e)=>{
                                setZoom(e.zoom)
                                setBounds([ e.bounds.nw.lng,e.bounds.se.lat,e.bounds.se.lng,e.bounds.nw.lat]);
                            }}
                            options={map => ({
                                streetViewControl: true,
                                scaleControl: true,
                                zoomControl: true,
                                fullscreenControl: true,
                                controlSize: 22,
                                mapTypeId: mapType,
                                mapTypeControl: true,
                                mapTypeControlOptions: {
                                style: map.MapTypeControlStyle.SMALL,
                                position: map.ControlPosition.TOP_LEFT,
                                    mapTypeIds: [
                                        map.MapTypeId.ROADMAP,
                                        map.MapTypeId.SATELLITE,
                                        map.MapTypeId.HYBRID,
                                        map.MapTypeId.TERRAIN,
                                    ]
                                },
                                styles:mapStyleDark,
                                tilt:0,
                            })}
                            onMapTypeIdChange={(e)=>{setmapType(e)}}
                        >

                            
                            <MapPointComponent
                                type={type}
                                lat={Number(data?.data?.latitude)}
                                lng={Number(data?.data?.longitude)}
                                cluster={{properties:{data}}}
                                dispatch={dispatch}
                                homepagePopup={control.homepagePopup}
                                expandOnInit={true}
                            />
                                
                            
                        </GoogleMapReact>
                    </div>
                </div>

                <div className='absolute top-[64px] right-[64px] text-[36px] font-bold ml-20 cursor-pointer select-none bg-[#00000090] rounded-[24px] w-[48px] h-[48px] flex items-center justify-center' onClick={closePopup}>X</div>  

            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)