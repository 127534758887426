import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={15}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="m.486 14.85 7.07-7.072L.487.708v14.141Z" />
  </svg>
)
export default SvgComponent
