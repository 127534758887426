import { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchSnapshotByTime } from "../../services/cameras.service"

function Component(props){
    const [data,setData]=useState(null)
    const fetchPhoto=async()=>{
        let result = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,true)
       if(result?.result?.[0]?.time===props.time){
            setData(result?.result?.[0])
       }else{
            let result2 = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,false)
            if(result2?.result?.[0]?.data){
                let Diff1 = (result?.result?.[0]?.time - props.time) || 9999999999999
                let Diff2 = (props.time - result2?.result?.[0]?.time) || 9999999999999
                if(Diff1<Diff2 && result?.result?.[0]){
                    setData(result?.result?.[0])
                }else if(setData(result2?.result?.[0])){
                    setData(result2?.result?.[0])
                }
            }
        }
    }
    useLayoutEffect(()=>{
        let timeOut=null
        if(props.deviceId&&props.time){
            if(props.isReport){
                timeOut= setTimeout(fetchPhoto , props.index*500 )
            }else {
                fetchPhoto()
            }
        }
        return ()=>{
            if(timeOut){
                clearTimeout(timeOut)
            }
        }
    },[props.deviceId,props.time,props.control?.projectId,props.isReport,props.index])

    const [showImagePopup,setshowImagePopup]=useState(false)
    const showPopup=()=>{setshowImagePopup(true)}
    const closePopup=()=>{setshowImagePopup(false)}

    return(
        <div className='w-full flex flex-col justify-center items-center' style={{aspectRatio:1920/1080,height:undefined}}>
            {data?
                <div className="w-full h-full cursor-pointer">
                <img onClick={showPopup} src={`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                </div>
                :<div className="text-white text-[10px]">No Snapshot</div>
            }

            {showImagePopup&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%]' style={{border:'.5px solid #FFFFFF80'}}>
                   
                    <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none' onClick={closePopup}>X</div>         
                    <img src={`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                </div>
            </div>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)