import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M9.001.166A8.336 8.336 0 0 0 .668 8.499c0 4.6 3.733 8.334 8.333 8.334s8.334-3.734 8.334-8.334S13.6.166 9 .166Zm0 15a6.676 6.676 0 0 1-6.666-6.667A6.676 6.676 0 0 1 9 1.833a6.676 6.676 0 0 1 6.667 6.666 6.676 6.676 0 0 1-6.667 6.667Zm-2.083-2.917 5.833-3.75-5.833-3.75v7.5Z"
    />
  </svg>
)
export default SvgComponent
