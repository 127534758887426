import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <g clipPath="url(#alertphoto)">
      <path
        fill="#fff"
        d="M15.833 3H4.167C3.25 3 2.5 3.75 2.5 4.667v11.666C2.5 17.25 3.25 18 4.167 18h11.666c.917 0 1.667-.75 1.667-1.667V4.667C17.5 3.75 16.75 3 15.833 3Zm0 13.333H4.167V4.667h11.666v11.666Zm-4.2-5.591-2.291 2.95-1.634-1.967-2.291 2.942h9.166l-2.95-3.925Z"
      />
    </g>
    <defs>
      <clipPath id="alertphoto">
        <path fill="#fff" d="M0 .5h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
