export function create7DayLabel(){
    const today = new Date(new Date().setHours(0,0,0,0))
    today.setDate(today.getDate() + 1)
    let oneZoomLable = []
    for(let i = 14; i >0; i--){
        const temp = new Date(today)
        temp.setHours(today.getHours() - (12 * (i)))
        oneZoomLable.push(temp.toLocaleDateString()+ '' + (temp.toLocaleTimeString()[0] == 0 ? 'am' : 'pm'))
    }
    let twoZoomLabel = []
    for(let i = 7; i > 0; i--){
        const temp = new Date(today)
        temp.setDate(today.getDate() - (1 * (i)))
        twoZoomLabel.push(temp.toLocaleDateString())
    }
    return {
        1: oneZoomLable,
        2: twoZoomLabel
    }
}
export function create30DayLabel(){
    const today = new Date(new Date().setHours(0,0,0,0))
    today.setDate(today.getDate())
    let oneZoomLable = []
    for(let i = 14; i >= 0; i--){
        const temp = new Date(today)
        temp.setDate(today.getDate() - (2 * (i)))
        const temp2 = new Date(temp)
        temp2.setDate(temp2.getDate() - 2)
        oneZoomLable.push(temp2.toLocaleDateString() + ' - '+temp.toLocaleDateString())
    }
    let twoZoomLabel = []
    for(let i = 3; i >= 0; i--){
        const temp = new Date(today)
        temp.setDate(today.getDate() - (6 * (i)))
        const temp2 = new Date(temp)
        temp2.setDate(temp2.getDate() - 6)
        twoZoomLabel.push(`${temp2.toLocaleDateString()} - ${temp.toLocaleDateString()}`)
    }
    return {
        1: oneZoomLable,
        2: twoZoomLabel
    }
}
export function createDayLabel(){
    return {
        1:['0:00am','1:00am','2:00am','3:00am','4:00am','5:00am','6:00am','7:00am','8:00am','9:00am','10:00am','11:00am',
            '12:00pm','1:00pm','2:00pm','3:00pm','4:00pm','5:00pm','6:00pm','7:00pm','8:00pm','9:00pm','10:00pm','11:00pm','12:00pm'],
        2:['0:00am','2:00am','4:00am','6:00am','8:00am','10:00am',
        '12:00pm','2:00pm','4:00pm','6:00pm','8:00pm','10:00pm'],
        3:['0:00am','3:00am','6:00am','9:00am','12:00pm','3:00pm','6:00pm','9:00pm',],
        4:['0:00am','4:00am','8:00am','12:00pm','4:00pm','8:00pm',],
        5:['0:00am','6:00am','12:00pm','6:00pm',],
        6:['0:00am-7:59am','8:00am-3:59pm','4:00pm-11:59pm',],
        7:['0:00am-11:59am','12:00pm-11:59am'],
        8:['0:00am-23:59am'],
    }
}