import { server_ip } from "../config";

async function fetchConfinedSpace(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/confinedSpaces/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editConfinedSpacesRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/confinedSpaces/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createConfinedSpacesRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/confinedSpaces`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteConfinedSpacesRecord(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/confinedSpaces/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchGasConfig(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/gasConfig/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editGasConfigRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/gasConfig/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createGasConfigRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/gasConfig`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchGasData(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/gasData/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchConfinedSpacesEvent(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"CONFINED_SPACE"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchConfinedSpaceEventStats(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/statistic`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"CONFINED_SPACE"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

export{
    fetchConfinedSpace,
    editConfinedSpacesRecord,
    createConfinedSpacesRecord,
    deleteConfinedSpacesRecord,
    fetchGasConfig,
    fetchGasData,
    fetchConfinedSpacesEvent,
    fetchConfinedSpaceEventStats,
    editGasConfigRecord,
    createGasConfigRecord
}