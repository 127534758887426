import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <circle cx={13} cy={13} r={12.5} fill="#fff" stroke="#929292" />
    <path fill="#000" d="M25 13a12 12 0 0 1-24 0h24Z" />
  </svg>
)
export default SvgComponent
