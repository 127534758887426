import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../../config/style.config";
import GreyBin from '../../svgs/permit/GrayBin'
import LoadingPage from "../Loading";
import { deleteWatchAlert } from "../../services/watches.service";
import { handleFilterDate } from "../../actions/actionsDateTime";
import { fetchConfinedSpacesEvent } from "../../services/confinedSpaces.service";
const CHART_TITLE = [
    {title: 'Item', field: null, flex: 1},
    {title: 'Date-Time', field: '', flex: 4},
    {title: 'Serial Number', field: 'deviceID', flex: 4},
    {title: 'Name', field: 'name', flex: 4},
    {title: 'Type', field: 'type', flex: 4},
    {title: 'Gas', field: 'type', flex: 4},
    {title: 'Action', field: '', inData:false, flex: 2, reportHidden:true},
]

const GAS_TYPE_DICT = {
    CS_O2_EXCEED_WARN: 'WARNING',
    CS_O2_EXCEED_EVACUATE: 'EVACUATE',
    CS_TEMPERATURE_WARN: 'WARNING',
    CS_TEMPERATURE_EVACUATE: 'EVACUATE',
    CS_PM25_EXCEED_WARN: 'WARNING',
    CS_PM25_EXCEED_EVACUATE: 'EVACUATE',
    CS_CO_EXCEED_WARN: 'WARNING',
    CS_CO_EXCEED_EVACUATE: 'EVACUATE',
    CS_CO2_EXCEED_WARN: 'WARNING',
    CS_CO2_EXCEED_EVACUATE: 'EVACUATE',
    CS_H2S_EXCEED_WARN: 'WARNING',
    CS_H2S_EXCEED_EVACUATE: 'EVACUATE',
    CS_CH4_EXCEED_WARN: 'WARNING',
    CS_CH4_EXCEED_EVACUATE: 'EVACUATE',
    CS_COM_GAS_EXCEED_WARN: 'WARNING',
    CS_COM_GAS_EXCEED_EVACUATE: 'EVACUATE',
}

const GAS_DICT = {
    CS_O2_EXCEED_WARN: 'O2',
    CS_O2_EXCEED_EVACUATE: 'O2',
    CS_TEMPERATURE_WARN: 'Temperature',
    CS_TEMPERATURE_EVACUATE: 'Temperature',
    CS_PM25_EXCEED_WARN: 'PM2.5',
    CS_PM25_EXCEED_EVACUATE: 'PM2.5',
    CS_CO_EXCEED_WARN: 'CO',
    CS_CO_EXCEED_EVACUATE: 'CO',
    CS_CO2_EXCEED_WARN: 'CO2',
    CS_CO2_EXCEED_EVACUATE: 'CO2',
    CS_H2S_EXCEED_WARN: 'H2S',
    CS_H2S_EXCEED_EVACUATE: 'H2S',
    CS_CH4_EXCEED_WARN: 'CH4',
    CS_CH4_EXCEED_EVACUATE: 'CH4',
    CS_COM_GAS_EXCEED_WARN: 'Compostable Gas',
    CS_COM_GAS_EXCEED_EVACUATE: 'Compostable Gas',
}

function WatchAlertList(props){
    const [ChartData, setChartData] = useState([])
    const [targetDelete, setTargetDelete] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
        fetchData()
    },[props.alertArr,searchParams])

    async function fetchData(){
        //console.log(props.alertArr)
        if(props.alertArr) return setChartData(props.alertArr)
        const filter = {}
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        filter["projectId"]= props.control.projectId
        const res = await fetchConfinedSpacesEvent(props.token.token, filter, {limit:999, sort: {time: -1}})
        if(res.errno) return
        //console.log(res.result)
        setChartData(res.result)
    }

    async function handleDelete(){
        setIsLoading(true)
        const res = await deleteWatchAlert(props.token.token, targetDelete._id)
        if(props.fetchData){
            props.fetchData()
        }else{
            fetchData()
        }
        setTargetDelete(null)
        setIsLoading(false)
    }
    return(
    <div className="py-[32px] px-[30px]">
        <div className="flex flex-row py-[16px] mt-5">
            {CHART_TITLE.map((obj, i) => (
                (props.isReport&&obj.reportHidden)?<div/>:
                    <div key={'title'+obj.title} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                        {obj.title}
                    </div>
            ))}
        </div>
        {targetDelete && 
            <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex: 999}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Confirm Delete Alert?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => setTargetDelete(null)}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm flex-1'>Name:</div>        
                        <div className='text-sm flex-2'>{targetDelete.name}</div>          
                    </div>
                    <div className='flex flex-row items-center space-between w-full mt-5'>
                        <div className='text-sm flex-1'>Alert Time:</div>       
                        <div className='text-sm flex-2'>{targetDelete?.time?new Date(targetDelete.time).toLocaleString():'-'}</div>          
                    </div>
                    <div onClick={handleDelete} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                        Delete
                    </div>
                </div>
                {isLoading && <LoadingPage />}
            </div>  
        }
        {ChartData.length > 0 && ChartData.map((items, idx) =>
            <div key={idx} className="table_row" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF1D':'transparent'}}>
                {CHART_TITLE.map((h, i) => 
                (props.isReport&&h.reportHidden)?<div/>:
                <div key={h.item + 'inner' + i} className="table_item" style={{flex: h.flex}}>{
                    h.inData ? (items.data[h.field] || '~') : 
                    h.title === 'Item' ? idx + 1 : 
                    h.title === 'Date-Time' ? <div className="w-full flex flex-col text-[10px]">
                        {items.time?new Date(items.time).toLocaleString():''}
                    </div> :
                    h.title === 'Type' ? GAS_TYPE_DICT[items[h.field]]||items[h.field] :
                    h.title === 'Gas' ? GAS_DICT[items[h.field]]||items[h.field] :
                    (h.title === 'Action' && props.control?.permissions?.SUMMARY_HIDE_ALERT) ? <GreyBin className='cursor-pointer' onClick={() => setTargetDelete({...items, idx})}/> :

                    (items[h?.field]) || '-'
                }
            </div>)}
        </div>)}
    </div>)
}

export default connect((store) => {
    return{
        token: store.tokenReducer,
        control: store.controlReducer,
    }
})(WatchAlertList)