import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { bgLineData } from "./func/FixedChartData";
import { create7DayLabel, createDayLabel, create30DayLabel } from "./func/LabelCreator";
import WatchChart from './WatchChart'
import { handleFilterDate } from "../../actions/actionsDateTime";
import { fetchConfinedSpaceEventStats } from "../../services/confinedSpaces.service";

const zoomIndexDict = {
    1:1,
    2:2,
    3:3,
    4:4,
    5:6,
    6:8,
    7:12,
    8:24
}
const zoomIndexLabelDict = createDayLabel()
const sevenDayLabels = create7DayLabel()
const tyDayLabels = create30DayLabel()
const labelsbyType = {
    "Today": zoomIndexLabelDict,
    "7 Days": sevenDayLabels,
    "30 Days": tyDayLabels,
}
function createDataSetObj(name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels){
    return {
        label: name,
        data: dataArr,
        toSub: toSub,
        xLabels: xLabels,
        pointRadius:5,
        pointBackgroundColor: pointBgColor,
        borderColor: pointBorderColor,
        fill: false,
        tension: 0.4,
        showLine:false
      }
}
function createTempData(data,zoomLevel, range){
    //   console.log(data)
        const newChartDataObj = {}
        newChartDataObj.data  = data.map(({name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels}) => {
            return createDataSetObj(name, dataArr, pointBgColor, pointBorderColor, toSub, xLabels)
        })
        newChartDataObj.data.unshift(bgLineData(newChartDataObj.data))
        const DATA_COUNT = data[0].dataArr.length;
        newChartDataObj.labels = labelsbyType[range][zoomLevel];

        //data need for chart
        return newChartDataObj
    }
function consolideArray(zoomLevel,datapoints = [], dateVal, customDates){
    let newArray =[]
    let count =0
    let sum =0
    let zoomVal = zoomIndexDict[zoomLevel]
    let idx = 0
    // if(dateVal === '30 Days' && zoomVal === 2) zoomVal = 4
    if(customDates){
        for(let num of datapoints){
            if(count === 0){
                sum = new Date(num).toLocaleDateString()
            }
            count++
            if(count === zoomVal || idx === datapoints.length - 1){
                if(zoomVal !== 1){
                    sum += ` - ${new Date(num).toLocaleDateString()}`
                }
                newArray.push(sum)
                count=0
                sum=0
            }
            idx++
        }
        return newArray
    }
    for(let num of datapoints){
        if(count< zoomVal){
            sum+=num
        }
        count++
        if(count=== zoomVal || idx === datapoints.length - 1){
            newArray.push(sum)
            count=0
            sum=0
        }
        idx++
    }
    return newArray||[]
}


const newData =(zoomLevel, sortedByTimeObj, dateVal)=> {
    const WARNINGArr = consolideArray(zoomLevel,sortedByTimeObj.WARNING, dateVal)
    const EVACUATEArr = consolideArray(zoomLevel,sortedByTimeObj.EVACUATE, dateVal)

    const labels = consolideArray(zoomLevel,sortedByTimeObj.timeLabels, dateVal, true)
    const WARNINGSub = WARNINGArr.map((num, i) => {
        if(num === 0) return 0
        let added = 0
        WARNINGArr[i] += added
        return added
    })
    const EVACUATESub = EVACUATEArr.map((num, i) => {
        if(num === 0) return 0
        let added = WARNINGArr[i]
        EVACUATEArr[i] += added
        return  added
    })
    return [
        {
            name: 'EVACUATE',
            dataArr: EVACUATEArr,
            toSub: EVACUATESub,
            xLabels: labels,
            pointBgColor: '#F47070',
            pointBorderColor: '#F47070',
        },
        {
            name: 'WARNING',
            dataArr: WARNINGArr,
            toSub: WARNINGSub,
            xLabels: labels,
            pointBgColor: '#FFC329',
            pointBorderColor: '#FFC329',
        },
    ]
}
//
function Component(props){
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const [ChartData, setChartData] = useState(undefined)
    const [sortedData, setSortedData] = useState({})
    const [maxValue, setMaxValue] = useState(0)
    const [zoomLevel,setZoomLevel]=useState(1)
    const [customRange, setCustomRange] = useState(undefined)
    // useEffect(() => {
    //     setChartData(createTempData(newData(zoomLevel),zoomLevel))
    // },[zoomLevel])
    useEffect(() => {
        fetchChartData()
    },[filterDate,filterFrom,filterTo])
    async function fetchChartData(){
        const keys = {
            "Today": 24,
            "7 Days": 7,
            "30 Days": 30,
        }
        const today = new Date(new Date().setHours(0,0,0,0))
        today.setDate(today.getDate() + 1)
        let timeRangeArr = []
        const sortedDataObj = {}
        let filter = handleFilterDate(filterDate, filterFrom, filterTo)
        const localRange = filterTo && filterFrom ? (new Date(filterTo) - new Date(filterFrom))  / (1000 * 60 * 60 * 24) : 0
        //console.log('localRange', localRange)
        setCustomRange(localRange)
        for(let i = (localRange == 0 ? keys[filterDate] : localRange); i >= 0; i--){
            const temp = localRange == 0 ? new Date(today) : new Date(filterTo)
            if(filterFrom && filterTo)temp.setDate(temp.getDate() - i)
            if(filterDate === 'Today' && localRange == 0)temp.setHours(today.getHours() - i)
            if(filterDate === '30 Days' || filterDate === '7 Days')temp.setDate(today.getDate() - (1 * (i)))
            timeRangeArr.push(temp)
        }
        const test = await fetchConfinedSpaceEventStats(props.token.token, {'time':filter, "projectId": props.control.projectId}, {limit:999})
        console.log(test)
        const data = test.result

        sortedDataObj.WARNING = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        sortedDataObj.EVACUATE = new Array((localRange == 0 ? keys[filterDate] : localRange) + 1).fill(0)
        data?.forEach((obj) => {
            //console.log(obj)
            const eventTime = new Date(obj.time)
            timeRangeArr.forEach((time, timeIdx) => {
                // console.log(new Date(time))
                let nextTimeSlot = timeRangeArr[timeIdx + 1]
                if(!nextTimeSlot){
                    nextTimeSlot = new Date(time)
                    nextTimeSlot.setDate(new Date(nextTimeSlot).getDate() + 1)
                }
                //if(timeIdx === (filterFrom && filterTo ? keys[filterDate] : localRange)) return;
                if(eventTime >= time && eventTime <= nextTimeSlot){
                    if(obj.type.includes('WARN'))sortedDataObj.WARNING[timeIdx]++
                    if(obj.type.includes('EVACUATE'))sortedDataObj.EVACUATE[timeIdx]++
                }
            })
        })
        //console.log(sortedDataObj)
        sortedDataObj.timeLabels = timeRangeArr
        setSortedData(sortedDataObj)
    }
    useEffect(() => {
        const data = createTempData(newData(zoomLevel, sortedData , filterDate),zoomLevel, filterDate)
        if(customRange || (filterDate === '30 Days')|| (filterDate === '7 Days')) data.labels = null
        setChartData(data)
        let localMax = 0
        data.data?.forEach((obj) => {
            if(obj.label !== '') localMax = Math.max(...obj.data, localMax)
        })
        setMaxValue(localMax)
        // console.log(localMax)
    },[zoomLevel, sortedData])
    const handleWheelEvent=(e)=>{
        let delta = e?.deltaY>0?1:-1
        let newLevel = zoomLevel+delta
        // if(filterDate === '7 Days') return setZoomLevel(newLevel<=1 ? 1 : newLevel>=2 ? 2 : newLevel)
        // if(filterDate === '30 Days') return setZoomLevel(newLevel<=1?1:newLevel>=2?2:newLevel)
        setZoomLevel(newLevel <= 1 ? 1 : newLevel >=8 ? 8 : newLevel)
    }
    return(
        <div key={`chart-${zoomLevel}`} className="text-white w-full" onWheel={handleWheelEvent}>
            <div className="flex flex-row justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
                <div className="text-[16px] font-[300]">Trends</div>
            </div>
            {ChartData && <WatchChart chartDataObj={ChartData} showLegend={false} maxValue={maxValue}/>}

            <div className="w-full flex flex-row itesm-center justify-center p-[24px] gap-[24px]">
                <div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#FFC329] flex rounded-full"/>
                    WARNING
                </div>
                <div className="flex flex-row items-center gap-[8px] text-[12px] text-[#A0B0C0]">
                    <div className="h-[8px] w-[8px] bg-[#F47070] flex rounded-full"/>
                    EVACUATE
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)