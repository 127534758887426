import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import Loading from '../components/Loading';
import DropDown from '../components/DropdownMenu';
import ToggleButton from '../components/ToggleButton'
import PageSelector from '../components/PageSelector'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import ChartChainLink from '../svgs/ChartChainLink'
import SendMessageSvg from '../svgs/watchPage/SendMessageSvg'
import { Login } from "../actions/actions";
import { fetchSmartWatch, sendWatchMessage } from "../services/watches.service";
import { fetchWatchMessages } from "../services/watchMessages.service";
//
import { handleFilterDate, yearMonthDay } from "../actions/actionsDateTime";

function SmartWatch(props){
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const navigate = useNavigate();
    const [isLoading,setisLoading]=useState(false)
    let [searchParams, setSearchParams] = useSearchParams();
    const [popType, setPopType] = useState(undefined)
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging


    const [data,setData]=useState([])
    const sortBy={createDate:-1};
    const fetchMessages=async(p=page,l=limit,st=sortBy)=>{
        //todo update filter
        const filter={
            projectId:props.control?.projectId
        }
        if(filterDate) filter['createDate'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['createDate'] = handleFilterDate('', filterFrom, filterTo)
        if(filter['createDate'])filter['createDate']['$gte'] = new Date(filter['createDate']['$gte'])
        if(filter['createDate'])filter['createDate']['$lte'] = new Date(filter['createDate']['$lte'])
        let result = await fetchWatchMessages(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
        }
    }

    useEffect(()=>{
        fetchMessages()
    },[page,props.control?.projectId, searchParams])




    const [watches,setWatches]=useState([])
    const fetchSmartWatches=async(p=1,l=999,st={'data.name':-1})=>{
        const filter={
            projectId:props.control?.projectId
        }
        let result = await fetchSmartWatch(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setWatches(result?.result);
            //setTotal(result?.count||0)
            //setactiveTotal(result?.activeCount||0)
        }
    }

    const selectWatch=(item,index)=>()=>{
        let d= [...watches]
        d[index].isChecked=!d[index].isChecked
        setWatches(d)
    }
    const [isAll,setisAll]=useState(false)
    const toggleAll=()=>{setisAll(!isAll)}
    useEffect(()=>{
        fetchSmartWatches()
    },[props.control.projectId])

    const [title,setTitle]=useState('')
    const [message,setMessage]=useState('')
    const handleTitleInput=(e)=>{setTitle(e.target.value)};
    const handleMessageInput=(e)=>{setMessage(e.target.value)};
    const sendMessages=async()=>{
        if(isLoading)return
        setisLoading(true)
        for(let watch of watches){
            if(isAll||watch.isChecked){
                const result = await sendWatchMessage(props.token?.token,props.control?.projectId,watch.id,title,message)
            }
        }
        setTitle('')
        setMessage('')
        setisLoading(false)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(key !== 'page'){
            searchParams.delete('page')
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }

    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-[14px] text-white'>
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
            <div style={{...LEFTRIGHT_PADDING}}>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Smart Watch</div>
                </div>
                <div className="flex flex-row justify-between items-center text-white">
                    <div className="flex flex-row mt-[27px]">
                        <div className="subpage_not_selected" onClick={() => navigate('/smartwatch/device-management')}>Device Management</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/smartwatch')}>Alert</div>
                        <div className="subpage_selected" onClick={() => navigate('/smartwatch/send-message')}>Send Message</div>
                    </div>
                </div>

                {/*message to smart watch*/}
                <div className="flex flex-col flex-1 min-h-[343px] gap-4 mb-[64px] text-[14px] mt-[24px] relative" style={{border: '.5px solid #FFFFFF80'}}>
                    
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px]" style={{}}>
                        <div className="flex flex-row items-center">
                            <div className="text-white text-[18px] font-bold flex items-center">
                                <TitleLeftSvg className='mr-[9px]'/>
                                Message to Smart Watch
                            </div>
                                <ChartChainLink className="mx-[10px] cursor-pointer"/>
                        </div>
                        <div className="text-white" style={{zIndex:1}}>
                            <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="table_header" style={{flex: 1}}>Title</div>
                        <div className="table_header" style={{flex: 3}}>Message</div>
                        <div className="table_header" style={{flex: 1}}>Serial Number</div>
                        <div className="table_header" style={{flex: 1}}>Device Name</div>
                        <div className="table_header" style={{flex: 1}}>Time</div>
                        <div className="table_header" style={{flex: 1}}>Sender</div>
                    </div>
                    {data.map((item, idx) =>
                        <div key={idx} className="table_row" style={{backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}} >
                            <div className="table_item" style={{flex:1}}>{item?.title} </div>
                            <div className="table_item" style={{flex:3}}>
                                <p className='w-full' style={{wordWrap:'break-word',whiteSpace:'pre-wrap'}}>{item?.message}</p>
                            </div>
                            <div className="table_item" style={{flex:1}}>{item?.deviceId} </div>
                            <div className="table_item" style={{flex:1}}>{item?.name||''} </div>
                            <div className="table_item" style={{flex:1}}>{new Date(item?.createDate).toLocaleString()} </div>
                            <div className="table_item" style={{flex:1}}>{item?.createdByUser?.[0]?.username} </div>
                        </div>
                    )}

                    <PageSelector
                        handleSelectPage={handleSelectPage}
                        page={page}
                        total={total}
                        totalPage={totalPage}
                    />
                </div>
                {/*end of message to smart watch*/}
                

                {/*send message*/}
                <div className="flex flex-col flex-1 min-h-[343px] gap-4 mb-[64px] text-[14px] mt-[24px] relative text-[14px] py-[24px] px-[16px]" style={{border: '.5px solid #FFFFFF80'}}>
                   <div className="flex flex-row items-center justify-between" style={{}}>
                        <div className="flex flex-row items-center">
                            <div className="text-white text-[18px] font-bold flex items-center">
                                <TitleLeftSvg className='mr-[9px]'/>
                                Send Message
                            </div>
                            {/* <ChartChainLink className="mx-[10px] cursor-pointer"/> */}
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-[32px]">
                        {/*Message*/}
                        <div className="flex flex-col flex-1">
                            <div>Title</div>
                            <input value={title} onChange={handleTitleInput} className="mt-2 p-[16px]" style={{border:'.5px solid #ffffff80',backgroundColor:"transparent"}} placeholder="Please enter title"/>
                            <div>Message</div>
                            <textarea value={message} onChange={handleMessageInput} className="mt-2 min-h-[200px] p-[16px]" style={{border:'.5px solid #ffffff80',backgroundColor:"transparent"}} placeholder="Please enter message"/>
                        </div>

                        {/*Select smart watch*/}
                        <div className="flex flex-col flex-1 gap-[16px]">
                            <div className="flex flex-row w-full justify-between pb-[8px]" style={{borderBottom:'.5px solid #ffffff80'}}>
                                Select Smart Watch
                                <div className="flex flex-row items-center">
                                    <ToggleButton value={isAll} onClick={toggleAll}/>
                                    <div className="ml-2">Select All ({watches?.length||'0'})</div>
                                </div>
                            </div>
                            {watches?.map((item,index)=>
                                <div className="flex flex-row items-center" key={item?._id}>
                                    <ToggleButton value={isAll||item?.isChecked} onClick={selectWatch(item,index)}/>
                                    <div className="ml-2">{item?.data?.name||'-'} ({item?.id})</div>
                                </div>
                            )}

                            <div onClick={sendMessages} className="mt-5 flex flex-row w-full items-center justify-center select-none cursor-pointer py-[8px] text-[#63BDFF] rounded-full bg-[#00202A] hover:bg-[#10303A] hover:font-bold gap-[8px]">
                                <SendMessageSvg/>Send Message
                            </div>
                        </div>
                    </div>
                </div>
                {/*end of send message*/}


            </div>
            {isLoading&&<Loading/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartWatch)