import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LEFTRIGHT_PADDING } from '../../config/style.config';
//svg
import TitleLeftSvg from '../../svgs/TitleLeftSvg'
import BluePlus from '../../svgs/permit/BluePlus'
import SmallHeader from '../../svgs/tracking/SmallHeader'
//
import {fetchPermitHistory} from '../../services/permit.service'
//comp
import PermitHistory from './PermitHistory'
function PermitActivityLog({hasPermission,token, _id ,handlePostHistory}){
    const [remark, setRemark] = useState('')
    const [history, setHistory] = useState()
    const navigate = useNavigate()
    useEffect(() =>{
        handleFetchHistory()
    },[])
    async function handleFetchHistory(){
        const res = await fetchPermitHistory(token.token, _id)
        if(res.errno) return console.log(res)
        // console.log(res)
        setHistory(res.result)
    }
    function handleSaveRemark(){
        const obj = {}
        obj.remark = remark
        handlePostHistory(obj)
        setRemark('')
        navigate('/permit')
    }
    return(<div className='flex-1 max-w-[1265px] mr-[40px]'>
        <div className='flex flex-col mb-[20px] mt-[40px]'>
            <div className='flex flex-row text-[18px] font-bold'><SmallHeader className="mr-[4px]"/>Remark</div>
        </div>
        <div className='relative h-[300px]'>
            {hasPermission && <div onClick={handleSaveRemark} className='absolute right-0 bottom-0 m-2 py-[2px] px-[16px] bg-[#00202A] rounded-full text-[#63BDFF] cursor-pointer select-none'>save</div>}
            <textarea disabled={!hasPermission} value={remark} onChange={(e)=>{setRemark(e.target.value)}} className='w-full h-[300px] border border-[#FFFFFF80] bg-transparent resize-none'></textarea>
        </div>
        <div className='flex flex-col mb-[20px] mt-[40px]'>
            <div className='flex flex-row text-[18px] font-bold'><SmallHeader className="mr-[4px]"/>History</div>
            <PermitHistory isReversed={true} historyArr={history}/>
        </div>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
})(PermitActivityLog)