import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||14}
    height={props.width?props.width/14*15:15}
    viewBox="0 0 14 15"
    fill="none"
    style={{...props.style,display:'flex', justifyContent:'center', alignItems:'center'}}
  >
    <g clipPath="url(#TickSvg)">
      <path
        fill="#fff"
        d="M5.25 9.95 2.8 7.5l-.817.817 3.267 3.266 7-7-.817-.816L5.25 9.95Z"
      />
    </g>
    <defs>
      <clipPath id="TickSvg">
        <path fill="#fff" d="M0 .5h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent