import React, { useEffect, useState, useRef } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import PageSelector from '../components/PageSelector';
import NoPermission from './NoPermission';
import DropdownMenu from '../components/DropdownMenu';
import AICameraChart from "../components/charts/AICameraChart";
import DropDown from '../components/DropdownMenu'
import AlertSnapshot from '../components/camera/AlertSnapshot'
import AlertSnapshotPopup from '../components/camera/AlertSnapshotPopup';
import AlertVideoPopup from '../components/camera/AlertVideoPopup';
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import SmallPointDownSvg from '../svgs/watchPage/SmallPointDownSvg'
import CogSvg from '../svgs/watchPage/CogSvg'
import BinSvg from '../svgs/camera/BinSvg'
import PlayVideoSvg from '../svgs/camera/PlayVideo'
import GridViewSvg from '../svgs/camera/GridView'
import ListViewSvg from '../svgs/camera/ListView'
import AlertVideoSvg from '../svgs/camera/AlertVideoSvg'
import AlertPhotoSvg from '../svgs/camera/AlertPhotoSvg'
import EditIconSvg from "../svgs/camera/EditIcon";
import { fetchCameraEvent } from "../services/cameras.service";
import { Login } from "../actions/actions";
//
import { deleteWatchAlert } from "../services/watches.service";
import { yearMonthDay,handleFilterDate } from "../actions/actionsDateTime";
import { REFRESH_INTERVAL, server_ip } from "../config";
const ALERT_TYPE_DICT={
    'CAMERA_DNG_ZONE':'Danger Zone',
    'CAMERA_NO_JACKET':'No Jactket',
    'CAMERA_NO_HELMET':'No Helmet',
    'CAMERA_FALLDOWN':'Falldown'
}
const typeToVal = {
        "Danger Zone": 'CAMERA_DNG_ZONE',
        "No Jacket": 'CAMERA_NO_JACKET',
        "No Helmet": 'CAMERA_NO_HELMET',
        "FallDown": 'CAMERA_FALLDOWN',
    }
function Screen(props){
    const [hoverZoom, setHoverZoom] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [targetDelete, setTargetDelete] = useState(null)
    const navigate = useNavigate();
    const [isGridView,setisGridView]=useState(true)
    const [popType, setPopType] = useState(undefined)
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const filterType = searchParams.get('type')
    const fromRef = useRef(null)
    const toRef = useRef(null)
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')
    }
    function handleTypeChange(val){
        if(val === 'All'){
            searchParams.delete('type')
            return setSearchParams(searchParams)
        }
        return handleParamChange('type', val)
    }

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging

    const [data,setData]=useState([])
    const sortBy={time:-1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId }
        if(filterType)filter["type"] = typeToVal[filterType]
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)

        let result = await fetchCameraEvent(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
        }
    }
    //snapshot popup
    const [isSnapshot,setisSnapshot]=useState(null)
    const handleShowPopup=(item,index)=>()=>{
        setisSnapshot(item)
    }
    const handleClosePopup=()=>{setisSnapshot(null)}
    //end of snapshot popup

    //video popup
    const [isAlertVideo,setisAlertVideo]=useState(null)
    const handleShowVideoPopup=(item,index)=>()=>{
        setisAlertVideo(item)
    }
    const handleCloseVideoPopup=()=>{setisAlertVideo(null)}
    //end of video popup


    useEffect(() => {
        const timer = setTimeout(fetchData, 500)
        const interval = setInterval(fetchData,REFRESH_INTERVAL)
        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    },[searchParams])

    useEffect(()=>{
        setSearchParams({page:1})
    },[props.control.projectId])

    async function handleDelete(){
        const res = await deleteWatchAlert(props.token.token, targetDelete._id)
        if(res.errno) {
            alert(res?.message)
            return
        }
        fetchData()
        setTargetDelete(null)
    }

    if(!props.control?.permissions?.CAMERA_VIEW)
        return(<NoPermission/>)

    return(
        <div className={"flex-col bg-[#0C0D14] flex-1 text-[14px] text-white"+ (hoverZoom ? ' overflow-hidden' : ' overflow-auto')}>
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
            {targetDelete && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex: 999}}>
                    <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                        <div className='flex flex-row items-center space-between w-full'>
                            <div className='font-bold'>Confirm Delete Alert?</div>          
                        </div>
                        <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => setTargetDelete(null)}>X</div>         

                        <div className='flex flex-row items-center space-between w-full mt-10'>
                            <div className='text-sm flex-1'>Name:</div>        
                            <div className='text-sm flex-2'>{targetDelete.name}</div>          
                        </div>
                        <div className='flex flex-row items-center space-between w-full mt-5'>
                            <div className='text-sm flex-1'>Alert Time:</div>       
                            <div className='text-sm flex-2'>{targetDelete?.time?new Date(targetDelete.time).toLocaleString():'-'}</div>          
                        </div>
                        {/* <div className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1'  style={{border:'.5px solid #ffffff80'}}>
                            {targetDelete.name}
                        </div> */}
                        <div onClick={handleDelete} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                            Delete
                        </div>
                    </div>
            </div>}
            <div className='mb-[410px]'style={{...LEFTRIGHT_PADDING}}>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Live view</div>
                </div>
                <div className="flex flex-row items-center justify-between mt-[27px]">
                    <div className="flex flex-row items-center">
                        <div className="subpage_not_selected" onClick={() => navigate('/camera')}>Camera</div>
                        <div className="subpage_selected">Alert</div>
                    </div>
                    <div className="flex flex-row items-center gap-4 flex-1 justify-end text-white"> 
                        <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                    </div>
                </div>
                

                {/*chart*/}
                <div className="w-full flex flex-col p-[16px] mt-[64px]" style={{border:'.5px solid #ffffff80'}}>
                    <div className="flex flex-row">
                        <TitleLeftSvg className='mr-[9px]'/>
                        <div className="text-[14px] text-white font-bold ">AI Camera Alert</div>
                    </div>
                    <div className="px-[0px] pt-[13px] w-full" onMouseEnter={() => setHoverZoom(true)} onMouseLeave={() => setHoverZoom(false)}>
                        <AICameraChart />
                    </div>
                </div>
                {/*chart*/}


                <div className="flex flex-row items-center justify-between mt-[27px]">
                    <div className="flex flex-row items-center">
                    </div>
                    <div className="flex flex-row items-center gap-2 flex-1 justify-end"> 
                        <div onClick={()=>{setisGridView(true)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:isGridView?'#63BDFF':'white'}}>
                            <GridViewSvg color={isGridView?'#63BDFF':'white'}/>
                            Grid View
                        </div>
                        |
                        <div onClick={()=>{setisGridView(false)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:!isGridView?'#63BDFF':'white'}}>
                            <ListViewSvg color={!isGridView?'#63BDFF':'white'}/>
                            List View
                        </div>
                        {/* <div className="rounded-full flex flex-row items-center justify-between flex-1 max-w-[200px] px-[16px] py-[2px] select-none cursor-pointer ml-2" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                            <input className="bg-transparent h-[21px] w-[130px]  mr-[11px] text-white" placeholder="Type"></input>
                            <SmallPointDownSvg />
                        </div> */}
                        <DropDown select={handleTypeChange} value={filterType || 'Type'} options={['All', 'Danger Zone', 'No Jacket', "No Helmet", "FallDown"]}/>
                    </div>
                </div>
               


                <div className="w-full flex flex-col">

                    {/*main list/grid*/}
                    {isGridView&&
                        <div className="mt-[25px] flex-wrap	flex flex-row gap-[16px] items-center justify-stretch mb-[64px]">
                            {data.map((item,index) => 
                                <div key={item?._id||index} className="inline-block py-[16px] px-[24px]" style={{border: '0.5px solid #FFFFFF80',width:'calc(50% - 8px)'}}>
                                    <div className="flex flex-row items-center justify-between">
                                        <div className="flex flex-row items-center text-white">
                                            <TitleLeftSvg className='mr-[9px]'/>
                                            {item?.name} ({item?.deviceID})
                                        </div>
                                        <div className="flex flex-row items-center gap-2">
                                            <div className="flex flex-row items-center rounded-full px-[16px] gap-[4px] py-[2px] bg-[#FFFFFF12]">
                                                Alert Type: 
                                                <div className="text-[#FFE454]">{ALERT_TYPE_DICT[item?.type]}</div>
                                            </div>
                                            {props.control?.permissions?.SUMMARY_HIDE_ALERT && <div className="flex flex-row items-center">
                                                <BinSvg className='cursor-pointer' onClick={() => setTargetDelete({...item,index})} />
                                            </div>}
                                            {/* <div className="button_blue">
                                                <EditIconSvg width={12}/>
                                                Edit
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center gap-2 text-white mt-[4px] text-[10px] text-[#ddd] font-[300]">
                                        {/* <CalenderSvg /> */}
                                        {new Date(item?.time).toLocaleString()}
                                    </div>
                                    <div className="flex flex-row items-center gap-[16px] mt-[32px] w-full">
                                        <div className="flex-1 p-[8px]" style={{border:'.5px solid #ffffff80'}}>
                                            <AlertSnapshot deviceId={item?.deviceID} time={item?.time}/>
                                        </div>
                                        <div className="flex-1 p-[8px] relative flex items-center justify-center" style={{border:'.5px solid #ffffff80'}}>
                                            <div className='w-full text-white flex items-center justify-center' style={{height:undefined,aspectRatio:1920/1080}}>
                                                {(item.video)?
                                                    <video src={`${server_ip}/api/documents/${item.video}`} className="w-full h-full" controls={true} autoPlay={false}/>
                                                    :<div className="">
                                                        No Video
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {/*main list/grid*/}
                    {!isGridView&&
                        <div className="mt-[25px] flex-wrap	flex flex-row gap-[16px] items-center justify-stretch w-full mb-10" style={{borderTop:'.5px solid #ffffff40',borderBottom:'.5px solid #ffffff40'}}>
                            <div className="py-[32px] w-full">
                                <div className="flex flex-row text-[10px] font-bold w-full items-center">
                                    <div className="flex-[1] table_header">Item</div>
                                    <div className="flex-[2] table_header">Camera Id</div>
                                    <div className="flex-[2] table_header">Camera Name</div>
                                    <div className="flex-[2] table_header">Date & Time</div>
                                    <div className="flex-[2] table_header">Alert Type</div>
                                    <div className="flex-[2] table_header">Snapshot</div>
                                    <div className="flex-[2] table_header">Alert Video</div>
                                    <div className="w-[100px] table_header"/>
                                </div>
                                {data.map((item, idx) =>
                                    <div key={item?._id||idx} className="text-[14px] table_row" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}}>
                                        <div className="flex-[1] table_item">{limit*(page-1)+idx+1}</div>
                                        <div className="flex-[2] table_item">{item?.deviceID}</div>
                                        <div className="flex-[2] table_item">{item?.name}</div>
                                        <div className="flex-[2] table_item">{new Date(item?.time).toLocaleString()}</div>
                                        <div className="flex-[2] table_item">{ALERT_TYPE_DICT[item?.type]||item?.type}</div>
                                        <div className="flex-[2] table_item flex flex-row items-center gap-[4px] cursor-pointer"  onClick={handleShowPopup(item,idx)}>Snapshot <AlertPhotoSvg/></div>
                                        <div className="flex-[2] table_item flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowVideoPopup(item,idx)}>Alert Video <AlertVideoSvg/></div>
                                        {props.control?.permissions?.SUMMARY_HIDE_ALERT && <div className="w-[100px] flex flex-row items-center table_item">
                                            <BinSvg className='cursor-pointer' onClick={() => setTargetDelete({...item,idx})} />
                                        </div>}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {/*end of main list/grid*/}
                                        
                </div>
                <PageSelector
                    handleSelectPage={handleSelectPage}
                    page={page}
                    total={total}
                    totalPage={totalPage}
                />


            </div>
            {/*snapshot popup*/}
            {isSnapshot&&<AlertSnapshotPopup close={handleClosePopup} time={isSnapshot?.time} deviceId={isSnapshot?.deviceID}/>}
            {isAlertVideo&&<AlertVideoPopup close={handleCloseVideoPopup} data={isAlertVideo}/>}
            {/*end of snapshot popup*/}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)