import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <rect width={30} height={30} fill="#00202A" rx={7} />
    <g fill="#63BDFF" clipPath="url(#BlueThreeDot)">
      <circle cx={10} cy={16} r={2} />
      <circle cx={16} cy={16} r={2} />
      <circle cx={22} cy={16} r={2} />
    </g>
    <defs>
      <clipPath id="BlueThreeDot">
        <path fill="#fff" d="M4 4h22v22H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
