export const firstData = {
    label: '',
    data: [],
    borderColor: 'rgba(41, 0, 217, 1)',
    fill: true,
    tension: 0.4
}
//!!! bg HAS to be in the data array index 1 !!!
export function bgLineData(obj){
    const newLine = obj[0].data.map(((point, pIdx) => {
        let localMax = 0
        obj?.forEach(inObj => {
            localMax = Math.max(localMax, inObj.data[pIdx])
        })
        return localMax === 0 ? localMax + 0.5 : localMax + Math.max(1, Math.floor((localMax / 3)))
    }))
    return {
        label: '',
        data: newLine,
        pointHitRadius: 0,
        borderColor: 'rgba(41, 255, 217, 0)',
        pointRadius: 0,
        pointHoverBackgroundColor:'rgba(41, 255, 217, 0)',
        pointBorderColor:'rgba(41, 255, 217, 0)',
        fill: true,
        tension: 0.4,
        backgroundColor: (context) => {
            const bgColor = ['rgba(255, 255, 255,.5)', 'rgb(255, 255, 255,0)']
            if(!context.chart.chartArea) return null
            const {ctx, data, chartArea:{top, bottom}} = context.chart
            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom)
            gradientBg.addColorStop(0, bgColor[0]) 
            gradientBg.addColorStop(1, bgColor[1]) 
            return gradientBg
        }
    }
    
} 