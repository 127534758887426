import React from "react";
import { getDateOnly, getTimeOnly } from "../../actions/actionsDateTime";
function createHistory(obj, idx, arrLen, modUser){
    const detailArr = []
    const {modifyDate, data, type} = obj
        // console.log(data, type)
        if(data === undefined){
        if(type === 'CREATE') detailArr.push((<div key={'CREATE' + idx} className=''>/ Create details with ID: <span className="font-bold">{obj._id}</span></div>))
        if(type === 'DELETE_DOCUMENT') detailArr.push((<div key={'DELETE_DOC' + idx} className=''>/ Deleted document <span className="font-bold">{obj.trackingRecordId}</span></div>))
    }
    if(data){
        if(type === 'UPLOADDOCUMENT') detailArr.push((<div key={'EDIT' + idx} className=''>/ Added document <span className="font-bold">{obj.data.originalname}</span></div>))
        if(type === 'DELETE_DOCUMENT') detailArr.push((<div key={'EDIT' + idx} className=''>/ Deleted document <span className="font-bold">{obj.data.originalname}</span></div>))
        if(type === 'EDIT') comparingBeforeAfter(data)
    }
    function comparingBeforeAfter({before, after}){
        for(const [key, val] of Object.entries(after)){
            if(before[key] !== val){
                if(['certificateStartDate','certificateEndDate','examination','maintenanceDate'].includes(key)){
                    detailArr.push((<div key={key + val + 'detail'} className=''>/ Changed <span className="font-bold">{key}</span> from <span className="font-bold">{getDateOnly(before[key])}</span> to <span className="font-bold">{getDateOnly(val)}</span></div>))
                }else{
                    detailArr.push((<div key={key + val + 'detail'} className=''>/ Changed <span className="font-bold">{key}</span> from <span className="font-bold">{before[key]}</span> to <span className="font-bold">{val}</span></div>))
                }
            }
        }
    }
    //detailArr.push((<div key={'NO' + idx} className=''><span className="font-bold">/ No Records</span></div>)
    if(detailArr.length === 0) detailArr.push((<div key={'NO' + idx} className=''><span className="font-bold">/ No Changes or History</span></div>))
    return (
        <div key={obj._id + idx + 'main'} className='flex flex-row overflow-y-hidden'>
            <div className='flex flex-row flex-1'>
                <div className='flex flex-col items-center relative mt-[16px]'>
                    <div className='text-[20px] font-bold text-white py-[5px] px-[14px] border rounded-full border-[#FFFFFF80]'>{idx + 1}</div>
                    {arrLen - 1 !== idx && <div className='mt-2 w-[1px] flex-1 border border-[#FFFFFF40]'></div>}
                </div>
                <div className='bg-[#FFFFFF12] p-[24px] flex flex-row ml-[32px] flex-1 mb-[32px]'>
                    <div>
                        <div className='flex flex-row items-center py-[6px] px-[16px] gap-2 rounded-full' style={{borderWidth:1, borderColor:'#FFFFFF80'}}>
                            <div className='text-white text-nowrap'>{getDateOnly(modifyDate)}</div>
                            <div className='text-white'>{getTimeOnly(modifyDate)}</div>
                        </div>
                        <div className='text-[#A1A1A1] mt-[8px]'>Updated by {(modUser || 'Unknown')}</div>
                    </div>
                    <div className='text-white ml-[72px] flex-1'>
                        {detailArr}
                    </div>
                </div>
            </div>
        </div>
    )
}

function DetailHistory({history, reverse, userDict}){
    const detailJSX = generateHistory()
    function generateHistory(){
        if(!history) return
        const newArr = []
        const historyArr = reverse ? [...history].reverse() : [...history]
        for(let i = 0 ; i < historyArr.length; i++){
            // console.log(userDict, historyArr[i])
            const hasInfo = createHistory(historyArr[i], i, historyArr.length, userDict[historyArr[i].createdBy])
            newArr.push(hasInfo)
        }
        return newArr
    }
    return(
        <div className='mt-[24px]'>
            {detailJSX}
        </div>
    )
}
export default DetailHistory 