import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||24}
    height={props.width||24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m12.003 5.2 8.102 14.448H3.9L12.003 5.2Zm0-3.42L.926 21.363H23.08L12.003 1.78Zm.857 14.506h-1.714V18h1.714v-1.714Zm0-6h-1.714v5.143h1.714v-5.143Z"
    />
  </svg>
)
export default SvgComponent
