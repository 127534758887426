import { useEffect, useLayoutEffect, useState } from "react"
import { isFLN } from "../../config"

const h = 1080
const w = 1920

function LiveView(props){

    const [isPlaying,setisPlaying]=useState(null)

    function start(){
        
            start_play()
       
    }

    useLayoutEffect(()=>{
        start()
    },[])

    function start_play(){

        let player = new window.ZLMRTCClient.Endpoint(
            {
                element: document.getElementById('video'),// video 标签
                debug: true,// 是否打印日志
                zlmsdpUrl:props.url,//流地址
                simulcast:false,
                useCamera:true,
                audioEnable:true,
                videoEnable:true,
                recvOnly:true,
                resolution:{w:w,h:h}
            }
        );

        player.on(window.ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,function(e)
        {// ICE 协商出错
            console.log('ICE 协商出错')
        });

        player.on(window.ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,function(e)
        {//获取到了远端流，可以播放
            console.log('播放成功',e.streams)
        });

        player.on(window.ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,function(e)
        {// offer anwser 交换失败
            console.log('offer anwser 交换失败',e)
            stop(player);
        });


        player.on(window.ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,function(s)
        {// 获取本地流失败

            console.log('获取本地流失败')
        });

        player.on(window.ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,function(state)
        {// RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
            console.log('当前状态==>',state)
        });
    }

    function stop(player){
        player.close();
        player = null;
    }



    return (
        <div className="w-full h-full p-0" style={{aspectRatio:w/h}}>
            <video id='video' controls autoplay className="w-full h-full">
                Your browser is too old which doesn't support HTML5 video.
            </video>
        </div>
    )
}

export default function Component(props){
    if(props.url)
    return(
        <iframe 
            key={props.url}
            className='w-full f-full' 
            style={{aspectRatio:w/h,padding:0,overflow:'hidden'}}
            src={isFLN?`/hls.html?data=${props.url}`:`/camera.html?data=${props.url}`}
        />
    )
    else return(<div/>)
}