import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M9 12.5 5.25 8.75 6.3 7.662l1.95 1.95V3.5h1.5v6.113l1.95-1.95 1.05 1.087L9 12.5Zm-4.5 3c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 14v-2.25h1.5V14h9v-2.25H15V14c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44h-9Z"
    />
  </svg>
)
export default SvgComponent
