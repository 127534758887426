import { server_ip } from "../config";

async function fetchProjectsList(token, filter={}, options={}) {
    try {
        const response = await fetch(`${server_ip}/api/projects/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                filter,
                options
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
export{
    fetchProjectsList,
}