import React from 'react';
export default function PageNotFound() {
  return (
    <div className='bg-black w-full text-white h-full'>
      <div className=''>
        Page Not Found
      </div>
    </div>
  );
}

