import React from 'react';
import { connect } from 'react-redux';
import { yearMonthDay } from '../../../../actions/actionsDateTime';
//svgs
import GreyCalendar from '../../../../svgs/permit/GreyCalendar'
function FormTextInput({isPreview, sectionIdx, fieldIdx, fieldObj, handleUpdateData}) {
    const {fieldData:{title, data}, fieldId, fullWidth, required} = fieldObj
    function handleDateChange(val){
        handleUpdateData(val)
    }
    return (
    <div key={fieldId} className={'flex flex-col px-[12px] mt-[24px]' + (' w-[100%]')}>
        <div className='w-[50%]'>
            <label className='mb-[8px] text-[16px]'>{title}{required ? '*': ''}</label>
            <div className='border border-[#FFFFFF80] rounded-full h-[33px] flex flex-row items-center px-[12px]'>
                {/* <GreyCalendar className='mr-[6px]'/> */}
                <input defaultValue={yearMonthDay(data)} onChange={(e) => handleDateChange(new Date(e.target.value).toString())} type='date' disabled={isPreview} className='bg-transparent h-[33px] w-full outline-none' style={{colorScheme: 'dark'}}></input>
            </div>
        </div>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormTextInput)