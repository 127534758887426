import { server_ip } from "../config";

//POST
export async function fetchTrackingHistory(filter, options) {
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter,
                options
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
export async function postNewTrackingRecord(token, data) {
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                data
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
export async function postDocumentUploadWithId(token, id, data) {
    try {
        console.log(data)
        if (!data) return { errno: 0, message: 'no data' };
        let formData = new FormData();
        formData.append('file', data, data.name);
        // for (let pair of formData.entries()) {
        //     const [key, value] = pair;
        //     console.log(`Key: ${key}, Value: ${value}`);
        // }
        const response = await fetch(`${server_ip}/api/documents/upload`, {
            method: 'POST',
            headers: {
                Accept: '/',
                'Authorization': 'Bearer ' + token,
                'istrackingdocument': 'true',
                'trackingrecordid': id
            },
            body: formData,
        })
        return await response.json();
    } catch (error) {
        console.log(error)
        return { errno: 1, message: 'Unexpected error' }
    }
}
export async function fetchTrackingCategory(token,key,filter){
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords/distinct`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                key,filter
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
//GET
export async function fetchTrackingDataById(id) {
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'istrackingdocument': 'true',
                'trackingrecordid': id
            }
        })
        return await response.json();
    } catch (error) {
        console.log(error)
        return { errno: 1, message: 'Unexpected error' }
    }
}
export async function fetchDocumentByName(name) {
    try {
        const response = await fetch(`${server_ip}/api/documents/${name}`, {
            method: 'GET',
            headers: {
                Accept: '/',
                // 'istrackingdocument': 'true',
                // 'trackingrecordid': id
            }
        })
        return await response;
    } catch (error) {
        console.log(error)
        return { errno: 1, message: 'Unexpected error' }
    }
}

//PUT
export async function putUpdateTrackingRecord(token, id, data) {
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords/` + id, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                data
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}

//DELETE
export async function deleteFileByName(token, fileName, trackId) {
    try {
        const response = await fetch(`${server_ip}/api/documents/filename/${fileName}`, {
            method: 'DELETE',
            headers: {
                Accept: '/',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error.message)
        return { errno: 1, message: 'Unexpected error' }
    }
}
export async function deleteTrackingRecordsById(token, _id) {
    try {
        const response = await fetch(`${server_ip}/api/trackingRecords/${_id}`, {
            method: 'DELETE',
            headers: {
                Accept: '/',
                'Authorization': 'Bearer ' + token,
            },
        })
        return await response.json();
    } catch (error) {
        console.log(error.message)
        return { errno: 1, message: 'Unexpected error' }
    }
}