export const DEVICE_TYPE={
    WATCH:"watch",
    LOCK:'lock',
    CAMERA: 'camera',
    CONFINED_SPACE: 'confinedSpace',
    DANGER_ZONE: 'dangerZone'
}

export const DEVLICE_LABEL={
    [DEVICE_TYPE?.WATCH]:'Smart Watch',
    [DEVICE_TYPE?.LOCK]:'Smart Lock',
    [DEVICE_TYPE?.CAMERA]:'AI Camera',
    [DEVICE_TYPE?.DANGER_ZONE]:'Danger Zone',
    [DEVICE_TYPE?.CONFINED_SPACE]:'Confined Space',
}