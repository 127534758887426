import { server_ip } from "../config";

async function postTemplateData(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/permitTemplates/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function putTemplateData(token,data,_id) {
    try {
        const response = await fetch(`${server_ip}/api/permitTemplates/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteTemplate(token,_id) {
    try {
        const response = await fetch(`${server_ip}/api/permitTemplates/${_id}`, {
            method: 'DELETE',
            headers: {
                Accept: '/',
                Authorization: `Bearer ${token}`,
            },
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function postTemplateDocument(token,file,id,type) {
    console.log(file, id)
    let formData = new FormData();
    formData.append('file', file[1]);
    try {
        const response = await fetch(`${server_ip}/api/documents/upload`, {
            method: 'POST',
            headers: {
                'ispermitdocument': 'true',
                'permitdocumentid': id,
                filetype:type,
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchTemplateList(token, filter={}, options={}) {
    try {
        const response = await fetch(`${server_ip}/api/permitTemplates/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                filter,
                options
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: 'Unexpected error' }
    }
}
async function deletePermit(token,_id) {
    try {
        const response = await fetch(`${server_ip}/api/permits/${_id}`, {
            method: 'DELETE',
            headers: {
                Accept: '/',
                Authorization: `Bearer ${token}`,
            },
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function postCreatePermit(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/permits/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function putEditPermit(token,data,_id) {
    try {
        const response = await fetch(`${server_ip}/api/permits/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchPermit(token, filter={}, options={}) {
    try {
        const response = await fetch(`${server_ip}/api/permits/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                filter,
                options
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchPermitById(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/permits/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function postPermitHistory(token, data){
    try {
        const response = await fetch(`${server_ip}/api/permitHistory/`,{
            method:'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,    
            },
            body: JSON.stringify({
                data
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchPermitHistory(token,_id){
    try {
        const response = await fetch(`${server_ip}/api/permitHistory/fetch  `,{
            method:'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',  
                Authorization: `Bearer ${token}`,    
 
            },
            body: JSON.stringify({
                "filter":{"permit_id":_id},
                "options":{}
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchPieChartStats(token, filter){
    try {
        const response = await fetch(`${server_ip}/api/permits/statistic`,{
            method:'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',  
                Authorization: `Bearer ${token}`,    
 
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{}
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchUsersRole(token, projectId){
    try {
        const response = await fetch(`${server_ip}/api/users/role/permit`,{
            method:'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',  
                Authorization: `Bearer ${token}`,    
 
            },
            body: JSON.stringify({
                "projectId": projectId
            })
        })
        return await response.json();
    } catch (error) {
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    postTemplateData,
    postTemplateDocument,
    fetchTemplateList,
    postCreatePermit,
    fetchPermit,
    putTemplateData,
    postPermitHistory,
    fetchPermitHistory,
    putEditPermit,
    deleteTemplate,
    deletePermit,
    fetchPermitById,
    fetchPieChartStats,
    fetchUsersRole
}