import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import EventTypeComp from '../components/tracking/EventTypeComp'
import {fetchRolesList, fetchUsersList} from '../services/users.service';
import PageSelector from '../components/PageSelector';
import CreateUserPopup from "../components/users/CreateUserPopup";
import EditUserPopup from "../components/users/EditUserPopup";
import PasswordResetPopup from "../components/users/PasswordResetPopup";
import DeleteUserPopup from "../components/users/DeleteUserPopup";
import NoPermission from './NoPermission'
import DropdownMenu from '../components/DropdownMenu';
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg';
import { Login } from "../actions/actions";
import EditIconSvg from "../svgs/camera/EditIcon";
import BinSvg from '../svgs/camera/BinSvg'
import PasswordSvg from '../svgs/Password';

const CHART_TITLE = [
    {title: 'ID', field: '_id', flex: 1},
    {title: 'Name', field: 'name', flex: 2},
    {title: 'Email', field: 'username', flex: 2},
    {title: 'Phone', field: 'phone', flex: 2},
    {title: 'Status', field: 'status', flex: 2},
    {title: 'Role', field: 'role', flex: 2},
    {title: 'Register Date', field: 'datetime', flex: 2},
    {title: 'Action', field: 'action', flex: 2},]

function SmartWatch(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        setSearchParams({page:p,role:roleFilter})
    }
    //end of paging

    //filter
    const roleFilter =  searchParams.get('role')||'All';
    const selectRoleFilter=(item,index)=>{ setSearchParams({page:1,role:item}) }
    //end of filter

    //search
    const searchTxt =  searchParams.get('search')||'';
    const handleSearch=(e)=>{setSearchParams({page:1,search:e.target.value,roleFilter,search:e.target.value})}
    //send of search

    const [data,setData]=useState([])
    const sortBy={username:1};

    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ type:{$in:['user']}, projectId:props.control?.projectId }
        if(searchTxt)filter.username={"$regex":searchTxt, "$options":'i'}
        if(roleFilter!=='All')filter.role=roleFilter
        let result = await fetchUsersList(props.token.token,{ skip: l*(p-1), limit:l , sort:st}, filter)
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
            setactiveTotal(result?.activeCount||0)
        }
    }


    const [roles,setroles]=useState([])
    const fetchRoles = async()=>{
        let result = await fetchRolesList(props.token?.token,{projectId:props.control?.projectId},{sort:{name:1}})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setroles(result?.result);
        }
    }
    useEffect(()=>{fetchRoles()},[props.control?.projectId])


    //create new user
    const [isCreating,setisCreating]=useState(false)
    const startCreate=()=>{setisCreating(true)}
    const cancelCreate=()=>{setisCreating(false)}
    const refresh=()=>{fetchData()}
    //end of create new user

    //edit user
    const [isEditing,setisEditing]=useState(false)
    const startEdit=(item,index)=>()=>{setisEditing(item)}
    const cancelEdit=()=>{setisEditing(false)}
    //end of edit user

    //reset user password
    const [isResetPw,setisResetPw]=useState(false)
    const startResetPW=(item,index)=>()=>{setisResetPw(item)}
    const cancelResetPW=()=>{setisResetPw(false)}
    //end of reset user password

    //delete user
    const [isDeleting,setisDeleting]=useState(false)
    const startDelete=(item,index)=>()=>{setisDeleting(item)}
    const cancelDelete=()=>{setisDeleting(false)}
    //end of delete user

    useEffect(() => {
        const timer = setTimeout(() => {fetchData()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        setSearchParams({page:1,role:'',search:''})
    },[props.control.projectId])

    

    if(!props.control?.permissions?.USER_VIEW)
        return(<NoPermission/>)
    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-[14px] text-white'>
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">User Management</div>
                </div>

                <div className="flex w-full flex-col mt-10">
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px]" style={{borderBottom:'.5px solid #ffffff80'}}>
                        <div className="flex flex-row items-center gap-[8px]">
                            <DropdownMenu select={selectRoleFilter} options={['All',...roles.map(i=>i.name)]} value={roleFilter==='All'?'Role':roleFilter}/>
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{border:'.5px solid #FFFFFF80'}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input onChange={handleSearch} className="bg-transparent h-[21px] text-white min-w-0 " placeholder="Search Email"></input>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-[8px]">
                            {props.control?.permissions?.USER_CREATE&&<div className="button_blue" onClick={startCreate}>
                                + Add User
                            </div>}
                            {props.control?.userData?.type==='admin'&&<div className="button_blue" onClick={()=>{navigate('/user-management/permission')}}>
                                Manage Permission Role
                            </div>}
                            {/* {props.control?.permissions?.USER_ALERT&&<div className="button_blue">
                                Manage Alert
                            </div>} */}
                        </div>
                    </div>
                    <div className="py-[32px]">
                        <div className="flex flex-row">
                            {CHART_TITLE.map((obj, i) => (
                                <div key={'title'+ obj.title} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                    {obj.title} 
                                </div>
                            ))}
                        </div>
                        {data.map((item, idx) =>
                            <div key={'TrackP2' + idx} className="table_row" style={{backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}} >
                                {CHART_TITLE.map((h, i) => 
                                    <div className='table_item' key={i} style={{flex:h.flex||1}}>
                                        {   h.field==='_id'
                                            ?<div>{ ((page-1)*limit+ idx + 1 )}</div>
                                            :h.field==='name'
                                            ?<div>{item?.data?.firstname} {item?.data?.lastname}</div>
                                            :h.field==='datetime'
                                            ?<div>{new Date(item?.createDate).toLocaleString()}</div>
                                            :h.field==='status'
                                            ? <EventTypeComp type={'active'}/> 
                                            :h.field==='username'
                                            ? <div>{item?.username}</div>
                                            :h.field==='phone'
                                            ? <div>{item?.data?.phone||'-'}</div>
                                            :h.field==='role'
                                            ? <div>{item?.role}</div>
                                            : h.field==='action'
                                            ? <div className="flex flex-row items-center gap-[8px]">
                                                {props.control?.permissions?.USER_EDIT&&<div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]' onClick={startEdit(item,idx)} ><EditIconSvg/></div>}
                                                {props.control?.permissions?.USER_PASSWORD&&<div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]'  onClick={startResetPW(item,idx)} ><PasswordSvg color='#63BDFF'/></div>}
                                                {props.control?.permissions?.USER_DELETE&&<div className='cursor-pointer' onClick={startDelete(item,idx)}><BinSvg color='#ffffff40'/></div>}
                                            </div>
                                            :<div/>
                                        }
                                    </div>       
                                )}
                            </div>
                        )}
                    </div>
                    <PageSelector
                        handleSelectPage={handleSelectPage}
                        page={page}
                        total={total}
                        totalPage={totalPage}
                    />
                </div>
            </div>
            {isCreating&&<CreateUserPopup
                cancel={cancelCreate}
                refresh={refresh}
                roles={roles}
            />}
            {isEditing&&<EditUserPopup
                cancel={cancelEdit}
                refresh={refresh}
                roles={roles}
                data={isEditing}
            />}
            {isResetPw&&<PasswordResetPopup
                cancel={cancelResetPW}
                refresh={refresh}
                data={isResetPw}
            />}
            {isDeleting&&<DeleteUserPopup
                cancel={cancelDelete}
                refresh={refresh}
                data={isDeleting}
            />}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartWatch)


