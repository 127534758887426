import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m9 3 3.75 3.75-1.05 1.088-1.95-1.95V12h-1.5V5.888L6.3 7.838 5.25 6.75 9 3Z"
    />
    <path
      fill="#fff"
      d="M4.5 15c-.412 0-.765-.15-1.058-.443A1.445 1.445 0 0 1 3 13.5v-2.25h1.5v2.25h9v-2.25H15v2.25c0 .412-.15.765-.443 1.057A1.445 1.445 0 0 1 13.5 15h-9Z"
    />
  </svg>
)
export default SvgComponent
