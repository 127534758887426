import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import DropdownMenu from '../../components/DropdownMenu';
import { createAccount, editUserData } from "../../services/users.service";
import LoadingPage from "../Loading";
import {validateEmail}from '../../utils/util';


function Component(props){ 
    const [isLoading,setisLoading]=useState(false)
    const [newRecord,setNewRecord]=useState(JSON.parse(JSON.stringify(props.data)))
    const [createError,setcreateError]=useState('')
    const handleInput=(field)=>(e)=>{
        let r= {...newRecord}
        r.data[field]=e.target.value
        setNewRecord(r)
    }
    const toggleMask=()=>{ setNewRecord({...newRecord,isMask:!newRecord.isMask})}
    const handleCancel=()=>{props.cancel();}
    const selectRole=(item,index)=>{
        setNewRecord({...newRecord,role:item})
    }
    const handleConfirm=async()=>{
        if(isLoading)return
        setcreateError('')
        setisLoading(true)
        const data={
            phone:newRecord?.data?.phone||'',
            firstname:newRecord?.data?.firstname||'',
            lastname:newRecord?.data?.lastname||'',
        }
        let result = await editUserData(props.token?.token,newRecord._id,newRecord.role,data)
        if(result.errno){
            setcreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
           handleCancel()
           props.refresh();
        }
        setisLoading(false)
    }


    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                <div className='flex flex-row items-center space-between w-full'>
                    <div className='font-bold'>Edit User</div>          
                </div>
                <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancel}>X</div>         

                
                <div className="text-white mt-5 font-bold text-[14px]">Email</div>
                <div className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1'  style={{border:'.5px solid #ffffff80'}}>
                    {newRecord?.username}
                </div>

                <div className="text-white mt-5 font-bold text-[14px] mb-1">Role</div>
                <div className="w-full flex flex-col">
                    <DropdownMenu select={selectRole} value={(newRecord?.role)||"Select a role"} isFullWidth={true} options={props.roles?.map(i=>i.name)}/>
                </div>

                <div className="text-white mt-5 font-bold text-[14px]">Phone</div>
                <input value={(newRecord?.data?.phone)||""} onChange={handleInput('phone')} placeholder='Phone' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px]">First Name</div>
                <input value={(newRecord?.data?.firstname)||""} onChange={handleInput('firstname')} placeholder='First name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>

                <div className="text-white mt-5 font-bold text-[14px]">Last Name</div>
                <input value={(newRecord?.data?.lastname)||""} onChange={handleInput('lastname')} placeholder='Last name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1' style={{border:'.5px solid #ffffff80'}}/>


                <div className='w-full text-[red] text-xs'>{createError}</div>

                <div onClick={handleConfirm} className='button_blue w-full mt-5' style={{padding:'6px 0px'}}>
                    Confirm
                </div>
            </div>
            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)