import { Pie } from 'react-chartjs-2'
import React, {useState, useEffect} from 'react'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Chart as ChartJS , RadialLinearScale,ArcElement } from "chart.js";
import { DEVICE_COLOR_DICT } from '../../config/style.config';
import { DEVICE_TYPE } from '../../dto/devices';
import { fetchPieChartStats } from '../../services/permit.service';
import {handleFilterDate} from '../../actions/actionsDateTime'
ChartJS.register(RadialLinearScale,ArcElement);


const cache = new Map();
const colors = [
  DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
  DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH],
  DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK],
  '#654CFF',
  DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE],
  DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE],
  DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
];
const data = {
    labels: ['111','222','333','444','555','666','777','888'],
    datasets: [{
        data: [Math.floor(Math.random()*100),
            Math.floor(Math.random()*100),
            Math.floor(Math.random()*100),
            Math.floor(Math.random()*100),
            Math.floor(Math.random()*100),
            Math.floor(Math.random()*100)]
    }],
};
const options={
    plugins: {
      legend: false,
      tooltip: false,
    },
  
    elements: {
      arc: {
        backgroundColor: function(context) {
          let c = colors[context.dataIndex];
          if (!c) {
            return;
          }
          return createRadialGradient3(context, c);
        },
        borderColor: function(context) {
            return colors[context.dataIndex];
        }
        
      }
    },
    
  }


  function createRadialGradient3(context,c) {
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
  
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (width !== chartWidth || height !== chartHeight) {
      cache.clear();
    }
    let gradient = cache.get(c);
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      var width = chartWidth;
      var height = chartHeight;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const r = Math.min(
        (chartArea.right - chartArea.left) / 2,
        (chartArea.bottom - chartArea.top) / 2
      );
      const ctx = context.chart.ctx;
      const diff= c===colors[0]?.0:c===colors[2]?.13:c===colors[3]?.16:c===colors[4]?.19:.1
      gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
      gradient.addColorStop(0, `white`);
      gradient.addColorStop(.1, `white`);
      gradient.addColorStop(0.1, `${c}00`);
      gradient.addColorStop(0.3, `${c}10`);
      gradient.addColorStop(0.65-diff, `${c}70`);
      gradient.addColorStop(0.65-diff, `${c}00`);
      gradient.addColorStop(0.9, `${c}50`);
      gradient.addColorStop(1, `${c}`);
      cache.set(c, gradient);
    }
  
    return gradient;
  }




function Component(props){
    const [pieData, setPieData] = useState(undefined)
    const [devideBy, setDevideBy] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = searchParams.get('date')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
      fetchData()
    },[searchParams])

    async function fetchData(){
        const filter = {}
        filter["projectId"] = props.control.projectId
        // if(filterDate) filter['time'] = handleFilterDate(filterDate)
        // if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)
        if(filterFrom && filterTo) filter['createDate'] = {
          "$lte": `${new Date(filterTo).toISOString()}`,
          "$gte": `${new Date(filterFrom).toISOString()}`
        }
        const timeFrom = new Date()
        const timeTo = new Date()
        if(filterDate){
            timeFrom.setDate(timeFrom.getDate() - 1)
            if(filterDate === '7 Days') timeFrom.setDate(timeFrom.getDate() - 7)
            if(filterDate === '30 Days') timeFrom.setDate(timeFrom.getDate() - 30)
            filter['createDate'] = {
                "$lte": `${timeTo.toISOString()}`,
                "$gte": `${timeFrom.toISOString()}`
            }
        }
       const res = await fetchPieChartStats(props.token.token, filter)
       if(res.errno) return
      //  console.log(res)
       const a = res.result
       const dataArr = [a.Draft, a['Pending for approval'], a.Approved, a.Valid, a.Expired, a.Cancelled]
       if(dataArr.every(num => num === 0)) dataArr.push(1)
       const sum = dataArr.reduce((accum, curr) => {
        const temp = accum + curr
        return temp
       },0)
      setDevideBy(sum)
      setPieData(dataMaker(dataArr))
    }
    function dataMaker(dataArr){
      const data = {
        labels: ['111','222','333','444','555','666','777','888'],
        datasets: [{
            data: dataArr
        }],
      };
      return data
    }
    return(
      <div className='w-full flex flex-col  text-white border border-[#FFFFFF80] p-6'>
        <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
            <div className="text-[16px] font-[300]">Status</div>
        </div>
        {pieData && <div className="flex flex-row items-center w-full py-[16px] flex-wrap" style={{gap:20}}>
                <div className="w-[140px] h-[140px]">
                    <Pie width={140} height={140} data={pieData || data} options={options}/>
                </div>
                 <div className="flex flex-col flex-1 justify-center items-stretch gap-[8px]">
                    {['Draft', 'Pending', 'Approved', 'Valid', 'Expired', 'Cancelled'].map((title, idx) => {
                      const typeCount = pieData.datasets[0].data?.[idx]
                      return (<div key={title + 'Type'} className="flex flex-row w-full">
                          <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[idx], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[idx]})`}}></div>
                          <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                              <div className="ml-2">{title}</div>
                              <div>{typeCount} ({Math.floor((typeCount /devideBy) * 100)}%)</div>
                          </div>
                      </div>)
                    })}
                    {/* <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[0], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[0]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Draft</div>
                            <div>{pieData.datasets[0].data?.[0]} ({Math.floor(100*pieData.datasets[0].data?.[0]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[1], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[1]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Pending</div>
                            <div>{pieData.datasets[0].data?.[1]} ({Math.floor(100*pieData.datasets[0].data?.[1]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[2], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[2]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Approved</div>
                            <div>{pieData.datasets[0].data?.[2]} ({Math.floor(100*pieData.datasets[0].data?.[2]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[3], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[3]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Valid</div>
                            <div>{pieData.datasets[0].data?.[3]} ({Math.floor(100*pieData.datasets[0].data?.[3]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[4], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[4]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Expired</div>
                            <div>{pieData.datasets[0].data?.[4]} ({Math.floor(100*pieData.datasets[0].data?.[4]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="w-[16px] h-[16px] flex justify-center items-center border" style={{borderColor:colors[5], background:`radial-gradient(rgba(50, 50, 50, 0%) 0%, ${colors[5]})`}}></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Cancelled</div>
                            <div>{pieData.datasets[0].data?.[5]} ({Math.floor(100*pieData.datasets[0].data?.[5]/(pieData.datasets[0].data?.[0]+pieData.datasets[0].data?.[1]+pieData.datasets[0].data?.[2]+pieData.datasets[0].data?.[3]+pieData.datasets[0].data?.[4]+pieData.datasets[0].data?.[5]))}%)</div>
                        </div>
                    </div> */}
                </div>
        </div>}
      </div>
    )
}

export default connect((store) => {
  return {
    token: store.tokenReducer,
    control: store.controlReducer,
  }
})(Component)