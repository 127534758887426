import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m9.665 1.274-.94-.94L5 4.061 1.272.334l-.94.94 3.727 3.727L.332 8.727l.94.94 3.727-3.726 3.726 3.726.94-.94L5.94 5.001l3.726-3.727Z"
    />
  </svg>
)
export default SvgComponent
