import './home.css'
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Login, sethomepagePopup } from '../actions/actions';
import { mapStyleDark, mapStyleWhite}  from '../dto/googleMapStyles'
import { DRAW_ZONE, DRAW_ZONE_2, DRAW_ZONE_3, DRAW_ZONE_3_COLOR_1, DRAW_ZONE_3_COLOR_2, ENABLE_DRAW_ZONE, defaultMapProps } from '../dto/googleMapDefault';
import useSupercluster from 'use-supercluster';
import { GOOGLEMAPAPIKEY, isHideConfinedSpace, isHideDangerZone, isHideSmartCamera, isHideSmartLock } from '../config';
import {fetchCameras, fetchDangerZones, fetchConfinedSpaces } from '../services/request.service';
import {fetchSmartWatch} from '../services/watches.service';
import {fetchSmartLock} from '../services/locks.service';
import MapPointComponent from '../components/home/MapPoint'
import MapClusterComponent from '../components/home/MapCluster'
import Weather from '../components/home/WeatherForecast';
import QuickSummary from '../components/home/QuickSummary';
import AlertPopup from '../components/home/AlertPopup';
import DeviceColorInfomation from '../components/home/DeviceColorInfo';
import DeviceClusterPopup from '../components/home/DeviceClusterPopup';
import { DEVICE_TYPE } from '../dto/devices';
import { THEMES } from '../dto/theme';

const allFilter = [DEVICE_TYPE.LOCK, DEVICE_TYPE.WATCH,DEVICE_TYPE.CAMERA,DEVICE_TYPE.DANGER_ZONE,DEVICE_TYPE.CONFINED_SPACE]

function Screen(props) {
    const mapRef = useRef();
    const [zoom,setZoom]=useState(defaultMapProps.zoom)
    const [bounds, setBounds] = useState(null);
    const [allDevice, setAllDevice] = useState({})
    const deviceFilter = useRef(new Set(allFilter))
    const [allPoints, setAllPoints] = useState([])
    useEffect(() => {
        fetchData()
        const updateInterval = setInterval(()=>{fetchData()},60*1000)
        return ()=>{clearInterval(updateInterval)}
    },[])
    useEffect(() => {
        createPointsFunc()
    },[allDevice])



    async function fetchData(){
        const combineObj = {}
        if(!isHideSmartLock){
            const lockRes = await fetchSmartLock(props.token.token, {'projectId':props.control.projectId})
            if(lockRes.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else if(lockRes.errno){
                console.warn('failed to fetch lock data')
            }else{
                combineObj[DEVICE_TYPE.LOCK] = lockRes.result
            }
        }
        if(!isHideSmartCamera){
            const cameraRes = await fetchCameras(props.token.token, {'projectId':props.control.projectId})
            if(cameraRes.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else if(cameraRes.errno){
                console.warn('failed to fetch camera data')
            }else{
                combineObj[DEVICE_TYPE.CAMERA] = cameraRes.result
            }
        }

        const watchRes = await fetchSmartWatch(props.token.token, {'projectId':props.control.projectId})
        if(watchRes.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(watchRes.errno){
            console.warn('failed to fetch watch data')
        }else{
            combineObj[DEVICE_TYPE.WATCH] = watchRes.result
        }


        if(!isHideDangerZone){
            const dangerZonesRes = await fetchDangerZones(props.token.token, {'projectId':props.control.projectId})
            if(dangerZonesRes.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else if(dangerZonesRes.errno){
                console.warn('failed to fetch dangerZone data')
            }else{
                combineObj[DEVICE_TYPE.DANGER_ZONE] = dangerZonesRes.result
            }
        }

        if(!isHideConfinedSpace){
            const confinedSpaceRes = await fetchConfinedSpaces(props.token.token, {'projectId':props.control.projectId})
            if(confinedSpaceRes.errno===10001){
                props.dispatch(Login(false))
                return alert('Session Expired');
            }else if(confinedSpaceRes.errno){
                console.warn('failed to fetch confinedSpace data')
            }else{
                combineObj[DEVICE_TYPE.CONFINED_SPACE] = confinedSpaceRes.result
            }
        }


        setAllDevice(combineObj)
    }

    function createPointsFunc(){
        // console.log('creating points')
        const createdPoints = []
        Object.entries(allDevice)?.forEach(arr => {
            if(!deviceFilter.current.has(arr[0])) return;
            const points = arr[1].filter(obj => obj.data.longitude && obj.data.latitude).map(obj => {
                return {
                    type: "Feature",
                    properties: { cluster: false, id: obj.id, data: obj },
                    geometry: {
                        type: arr[0],
                        coordinates: [
                            parseFloat(obj.data.longitude),
                            parseFloat(obj.data.latitude)
                        ]
                    }        
                }
            })
            createdPoints.push(...points)
        })
        setAllPoints(createdPoints)
    }

    const { clusters, supercluster } = useSupercluster({
        points: allPoints,
        bounds,
        zoom,
        options: { 
            radius: 75,
            maxZoom: 18,
        },
    });
    const [mapType,setmapType]=useState('hybrid')

    return (
        <div className='flex flex-col w-full h-full bg-[#04111F]'>
            
            <div className='relative w-full h-full overflow-hidden' style={{opacity:.8}}>
                <div className='w-full h-full' style={{}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLEMAPAPIKEY ,libraries: ['drawing']}}
                    defaultCenter={defaultMapProps.center}
                    defaultZoom={defaultMapProps.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onZoomAnimationEnd={()=>{props.dispatch(sethomepagePopup(null))}}
                    onGoogleApiLoaded={({ map ,maps}) => { 
                        if(!ENABLE_DRAW_ZONE)return;
                        mapRef.current = map;
                        let polygon= new maps.Polygon({
                            paths:DRAW_ZONE,
                            strokeColor:'#29FFD9',
                            fillColor:'#29FFD980'
                        })
                        polygon.setMap(map)

                        if(DRAW_ZONE_2){
                            let polygon2= new maps.Polygon({paths:DRAW_ZONE_2,strokeColor:'#29FFD9', fillColor:'#29FFD980'})
                            polygon2.setMap(map)
                        }
                        if(DRAW_ZONE_3){
                            let polygon3= new maps.Polygon({ paths:DRAW_ZONE_3,strokeColor:DRAW_ZONE_3_COLOR_1||'#29FFD9',fillColor:DRAW_ZONE_3_COLOR_2||'#29FFD980'})
                            polygon3.setMap(map)
                        }
                    }}
                    onChange={(e)=>{
                        setZoom(e.zoom)
                        setBounds([ e.bounds.nw.lng,e.bounds.se.lat,e.bounds.se.lng,e.bounds.nw.lat]);
                    }}
                    options={map => ({
                        streetViewControl: true,
                        scaleControl: true,
                        zoomControl: true,
                        fullscreenControl: true,
                        controlSize: 22,
                        mapTypeId: mapType,
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                        style: map.MapTypeControlStyle.SMALL,
                        position: map.ControlPosition.TOP_LEFT,
                            mapTypeIds: [
                                map.MapTypeId.ROADMAP,
                                map.MapTypeId.SATELLITE,
                                map.MapTypeId.HYBRID,
                                map.MapTypeId.TERRAIN,
                            ]
                        },
                        styles:mapStyleDark,
                        tilt:0,
                     })}
                     onMapTypeIdChange={(e)=>{setmapType(e)}}
                >

                    {clusters.map((cluster,index) => {
                        const [longitude, latitude] = cluster.geometry.coordinates;
                        const {
                            cluster: isCluster,
                            point_count: pointCount
                        } = cluster.properties;
                        

                        if (isCluster) {
                            return (
                                <MapClusterComponent
                                    key={index}
                                    lat={latitude}
                                    lng={longitude}
                                    pointCount={pointCount}
                                    points={allPoints}
                                    id={cluster.id}
                                    items={supercluster.getLeaves(cluster.id,Infinity)}
                                />
                            );
                        }
                        return (
                            <MapPointComponent
                                type={cluster.geometry.type}
                                key={index}
                                lat={latitude}
                                lng={longitude}
                                cluster={cluster}
                                dispatch={props.dispatch}
                                homepagePopup={props.control.homepagePopup}
                            />
                        )
                    })}
                    
                </GoogleMapReact>
                </div>


                <DeviceColorInfomation/>
                <Weather/>
                <QuickSummary/>
                <AlertPopup/>
                {props.control?.homepagePopup?.type==='cluster'&&<DeviceClusterPopup/>}
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  