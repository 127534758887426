import { server_ip } from "../config";

async function fetchPermitWorkerLists(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/permitWorkerLists/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editpermitWorkerListRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/permitWorkerLists/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createpermitWorkerListsRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/permitWorkerLists`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deletepermitWorkerListsRecord(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/permitWorkerLists/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

export{
    fetchPermitWorkerLists,
    editpermitWorkerListRecord,
    createpermitWorkerListsRecord,
    deletepermitWorkerListsRecord,
}