import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M15 0h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5ZM9 8H5C3.35 8 2 6.65 2 5s1.35-3 3-3h4V0H5C2.24 0 0 2.24 0 5s2.24 5 5 5h4V8ZM6 4h8v2H6V4Z"
    />
  </svg>
)
export default SvgComponent
