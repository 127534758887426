import { useSearchParams } from 'react-router-dom'
import TitleLeftSvg from '../../svgs/TitleLeftSvg'
import './GasChart.css'

const CONFIG = {
    gas_o2:{
        min:0,
        max:100,
        y:['100%','80%','60%','40%','20%'],
        type:'range',
        color:'#29FFD9',
        unit:'%',
    },
    temperature:{
        min:0,
        max:50,
        y:['50°C','40°C','30°C','20°C','10°C'],
        type:'limit',
        color:'#0057FF',
        unit:'°C',
    },
    particle_pm25:{
        min:0,
        max:100,
        y:['100 ug/m3','80 ug/m3','60 ug/m3','40 ug/m3','20 ug/m3'],
        type:'limit',
        color:'#63BDFF',
        unit:'ug/m3',
    },
    gas_co:{
        min:0,
        max:100,
        y:['100 PPB','80 PPB','60 PPB','40 PPB','20 PPB'],
        type:'limit',
        color:'#BF96FF',
        unit:'PPB',
    },
    gas_co2:{
        min:0,
        max:10000,
        y:['10000 PPM','8000 PPM','6000 PPM','4000 PPM','2000 PPM'],
        type:'limit',
        color:'#FF969C',
        unit:'PPM',
    },
    gas_h2s:{
        min:0,
        max:20000,
        y:['20000 PPB','16000 PPB','12000 PPB','8000 PPB','4000 PPB'],
        type:'limit',
        color:'#654CFF',
        unit:'PPB',
    },
    gas_ch4:{
        min:0,
        max:100,
        y:['100 PPM','80 PPM','60 PPM','40 PPM','20 PPM'],
        type:'limit',
        color:'#29FF4C',
        unit:'PPM',
    },
    gas_ex:{
        min:0,
        max:100,
        y:['100% LEL','80% LEL','60% LEL','40% LEL','20% LEL'],
        type:'range',
        color:'#F47CFF',
        unit:'% LEL',
    },
}
const start = 60
const end = 1140
export default function Component(props){
    let [searchParams, setSearchParams] = useSearchParams();
    const handleScroll=(e)=>{
        //e.preventDefault();
        const boo= e.deltaY>0
        const time= searchParams.get('time')||`${new Date().getHours()<10?'0':''}${new Date().getHours()}:${new Date().getMinutes()<10?'0':''}${new Date().getMinutes()}`
        searchParams.delete('time')
        let hh = parseInt(time.split(':')[0])
        let mm = parseInt(time.split(':')[1])
        if(boo){
            mm=mm+1
            if(mm>=60){
                mm=mm%60
                hh=(hh+1)%24
            }
        }else{
            mm=mm-1
            if(mm<0){
                mm=59
                hh=(hh-1)<0?23:(hh-1)
            }
        }
        searchParams.set('time', `${hh<10?'0':''}${hh}:${mm<10?'0':''}${mm}`)
        setSearchParams(searchParams)
    }
    return(
        <div className="w-ffull flex flex-col p-[16px] mb-[24px]" onWheel={handleScroll} style={{border:'.5px solid #ffffff80',touchAction:"none"}}>
            <div className="flex flex-row items-center w-full pb-[8px]" style={{borderBottom:'.5px solid #ffffff80'}}>
                <TitleLeftSvg className='mr-[9px]'/>
                <div className="text-[16px] text-white ">{props.label}</div>
            </div>


            <svg
                className='mt-[24px]'
                xmlns="http://www.w3.org/2000/svg"
                width={'100%'}
                style={{background:'#ff000000',height:undefined,aspectRatio:120/14}}
                viewBox='0 0 1200 140'
            >
                {/* y axis text */}
                <text x={25} y={10} textAnchor='middle' fill='#ffffff80' fontSize={6}>{CONFIG?.[props.field]?.y[0]}</text>
                <text x={25} y={30} textAnchor='middle' fill='#ffffff80' fontSize={6}>{CONFIG?.[props.field]?.y[1]}</text>
                <text x={25} y={50} textAnchor='middle' fill='#ffffff80' fontSize={6}>{CONFIG?.[props.field]?.y[2]}</text>
                <text x={25} y={70} textAnchor='middle' fill='#ffffff80' fontSize={6}>{CONFIG?.[props.field]?.y[3]}</text>
                <text x={25} y={90} textAnchor='middle' fill='#ffffff80' fontSize={6}>{CONFIG?.[props.field]?.y[4]}</text>

                {/* grid */}
                <line x1={50} y1={10} x2={1150} y2={10} stroke='#ffffff30' strokeWidth={.5}/>
                <line x1={50} y1={30} x2={1150} y2={30} stroke='#ffffff30' strokeWidth={.5}/>
                <line x1={50} y1={50} x2={1150} y2={50} stroke='#ffffff30' strokeWidth={.5}/>
                <line x1={50} y1={70} x2={1150} y2={70} stroke='#ffffff30' strokeWidth={.5}/>
                <line x1={50} y1={90} x2={1150} y2={90} stroke='#ffffff30' strokeWidth={.5}/>

                {CONFIG?.[props.field].type==='range'&&props.gasConfig?.[props.field]?.enabled&&
                    <svg>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].warning_lower)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].warning_lower)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#FFC329' strokeDasharray="4" strokeWidth={.5}/>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].warning_upper)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].warning_upper)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#FFC329' strokeDasharray="4" strokeWidth={.5}/>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].evacuate_lower)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].evacuate_lower)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#F47070' strokeDasharray="4" strokeWidth={.5}/>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].evacuate_upper)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].evacuate_upper)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#F47070' strokeDasharray="4" strokeWidth={.5}/>
                    </svg>
                }
                {CONFIG?.[props.field].type==='limit'&&props.gasConfig?.[props.field]?.enabled&&
                    <svg>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].warning)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].warning)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#FFC329' strokeDasharray="4" strokeWidth={.5}/>
                        <line x1={50} y1={10+ 100-((props.gasConfig?.[props.field].evacuate)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} x2={1150} y2={10+ 100-((props.gasConfig?.[props.field].evacuate)/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)} stroke='#F47070' strokeDasharray="4" strokeWidth={.5}/>
                    </svg>
                }

                {props.data?.map((item,index)=>{
                        let color = CONFIG?.[props.field].color
                        if(CONFIG?.[props.field].type==='range'&&props.gasConfig?.[props.field]?.enabled){
                            const value = item.data?.[props.field]
                            const isWarning = value<= props.gasConfig?.[props.field].warning_lower || value >= props.gasConfig?.[props.field].warning_upper
                            const isEvacuate = value<= props.gasConfig?.[props.field].evacuate_lower || value >= props.gasConfig?.[props.field].evacuate_upper
                            color = isEvacuate?'#F47070':isWarning?'#FFC329':color
                        }else if(CONFIG?.[props.field].type==='limit'&&props.gasConfig?.[props.field]?.enabled){
                            const value = item.data?.[props.field]
                            const isWarning = value>= props.gasConfig?.[props.field].warning
                            const isEvacuate = value >= props.gasConfig?.[props.field].evacuate
                            color = isEvacuate?'#F47070':isWarning?'#FFC329':color
                        }
                        return(
                            <svg className='gas-chart-container' key={item?._id||index}>
                                <line 
                                    x1={start+ (new Date(item.timestamp).getTime()- props.time.getTime())*(end-start)/1800000 }
                                    y1={110}
                                    x2={start+ (new Date(item.timestamp).getTime()- props.time.getTime())*(end-start)/1800000}
                                    y2={10+ 100-((item.data?.[props.field])/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)}
                                    stroke={color}
                                    strokeWidth={1}
                                />
                                <circle
                                    cx={start+ (new Date(item.timestamp).getTime()- props.time.getTime())*(end-start)/1800000} 
                                    cy={10+ 100-((item.data?.[props.field])/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)}
                                    fill={color}
                                    r={2}
                                />
                                {/* <text
                                    x={start+ (new Date(item.timestamp).getTime()- props.time.getTime())*(end-start)/1800000} 
                                    y={10+ 100-((item.data?.[props.field])/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)}
                                    fill='#ffffffff' fontSize={3} 
                                >
                                    {item?.timestamp.split('T')?.[1]?.split('.')?.[0]?.split(':')?.[1]}
                                </text> */}

                                <svg className='gas-chart-popup'>
                                    <text
                                        textAnchor='middle'
                                        x={start+ (new Date(item.timestamp).getTime()- props.time.getTime())*(end-start)/1800000} 
                                        y={Math.max((10+ 100-((item.data?.[props.field])/(CONFIG?.[props.field].max-CONFIG?.[props.field].min)*100)-10),10)}
                                        fill='#ffffffff' fontSize={8} fontWeight={'900'} style={{zIndex:'1'}}
                                    >
                                        {item.data?.[props.field]}{CONFIG?.[props.field].unit} {new Date(item.timestamp)?.toLocaleTimeString()}
                                    </text> 
                                </svg>
                            </svg>
                        )
                    }
                )}

                {Array(31).fill().map((x,i)=>i).map((item,index)=>
                    <text key={index} x={start + index*(end-start)/30} y={130} textAnchor='middle' fill='#ffffff' fontSize={8}>
                        {(new Date(props.time.getTime()+index*60000)).getHours()<10?'0':''}{(new Date(props.time.getTime()+index*60000)).getHours()}
                        :{(new Date(props.time.getTime()+index*60000)).getMinutes()<10?'0':''}{(new Date(props.time.getTime()+index*60000)).getMinutes()}
                    </text>
                )}
                

                <line x1={0} y1={110} x2={1200} y2={110} stroke='#ffffff80' strokeWidth={1}/>
                
            </svg>


        </div>
    )
}