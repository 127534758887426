import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#a)">
      <path d="M19.5 8h-1V3h-12v5h-1c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3Zm-11-3h8v3h-8V5Zm8 12v2h-8v-4h8v2Zm2-2v-2h-12v2h-2v-4c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2Z" />
      <path d="M18.5 12.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h24v24H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
