import React from 'react';
import { connect } from 'react-redux';
import FormTextInput from './formComponets/FormTextInput';
import FormTitleDes from './formComponets/FormTitleDes';
import FormTable from './formComponets/FormTable'
import FormSelectBox from './formComponets/FormSelectBox';
import FormDate from './formComponets/FormDate';
import FormImage from './formComponets/FormImage';
import FormDropDown from './formComponets/FormDropDown';
//svgs
import SmallHeader from '../../../svgs/tracking/SmallHeader'
import { PERMIT_FIELD_TYPE } from '../../../dto/permit';
import FormWorkerList from './formComponets/FormWorkerList';
function GenerateForm({isPreview, sectionIdx, fieldIdx, fieldObj, updateFieldDataDataWithKey,updateFieldData}) {
  function handleUpdateData(val){
    updateFieldDataDataWithKey(sectionIdx, fieldIdx, val)
  }
    return (<>
        {fieldObj.type === PERMIT_FIELD_TYPE[0] && <FormTextInput isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[1] && <FormTitleDes fieldObj={fieldObj}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[3] && <FormTable isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} updateFieldData={updateFieldData}/>}
        {fieldObj.type === 'Image' && <FormImage isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[2] && <FormSelectBox isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[4] && <FormDropDown isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[5] && <FormDate isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData}/>}
        {fieldObj.type === PERMIT_FIELD_TYPE[6] && <FormWorkerList isPreview={isPreview} sectionIdx={sectionIdx} fieldIdx={fieldIdx} fieldObj={fieldObj} handleUpdateData={handleUpdateData} />}
    </>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(GenerateForm)