import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M4 14.5h8v2H4v-2Zm0-4h8v2H4v-2Zm6-10H2c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2v-12l-6-6Zm4 18H2v-16h7v5h5v11Z"
    />
  </svg>
)
export default SvgComponent
