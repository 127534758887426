import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.341 8.825 2.526 5l3.817-3.825L5.167 0l-5 5 5 5 1.175-1.175Z"
    />
  </svg>
)
export default SvgComponent
