import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||16}
    height={props.width||16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fill="#63BDFF"
      d="m9.717 5.517.766.766-7.55 7.55h-.766v-.766l7.55-7.55Zm3-5.017a.834.834 0 0 0-.584.242l-1.525 1.525 3.125 3.125 1.525-1.525a.83.83 0 0 0 0-1.175l-1.95-1.95A.818.818 0 0 0 12.717.5Zm-3 2.658L.5 12.375V15.5h3.125l9.217-9.217-3.125-3.125Z"
    />
  </svg>
)
export default SvgComponent
