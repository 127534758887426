import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import DropdownMenu from '../../../DropdownMenu';
import { fetchPermitWorkerLists } from '../../../../services/permitWorkerLists.service';
//svgs
function Component({isPreview, sectionIdx, fieldIdx, fieldObj, handleUpdateData,control,token}) {
    const {fieldData, fieldId, fullWidth, required} = fieldObj


    const [record,setRecord]=useState({})

    useEffect(() => {
        let timer = setTimeout(() => fetchWorkerInfoList(), 500)
        return () => clearTimeout(timer)
    },[])
    async function fetchWorkerInfoList(){
        if(!control?.projectId) return
       
        const res = await fetchPermitWorkerLists(token.token, {projectId:control?.projectId}, {limit:1})
        if(res?.errno) return console.log('error fetching data')
        if(res?.result){
            setRecord(res?.result?.[0]||[])
        }
    }


    const handleUpdateField=(index1,index2)=>(e)=>{
        let data= [...(fieldData?.data || [])]
        data[index1]=data?.[index1]||[]
        data[index1][index2]=e.target.value
        handleUpdateData(data)
    }
    const [isSelecting,setisSelecting]=useState(null)
    const [selectedWorker,setselectedWorker]=useState(null)
    const handleConfirmSelectWorker=()=>{
        if(!selectedWorker|| selectedWorker==='Select Worker'){
            let data= [...(fieldData?.data || [])]
            data[isSelecting]=data?.[isSelecting]||[]
            data[isSelecting][0]=''
            data[isSelecting][1]=''
            data[isSelecting][2]=''
            handleUpdateData(data)
            setisSelecting(null)
            setselectedWorker(null)
            return
        }
        let foundRecord = record?.list?.filter(w=>`${w.name} (${w.number})`===selectedWorker)?.[0]
        if(foundRecord){
            let data= [...(fieldData?.data || [])]
            data[isSelecting]=data?.[isSelecting]||[]
            data[isSelecting][0]=foundRecord.name
            data[isSelecting][1]=foundRecord.number
            data[isSelecting][2]=foundRecord.expireDate
            handleUpdateData(data)
            setisSelecting(null)
            setselectedWorker(null)
        }
    }
    return (
    <div className='w-full mt-[24px]'>
        <div className=''>{fieldData.title || ''}</div>
        <div className='flex flex-row w-full'>
            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 32, paddingRight: 12}}>
                姓名
            </div>
            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 12}}>
                核准工人證明書編號
            </div>
            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 12}}>
                到期日
            </div>
            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 12}}>
                簽署
            </div>
            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 32}}>
                時間
            </div>
        </div>

        {[...Array(Math.max(parseInt(fieldData?.noOfRows)||1,1)).keys()]?.map((item)=>
            <div key={item} className='flex flex-row w-full'>
                <div onClick={()=>{setisSelecting(item)}} className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold cursor-pointer' style={{flex: 1,paddingLeft: 12, paddingRight: 12, color:fieldData?.data?.[item]?.[0]?'white':'grey'}}>
                    {fieldData?.data?.[item]?.[0]||'Select Worker'}
                </div>
                <div onClick={()=>{setisSelecting(item)}} className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold cursor-pointer' style={{flex: 1,paddingLeft: 12, paddingRight: 12,color:fieldData?.data?.[item]?.[0]?'white':'grey'}}>
                    {fieldData?.data?.[item]?.[0]?(fieldData?.data?.[item]?.[1]||'-'):'-'}
                </div>
                <div onClick={()=>{setisSelecting(item)}} className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold cursor-pointer' style={{flex: 1,paddingLeft: 12, paddingRight: 12,color:fieldData?.data?.[item]?.[0]?'white':'grey'}}>
                    {fieldData?.data?.[item]?.[0]?(fieldData?.data?.[item]?.[2]||'-'):'-'}
                </div>
                <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 12}}>
                    <input disabled={false} value={fieldData?.data?.[item]?.[3]} onChange={handleUpdateField(item,3)} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-full'></input>
                </div>
                <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{flex: 1,paddingLeft: 12, paddingRight: 32}}>
                    <input disabled={false} value={fieldData?.data?.[item]?.[4]} onChange={handleUpdateField(item,4)} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-full'></input>
                </div>
            </div>
        )}

        {isSelecting!==null&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:99}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                <div className='flex flex-row items-center space-between w-full'>
                    <div className='font-bold'>Select Worker</div>          
                </div>
                <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={()=>{setisSelecting(null)}}>X</div>         
                
                <div className='mt-[24px]'/>
                <DropdownMenu select={(item,index)=>{setselectedWorker(item)}} value={selectedWorker||'Select Worker'} options={['Select Worker',...(record||[])?.list?.map(worker=>`${worker.name} (${worker.number})`)]}/>
                <div className='mt-[120px]'/>

                <div onClick={handleConfirmSelectWorker} className='button_blue w-full mt-5'>
                    OK
                </div>
            </div>
        </div>}
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)