export default function reducer(state={
	isLogin:false,
	token:null,
	usertype:null,
	username:null,
	userid:null
},action) {
	//const newState = {...state}
	switch(action.type){
		case "Login":{
			state={...state, 
				isLogin:action.payload,
				token:action.token,
				usertype:action.usertype,
				username:action.username,
				userid:action.userid,
			}
			break;
		}
		default: {
			;
		}
	}
	return state
}