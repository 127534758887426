import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      stroke="#717171"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.5 1.832v2.667M8.5 12.5v2.667M3.785 3.785l1.887 1.887M11.328 11.328l1.887 1.887M1.832 8.5h2.667M12.5 8.5h2.667M3.785 13.215l1.887-1.887M11.328 5.672l1.887-1.887"
    />
  </svg>
)
export default SvgComponent
