import React, { useRef, useState, useEffect } from 'react';
import "../components/DropdownMenu.css"
import { connect } from 'react-redux';
import {createPortal} from 'react-dom'
import { useNavigate, useSearchParams } from "react-router-dom";
import { LEFTRIGHT_PADDING } from "../config/style.config";
import {fetchTemplateList} from '../services/permit.service'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import BluePlus from '../svgs/permit/BluePlus'
import SearchMagGlass from '../svgs/permit/SearchMagGlass'
import DeviceSettingSvg from '../svgs/watchPage/DeviceSettingSvg';
import DownArrow from '../svgs/map/DownArrow'
import EditIconSvg from '../svgs/camera/EditIcon'
import BinSvg from '../svgs/watchPage/BinSvg'
//componets
import NoPermssion from './NoPermission'
import PopUpBase from '../components/PopUpBase'
import PermitPopUp from '../components/permit/PermitPopup';
import PageSelector from '../components/PageSelector'
import CopyLink from '../components/CopyLink';
//action
import {getDateOnly,getTimeOnly} from '../actions/actionsDateTime'
import { deleteFileByName } from '../services/httpApiTracking';
import {deleteTemplate} from '../services/permit.service'
import { isSSM508 } from '../config';
const CHART_TITLE = [
    {title: 'Item', field: null, flex: 1},
    {title: 'Name', field: 'name', flex: 4},
    {title: 'Type', field: 'type', flex: 4},
    {title: 'Last Update', field: 'modifyDate', flex: 4},
    {title: 'Actions', field: '', flex: 4},
]
function Screen(props) {
    const hasPerms = props.control.permissions
    const [templatePopup, setTemplatePopup] = useState(false)
    const [deletePop, setDeletePop] = useState(false)
    const [targetDelete, setTargetDelete] = useState(undefined)
    const limit = 10
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const filterName = searchParams.get('name')
    const filterType = searchParams.get('type')
    const filterCompany = searchParams.get('company')
    const filterDate = searchParams.get('date')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const page =  parseInt(searchParams.get('page')) || 1;
    const [totalItem, setTotalItem] = useState(1)
    const totalPage = Math.ceil(totalItem/limit)||1
    const [templateListData, setTemplateListData] = useState([])
    const [threeDotPopIdx, setThreeDotPopIdx] = useState(undefined)
    useEffect(() => {
        if(!hasPerms.PERMIT_VIEW) return
        let timer = setTimeout(() => fetchTemplateListData(), 500)
        return () => clearTimeout(timer)
    },[searchParams, hasPerms, props.control])
    async function fetchTemplateListData(){
        const filter = {}
        if(filterType) filter['type'] = {"$eq": `${filterType}`}
        if(filterName) filter['name'] = {"$regex": `${filterName}`, "$options":'i'}
        filter["projectId"] = props.control.projectId
        const res = await fetchTemplateList(props.token.token, filter, {limit,skip:limit*(page-1)})
        if(res.errno) return console.log('Error fetching data')
        setTotalItem(res.count)
        setTemplateListData(res.result)
    }
    async function handleDeleteTemplate(){
        const data = targetDelete
        if(data.form?.length > 0){
            data.form.forEach((sectionObj, secIdx) => {
                sectionObj.data.forEach((fieldObj, fieldIdx) => {
                    if(fieldObj.type === 'Image' && fieldObj.fieldData.imgId.length > 0){
                        fieldObj.fieldData.imgId.forEach(async ({id, type}) => {
                            const res = await deleteFileByName(props.token.token, id+'.'+type.split('/')[1])
                        })
                    }
                })
            })
        }
        const deleteRes = await deleteTemplate(props.token.token, data._id)
        if(deleteRes.errno) console.log(deleteRes)
        fetchTemplateListData()
        setDeletePop(false)
        setTargetDelete(null)
    }
    function startDelete(data){
        setDeletePop(true)
        setTargetDelete(data)
    }
    function handleParamChange(key, val){
        const obj = {
            name: filterName,
            type: filterType,
            company: filterCompany,
        }
        if(val === '' || val === 'All'){
            searchParams.delete(key)
            return setSearchParams(searchParams)
        }
        // if(obj[key] === val){
        //     searchParams.delete(key)
        //     return setSearchParams(searchParams)
        // }
        if(key === 'page' && (val > totalPage || val < 1)) return;
        searchParams.delete(key)
        searchParams.append(key, val)
        setSearchParams(searchParams)
    }
    const handleSelectPage=(num)=>()=>{
        handleParamChange('page', num)
    }
    function handleTemplatePopup(bol){
        setTemplatePopup(bol)
    }
    function handleThreeDotPop(idx){
        if(idx === threeDotPopIdx) return setThreeDotPopIdx(undefined)
        setThreeDotPopIdx(idx)
    }
    function handleCreatePermit(items){
        const temp = {...items}
        temp.createdBy = props.control.userData._id
        navigate('/permit/create', {state:{data:temp}})
    }
    if(hasPerms.PERMIT_VIEW === false) return (<NoPermssion/>)
    return(
        
        <div className='w-full h-full bg-[#0C0D14]' style={{overflowY:'auto'}}>
            {templatePopup && createPortal(<PopUpBase closePopUp={handleTemplatePopup}> <PermitPopUp setCreatePopUp={handleTemplatePopup}/> </PopUpBase>, props.portalRef.current)}
            <div style={{...LEFTRIGHT_PADDING}} className='w-full'>
                <div className="flex flex-row items-center mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Permit System</div>
                </div>
                <div className="flex flex-row justify-between items-center text-white mt-[48px]">
                    <div className="flex flex-row">
                        <div className="subpage_not_selected" onClick={() => navigate('/permit')}>Permit</div>
                        <div className="subpage_selected" >Template</div>
                    </div>
                    <div className='flex flex-row items-center gap-[4px]'>
                        {(hasPerms.PERMIT_TEMPLATE&& isSSM508 )&& <div className='button_blue' onClick={() => navigate('/permit/worker-list')}>
                            <DeviceSettingSvg className='mr-[2px]' /> Worker Info. List
                        </div>}
                        {hasPerms.PERMIT_TEMPLATE && <div className='button_blue' onClick={() => setTemplatePopup(true)}>
                            <BluePlus className='mr-[2px]' /> Create Template
                        </div>}
                    </div>
                </div>
                <div className="flex flex-row justify-between items-center text-white mt-[27px]" style={{borderBottomWidth: 0.5, borderBottomColor:'#FFFFFF4D'}}>
                    <div className="flex flex-row">
                        <div className="p-[18px] font-bold text-white cursor-pointer flex flex-row items-center gap-2">Template <CopyLink /></div>
                    </div>
                    <div className='flex flex-row items-center gap-[4px]'>
                    <div className='flex flex-row items-center px-[12px] w-[180px] h-[28px] border border-[#aaa] rounded-[16px]'>
                        <SearchMagGlass className='mr-[8px]'/>
                        <input onChange={(e) => handleParamChange('name', e.target.value)} className="flex flex-row items-center text-[12px] pointer-cursor bg-transparent h-[18px] my-[0px]" placeholder='Name' /> 
                    </div>
                    <div className='relative dropdown_main select-none cursor-pointer'>
                        <div className="hover:bg-[#888] flex flex-row items-center text-[12px] gap-[8px] w-[180px] px-[16px] py-[4px] rounded-[16px] border border-[#aaa] pointer-cursor justify-between" > 
                            {filterType||'Type'}
                            <DownArrow/>
                        </div>
                        <div className='absolute top-[100%] left-0 dropdown_main_expand flex-col text-xs bg-black w-full gap-[4px] rounded-[4px] border border-[#aaa] max-h-[200px]' style={{overflowY:'auto', zIndex:999}}>
                            {(['All','Permit to Move','Permit to Work','Permit to Operate','Other Permit']).map((item,index)=>
                                <div onClick={() => {handleParamChange('type', item)}} key={index} className='px-[16px] py-[4px] hover:text-black hover:bg-white cursor-pointer'> {item}</div>
                            )}
                        </div>
                    </div>
                        {/* <Dropdown value='Company' options={['All Company']} paramKey={'Company'} onClick={handleParamChange}/> */}
                    </div>
                </div>
                <div className="py-[32px] px-[0px]">
                    <div className="flex flex-row text-[14px]">
                        {CHART_TITLE.map((obj, i) => (
                            <div key={'title'+ obj.title} className="table_header" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                {obj.title}
                            </div>
                        ))}
                    </div>
                    {templateListData.length > 0 && templateListData?.map((items, idx) =>
                        <div key={'TrackP2' + idx} className="table_row" style={{overflowWrap: 'break-word',backgroundColor: idx%2 ?'#FFFFFF1D':'transparent'}}>
                            {CHART_TITLE.map((h, i) => 
                            <div key={'TrackP2' + h.title + i} className="table_item" style={{flex: h.flex}}>{
                                h.title === 'Actions' ? (hasPerms.PERMIT_TEMPLATE && <div className='flex flex-row items-center gap-4 relative'>
                                    <div onClick={() => handleCreatePermit(items)}className='bg-[#00202A] py-[2px] px-[16px] rounded-full text-[#5FC5FF] cursor-pointer select-none'>Create Permit</div>
                                    <div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]' onClick={() => {navigate('/permit/template/add', {state:{data: items, isEdit: true}})}} ><EditIconSvg/></div>
                                    <div className='cursor-pointer' onClick={() => {startDelete(items)}}><BinSvg color='#777676'/></div>
                                  
                                </div>):
                                h.title === 'Last Update' ? `${getDateOnly(items[h.field])} ${getTimeOnly(items[h.field])}`:
                                h.title === 'Item' ? (idx + 1) + ((page - 1) * limit)
                                : items[h.field]
                            }</div>
                            )}
                        </div>
                    )}
                </div>
                <PageSelector page={page} total={totalItem} totalPage={totalPage} handleSelectPage={handleSelectPage}/>
            </div>
            {deletePop && 
                <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex: 999}}>
                    <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                        <div className='flex flex-row items-center space-between w-full'>
                            <div className='font-bold'>Confirm Delete</div>          
                        </div>
                        <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => setTargetDelete(null)}>X</div>         

                        <div className="text-white mt-10 font-bold text-[14px]">Name:</div>
                        <div className='bg-transparent rounded-[99px] text-sm px-[16px] py-[2px] mt-1'  style={{border:'.5px solid #ffffff80'}}>
                            {targetDelete?.name}
                        </div>
                        <div onClick={handleDeleteTemplate} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                            Delete
                        </div>
                    </div>
                </div> 
            }
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)