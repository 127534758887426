import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={18}
    fill="none"
    {...props}
  >
    <path stroke="#fff" strokeWidth={0.5} d="m13.216.127-10.001 17" />
    <path stroke="#fff" strokeWidth={2} d="m6.857 2.514-6 10" />
  </svg>
)
export default SvgComponent
