import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||20}
    height={props.width||20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#fff"
      d="M1.013 7.233a.998.998 0 0 1 .513-1.316L14.6.161c.995-.438 2.22.051 2.645 1.042l2.21 5.154a2.001 2.001 0 0 1-1.052 2.624l-6.038 2.588 1.24 3.1h4.323v-3h2v8h-2v-3h-4.323a1.988 1.988 0 0 1-1.856-1.258l-1.222-3.054-5.205 2.23a1 1 0 0 1-1.31-.517l-.312-.71L2 14.04l-2-5 1.536-.613-.523-1.194Zm4.434 5.126 12.169-5.215-2.208-5.152L3.246 7.346l2.2 5.013Z"
    />
  </svg>
)
export default SvgComponent
