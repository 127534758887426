import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    {...props}
  >
    <g clipPath="url(#acloseicon)">
      <path
        fill="#fff"
        d="m11.085 4.239-.823-.822-3.26 3.26-3.262-3.26-.822.822 3.26 3.26-3.26 3.262.822.822 3.261-3.26 3.261 3.26.823-.822L7.824 7.5l3.26-3.261Z"
      />
    </g>
    <defs>
      <clipPath id="acloseicon">
        <path fill="#fff" d="M0 .5h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
