import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <rect width={30} height={30} fill="#333" rx={7} />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="m7.668 15 1.292 1.292 5.125-5.115v11.156h1.833V11.177l5.115 5.124 1.302-1.302L15 7.666l-7.333 7.333Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M4 4h22v22H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
