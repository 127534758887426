import { server_ip } from "../config";

async function fetchAICamEventStats(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/statistic`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"CAMERA"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

export {
    fetchAICamEventStats,
}