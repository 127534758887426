import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
//svgs
function FormTitleDes({isPreview, sectionIdx, fieldIdx, fieldObj}) {
    const {fieldData:{title, description}, fieldId, fullWidth, required} = fieldObj
    return (
    <div key={fieldId} className='flex flex-col mb-[16px] mt-[24px]'>
        <div className='flex flex-row text-[14px]'>{title}</div>
        <div className='text-[12px] text-[#797979]'>{description}</div>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormTitleDes)