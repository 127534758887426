import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <path
      stroke="#63BDFF"
      strokeLinecap="round"
      d="M13.25 1.75 9.312 13l-2.25-5.063L2 5.688 13.25 1.75Z"
    />
  </svg>
)
export default SvgComponent
