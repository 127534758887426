import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={31}
    fill="none"
    {...props}
  >
    <rect width={30} height={30} x={0.285} y={0.5} fill="#00202A" rx={7} />
    <g fill={props.color||"#fff"} clipPath="url(#a3123)">
        <circle cx={10.285} cy={16.5} r={2} />
      <circle cx={16.285} cy={16.5} r={2} />
      <circle cx={22.285} cy={16.5} r={2} />
    </g>
    <defs>
      <clipPath id="a3123">
        <path fill={props.color||"#fff"} d="M4.285 4.5h22v22h-22z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
