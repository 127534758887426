import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g clipPath="url(#alistview)">
      <path
        fill={props.color||"#fff"}
        d="M3 9.5h14v-2H3v2Zm0 4h14v-2H3v2Zm0 4h14v-2H3v2Zm16 0h2v-2h-2v2Zm0-10v2h2v-2h-2Zm0 6h2v-2h-2v2Z"
      />
    </g>
    <defs>
      <clipPath id="alistview">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
