import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#717171"
        d="M14.25 3.5h-.75V2H12v1.5H6V2H4.5v1.5h-.75c-.833 0-1.493.675-1.493 1.5L2.25 15.5a1.5 1.5 0 0 0 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V5c0-.825-.675-1.5-1.5-1.5Zm0 12H3.75V8h10.5v7.5Zm0-9H3.75V5h10.5v1.5ZM6.75 11h-1.5V9.5h1.5V11Zm3 0h-1.5V9.5h1.5V11Zm3 0h-1.5V9.5h1.5V11Zm-6 3h-1.5v-1.5h1.5V14Zm3 0h-1.5v-1.5h1.5V14Zm3 0h-1.5v-1.5h1.5V14Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
