import React from 'react';
import { connect } from 'react-redux';
//svgs
import GreyCalendar from '../../../../svgs/permit/GreyCalendar'
import TickSvg from '../../../../svgs/permit/FormTick'
function FormSelectBox({isPreview, sectionIdx, fieldIdx, fieldObj, handleUpdateData}) {
    const {fieldData:{title, options, data=[]}, fieldId, fullWidth, required} = fieldObj
    const optionsArr = (options.split(';') || false)
    if(optionsArr){
        if(optionsArr.at(-1) === '')optionsArr.pop()
    }
    function handleOptionChange(val){
        let newOptions = []
        if(data.includes(val)){
            data?.forEach(value=>{
                if(value !== val) newOptions.push(value)
            })
        }else{
            newOptions = [...data, val]
        }
        handleUpdateData(newOptions)
    }
    return (
    <div key={fieldId + fieldIdx} className={'flex flex-col px-[12px] mt-[24px]' + (fullWidth ? ' w-full' : ' w-[50%]')}>
        <label className='mb-[12px] text-[16px]'>{title}</label>
        {optionsArr &&optionsArr.map((opt, optIdx) => (
            <div key={opt + optIdx} className='flex flex-row items-center mb-[16px] ml-[12px]'>
                <label className='relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'>
                    <input disabled={isPreview} className='absolute opacity-0 cursor-pointer' onChange={() => handleOptionChange(opt)} type="checkbox" />
                    {/* <div style={{backgroundColor: data.includes(opt) ? 'white' : 'transparent'}}className='h-full w-full'></div> */}
                    <TickSvg width={24} style={{opacity: data.includes(opt) ? 100 : 0}}/>
                </label>
                <div>{opt}</div>
            </div>
        ))} 
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormSelectBox)