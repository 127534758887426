import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#abinfff)">
      <path
        fill={props.color||"#fff"}
        d="M6.333 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7h-12v12Zm2-10h8v10h-8V9Zm7.5-5-1-1h-5l-1 1h-3.5v2h14V4h-3.5Z"
      />
    </g>
    <defs>
      <clipPath id="abinfff">
        <path fill="#fff" d="M.333 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
