import { DEVICE_TYPE } from "../dto/devices"

export const LEFTRIGHT_PADDING= {
    paddingLeft:27,
    paddingRight:27
}

export const DEVICE_COLOR_DICT = {
    [DEVICE_TYPE.CAMERA]:'#0057FF',
    [DEVICE_TYPE.LOCK]:'#29FFD9',
    [DEVICE_TYPE.WATCH]:'#63BDFF',
    [DEVICE_TYPE.DANGER_ZONE]:'#FFE454',
    [DEVICE_TYPE.CONFINED_SPACE]:"#FF969C"
}