import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { LEFTRIGHT_PADDING } from "../config/style.config";
import { postTemplateDocument, postCreatePermit, postPermitHistory, putEditPermit, fetchPermitById} from '../services/permit.service';
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import BluePlus from '../svgs/permit/BluePlus'
import SmallHeader from '../svgs/tracking/SmallHeader'
//componets
import GenerateForm from '../components/permit/display/GenerateForm';
import FormStatus from '../components/permit/display/FormStatus';
import Loading from '../components/Loading'
import PermitActivityLog from '../components/permit/PermitActivityLog';
//
import {getDateOnly} from '../actions/actionsDateTime'
import { deleteFileByName } from '../services/httpApiTracking';

function Screen(props) {
    const navigate = useNavigate();
    const param = useLocation()
    const URLParam = useParams()
    // console.log(param)
    const currentUserId = props.control.userData._id
    if(!param.state?.data && !URLParam._id) navigate('/permit')
    const [templateData, setTemplateData] = useState(param?.state?.data)
    const [formData, setFormData] = useState(param?.state?.data?.form)
    const [copyTemplate, setCopyTemplate] = useState({...param?.state?.data})
    const [editedObj, setEditedObj] = useState({})
    const [attachments, setAttachements] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [pageToggle, setPageToggle] = useState('Overview')
    const [userIsIssuer, setIsUserIssuer] = useState(undefined)
    const [userIsApprover, setIsUserApprover] = useState(undefined)
    const [userIsReceiver, setIsUserReceiver] = useState(undefined)
    const [hasPermission, setHasPermission] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    useEffect(() => {
        console.log(templateData)
        console.log(editedObj)
    },[templateData,editedObj])
    useEffect(() => {
        if(props.control.userData?._id && templateData?.createdBy && !hasPermission){
            const issuerPermission = props.control.userData._id === templateData?.createdBy
            const approvePermission = (templateData?.approvers || []).some((obj) => obj.username === props.control.userData.username)
            const viewPermission = (templateData?.viewers || []).some((obj) => obj.username === props.control.userData.username)
            //viwer is the approver array
            // console.log({issuerPermission, approvePermission, viewPermission})
            setIsUserIssuer(issuerPermission)
            setIsUserApprover(approvePermission)
            setIsUserReceiver(viewPermission)
            const havePerm = [issuerPermission, approvePermission, viewPermission].some((perms) => perms === true)
            // if(!havePerm) navigate('/permit')
            setHasPermission(havePerm)
        }
    },[props.control.userData, templateData])

    // useEffect(() => {
    //     console.log('editObj',editedObj)
    // },[editedObj])
    useState(async () => {
            let temp = {...templateData}
            if(URLParam._id){
                const newTemplateData = await fetchPermitById(props.token.token,URLParam._id)
                if(newTemplateData.errno) return navigate('/permit')
                temp = {...newTemplateData.result}
            } 
            delete temp.createDate
            delete temp.modifiedBy
            delete temp.modifyDate
            delete temp.createBy
            temp.template_id = temp._id
            temp.templateName = temp?.name || temp?.templateName
            temp.templateType = temp?.type || temp?.templateType

            if(!temp.approvers)temp.approvers = []
            if(!temp.receivers)temp.receivers = []
            if(!temp.viewers)temp.viewers = []
            temp.newAttachments = []
            temp.newDelete = []
            // console.log(temp)
            setCopyTemplate({...temp})
            setTemplateData(temp)
            setFormData(temp.form)
    },[])
    function handleSubmitError(msg){
        setErrorMsg(msg)
        setIsLoading(false)
    }
    async function sumbitData(){
        setIsLoading(true)
        const finalData = {...templateData}
        finalData.form = [...formData]
        // return compareForHistory(finalData)
        if(finalData.attachments === undefined) finalData.attachments = []
        if(finalData.isAllApprovers === undefined) finalData.isAllApprovers = false
        if(finalData?.status === undefined) return handleSubmitError('Pick a status');
        if(finalData.validFrom === undefined || finalData.validTo === undefined) return handleSubmitError('Input valid time');
        if(!finalData.validFrom instanceof Date && !isNaN(finalData.validFrom)) return handleSubmitError('invalid validFrom date formate')
        if(!finalData.validTo instanceof Date && !isNaN(finalData.validTo)) return handleSubmitError('invalid validTo date formate')
        //
        delete finalData._id
        delete finalData.type
        const copyNewAttachement = [...finalData.newAttachments]
        finalData.attachments = [...finalData.attachments, ...finalData.newAttachments]
        delete finalData.newAttachments
        let permitRes
        if(finalData.newDelete.length > 0){
            finalData.newDelete.forEach(async ({id, type}) => {
                const res = await deleteFileByName(props.token.token, id+'.'+type.split('/')[1])
            })
        }
        delete finalData.newDelete
        delete finalData.createdById
        delete finalData.createdByUser
        delete finalData.createdBy
        if(URLParam._id){
            delete finalData.templateName
            delete finalData.templateType
            delete finalData.projectId
            delete finalData.template_id
            permitRes = await putEditPermit(props.token.token, finalData, templateData._id)
            const history = compareForHistory(finalData)
            console.log(history)
            const historyRes = await handlePostHistory(history)
        }else{
            permitRes = await postCreatePermit(props.token.token, finalData)
            // console.log('this is permit res',permitRes)
            const createFirst = await handlePostHistory({created:''},permitRes.result.insertedId)
            console.log('called handlePostHistory')
        }
        if(permitRes.errno) return console.log(permitRes)
        //
        // console.log('copy new Attach', copyNewAttachement)
        if(copyNewAttachement.length > 0){
            let i = 0
            for(const file of attachments.entries()){
                const id = copyNewAttachement[i].id
                const type = copyNewAttachement[i].type
                const res = await postTemplateDocument(props.token.token, file, id, type)
                if(res.errno) return console.log(res)
                i++
            }
        }

        setIsLoading(false)
        navigate('/permit')
    }
    function handleHaveAttachement(formData){
        setAttachements(formData)
    }
    function updateFieldDataDataWithKey(i, k, val){
        const temp = [...formData]
        const newEditedObj = {...editedObj}
        const title = temp[i].data[k].fieldData.title === undefined ? temp[i].data[k].fieldData.textInputTitle : temp[i].data[k].fieldData.title
        const newKey = `Section${i+1}_${temp[i].data[k].type}_${title}`
        newEditedObj[newKey] = [val]
        temp[i].data[k].fieldData.data = val
        setEditedObj(newEditedObj)
        setFormData(temp)
    }
    function updateFieldData(i,k,val){
        const temp = [...formData]
        const newEditedObj = {...editedObj}
        const type = temp[i].data[k].type
        const newKey = `Section${i+1}_${type}_field${k+1}`
        newEditedObj[newKey] = 'Updated value'

        temp[i].data[k].fieldData = val
        setEditedObj(newEditedObj)
        setFormData(temp)
    }
    function handleDeleteExistingFile(obj){
        const temp = {...templateData}
        const copy = [...templateData.attachments]
        const final = copy.filter(({id, type}) => id !== obj.id)
        const deleteArr = copy.filter(({id, type}) => id === obj.id)
        const newDelete = [...templateData.newDelete,...deleteArr]
        temp.newDelete = newDelete
        temp.attachments = final
        //
        const newEditedObj = {...editedObj}
        const type = 'remove_attachment'
        const newKey = `${type}_${obj.id + '.' + obj.type.split('/')[1]}`
        newEditedObj[newKey] = ''
        setEditedObj(newEditedObj)
        setTemplateData(temp)
    }
    function updateTemplateDataWithKey(key, val){
        const temp = {...templateData}
        const newEditedObj = {...editedObj}
        const newKey = `Permit_${key}`
        if(['validFrom','validTo'].includes(key)){
            newEditedObj[newKey] = [getDateOnly(val)]
        }
        temp[key] = val
        if(key === 'newAttachments'){
            const type = 'permit_newAttachement'
            newEditedObj[type] = ''
        }
        setEditedObj(newEditedObj)
        setTemplateData(temp)
    }
    async function handlePostHistory(obj, whenCreate = null){
        const data = {}
        data.permit_id = templateData._id
        if(whenCreate) data.permit_id = whenCreate
        data.data = obj
        const res = await postPermitHistory(props.token.token, data)
    }
    function compareForHistory(newObj){
        const historyObj = {...editedObj}
        if(copyTemplate.status !== newObj.status) historyObj.status = templateData.status
        const usersAll = ['approvers', 'receivers', 'viewers']
        usersAll.forEach(key => {
            const idToApprove = {}
            const ogUser = copyTemplate[key].map(obj => {
                if(key === 'approvers')idToApprove[obj.username] = obj.isApproved
                if(key === 'receivers')idToApprove[obj.username] = obj.isRead
                return obj.username
            })
            const userSet = new Set(ogUser)
            console.log(idToApprove, userSet)
            newObj[key].forEach(obj => {
                console.log(obj)
                if(!userSet.has(obj.username)){
                    const objKey = 'added_'+key
                    historyObj[objKey] ? historyObj[objKey].push(obj.username) : historyObj[objKey] = [obj.username]
                }else{
                    if(key === 'approvers'){
                        if(idToApprove[obj.username] !== obj.isApproved){
                            historyObj.newlyApproved ? historyObj.newlyApproved.push(obj.username) : historyObj.newlyApproved = [obj.username]
                        }
                    }
                    if(key === 'receivers'){
                        if(!idToApprove[obj.username] === obj.isRead){
                            historyObj.newlyRead ? historyObj.newlyRead.push(obj.username) : historyObj.newlyRead = [obj.username]
                        }
                    }
                    userSet.delete(obj.username)
                }
            })
            const remaining = Array.from(userSet)
            if(remaining.length > 0){
                remaining.forEach((user) => {
                    const newKey = 'removed_'+key
                    historyObj[newKey] ? historyObj[newKey].push(user) : historyObj[newKey] = [user]
                })
            }
        })
        console.log(historyObj)
        return {
            data: historyObj
        }
    }
    function handleUpdateRoles(type, obj){
        const currentList = [...templateData[type]]
        let targetDeleteIdx = undefined
        const inCurrentList = currentList.some((currObj, idx) => {
            const result = currObj.username === obj.username
            if(result) targetDeleteIdx = idx
            return result
        })
        if(inCurrentList){
            currentList.splice(targetDeleteIdx, 1)
        }else{
            let tempObj = undefined
            if(type === 'approvers')tempObj = {username: obj.username, isApproved: -1}
            if(type === 'viewers')tempObj = {username: obj.username}
            currentList.push(tempObj)
        }
        updateTemplateDataWithKey(type, currentList)
    }
    return (
        <div className='w-full h-full bg-[#0C0D14] text-white pb-12' style={{overflowY:'auto'}}> 
            {isLoading && <div className='select-none opacity-40 w-[100vw] h-[100vh] fixed inset-0 bg-[#000000] flex flex-row items-center justify-center' style={{zIndex:99}}>
                <Loading />
            </div>}           
            <div style={{...LEFTRIGHT_PADDING}} className='flex flex-col'>
                    <div className="flex flex-col justify-between items-center text-white">
                        <div className="flex flex-row items-center justify-between mt-[40px] w-full">
                            <div className='flex flex-row items-center flex-1'>
                                <TitleLeftSvg className='mr-[9px]'/>
                                <div className="text-[24px] text-white font-bold flex-1">Preview - {templateData?.templateName}<span className='text-[14px] text-[#949494]'> | {templateData?.type || templateData?.templateType}</span></div>
                            </div>
                            <div className='flex flex-row items-center justify-center gap-4'>
                                <div onClick={() => navigate('/permit')} className='py-[2px] px-[16px] bg-[#FFFFFF26] text-white rounded-full select-none cursor-pointer'>Cancel</div>
                                {hasPermission && <div onClick={() => {sumbitData()}} className='py-[2px] px-[16px] bg-[#00202A] text-[#63BDFF] rounded-full select-none cursor-pointer'>{URLParam._id ? 'Save Permit' : 'Create Permit'}</div>}
                            </div>
                        </div>
                        <div className='text-[red] ml-auto'>{errorMsg}</div>

                    </div>
                    <div className='w-full flex flex-row mt-[40px]'>
                        <div className='flex-1'>
                            <div className='w-full flex flex-row'>
                                <div onClick={() => {setPageToggle('Overview')}} className='p-[8px] select-none cursor-pointer text-[16px] font-bold' style={pageToggle === 'Overview' ? {borderBottomWidth: 1, borderBottomColor: '#FFFFFF80', color: 'white'} : {color:'#3F3F3F'}}>Overview</div>
                                <div onClick={() => {setPageToggle('Activity Log')}} className='p-[8px] select-none cursor-pointer text-[16px] font-bold' style={pageToggle === 'Activity Log' ? {borderBottomWidth: 1, borderBottomColor: '#FFFFFF80', color: 'white'} : {color:'#3F3F3F'}}>Activity Log</div>
                            </div>
                            {pageToggle === 'Overview' && <div className='flex-1 max-w-[1265px] mr-[40px]'>
                                <GenerateForm inPending={templateData.status === 'Pending for approval'} permissionObj = {{userIsIssuer, userIsApprover, userIsReceiver}} form={formData} isPreview={false} updateFieldDataDataWithKey={updateFieldDataDataWithKey} updateFieldData={updateFieldData}/>
                            </div>}
                            {(pageToggle === 'Activity Log' && URLParam._id) && <PermitActivityLog hasPermission={hasPermission} handlePostHistory={handlePostHistory} _id={templateData._id}/>}
                        </div>
                        <div className='flex-1 max-w-[600px] h-full py-[24px] px-[20px]'>
                            <FormStatus hasPermission={hasPermission}   handleUpdateRoles={handleUpdateRoles} handleDeleteExistingFile={handleDeleteExistingFile} handleHaveAttachement={handleHaveAttachement} templateData={templateData} updateTemplateDataWithKey={updateTemplateDataWithKey} isPreview={false}/>
                        </div>
                    </div>
            </div>
        </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)