import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={16}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M.383 15.071 9 8 .383.929V15.07Z" />
  </svg>
)
export default SvgComponent
