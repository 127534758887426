import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||30}
    height={props.width||30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={0.4}
      d="M14.98 16.97a2.078 2.078 0 0 0-1.26 3.73v1.6a1.261 1.261 0 0 0 2.52 0v-1.6a2.078 2.078 0 0 0-1.26-3.73Zm.65 3.17c-.12.07-.2.2-.2.35v1.82c0 .25-.2.45-.45.45s-.45-.2-.45-.45v-1.82c0-.14-.07-.27-.2-.35-.39-.23-.62-.65-.62-1.09 0-.7.57-1.27 1.27-1.27.7 0 1.27.57 1.27 1.27 0 .45-.24.87-.62 1.09Z"
    />
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M21.85 12.7v12.73c0 1.04-.84 1.88-1.88 1.88h-9.93c-1.04 0-1.88-.84-1.88-1.88V4.57c0-1.04.84-1.88 1.88-1.88h9.93c1.04 0 1.88.84 1.88 1.88v2.02"
    />
    <path
      fill="#fff"
      d="M26.05 8.27c-.36-.36-.84-.56-1.35-.56h-6.93a3.365 3.365 0 0 0-6.14 1.91 3.365 3.365 0 0 0 6.14 1.91h6.93c1.05 0 1.91-.86 1.91-1.91 0-.51-.2-.99-.56-1.35Zm-1.35 1.76h-7.88c-.19.83-.93 1.46-1.82 1.46-1.03 0-1.87-.84-1.87-1.87 0-1.03.84-1.87 1.87-1.87.89 0 1.63.63 1.82 1.46h7.88a.4.4 0 0 1 .29.12.4.4 0 0 1 .12.29c0 .23-.18.41-.41.41Z"
    />
  </svg>
)
export default SvgComponent
