import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#columnPen)">
      <path
        fill="#fff"
        d="m9.373 6.013.614.614-6.04 6.04h-.614v-.614l6.04-6.04ZM11.773 2a.667.667 0 0 0-.466.193l-1.22 1.22 2.5 2.5 1.22-1.22c.26-.26.26-.68 0-.94l-1.56-1.56A.655.655 0 0 0 11.773 2Zm-2.4 2.127L2 11.5V14h2.5l7.373-7.373-2.5-2.5Z"
      />
    </g>
    <defs>
      <clipPath id="columnPen">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
