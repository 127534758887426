import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#columnBin)">
      <path
        fill="#FFE454"
        d="M4.003 12.667c0 .733.6 1.333 1.333 1.333h5.333c.734 0 1.334-.6 1.334-1.333v-8h-8v8ZM5.336 6h5.333v6.667H5.336V6Zm5-3.333L9.669 2H6.336l-.667.667H3.336V4h9.333V2.667h-2.333Z"
      />
    </g>
    <defs>
      <clipPath id="columnBin">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
