import { server_ip } from "../config";

async function fetchAICameras(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/cameras/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editAICamera(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/cameras/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createAICamera(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/cameras`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteAICamera(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/cameras/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchLatestSnapshotByDeviceId(token,projectId,deviceId){
    try {
        const response = await fetch(`${server_ip}/api/cameraSnapshots/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{projectId,deviceID:deviceId},
                "options":{"limit":1,sort:{time:-1}}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchCameraEvent(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"CAMERA"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchSnapshotByTime(token,projectId,deviceId,time,isGreaterThanEq=true){
    try {
        const response = await fetch(`${server_ip}/api/cameraSnapshots/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{
                    projectId,
                    deviceID:deviceId,
                    time:isGreaterThanEq? {$gte:time} : {$lt:time} 
                },
                "options":{"limit":1,sort:{time:isGreaterThanEq?1:-1}}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

export{
    fetchAICameras,
    editAICamera,
    createAICamera,
    deleteAICamera,
    fetchLatestSnapshotByDeviceId,
    fetchCameraEvent,
    fetchSnapshotByTime,
}