import React, {useState, useEffect} from 'react'
import DetailsSvg from '../../svgs/map/Details';
import { useNavigate } from 'react-router-dom';
import { fetchLatestAlerts } from '../../services/request.service';
import { connect } from 'react-redux';
const CHART_TITLE = [
    {title: 'Device', field: 'device', flex: 1},
    {title: 'Type', field: 'type', flex: 1},
    {title: 'time', field: 'time', flex: 1},
]

function Component(props){
    const navigate = useNavigate()
    const [alertData, setAlertData] = useState([])
    useEffect(() => {
        fetchData()
       const timer = setInterval(() => fetchData(), 30000) 
       return () => clearInterval(timer)
    },[])
    async function fetchData(){
        const filter = {}
        filter["projectId"] = props.control.projectId
        filter['type'] = {"$nin": ["LOCK_UNLOCK", "LOCK_LOCK"]}
        const res = await fetchLatestAlerts(props.token.token, filter,{limit:10, sort:{time: -1}})
        if(res.errno) return
        setAlertData(res.result)
    }
    function formatType(str){
        const obj = {
            "LOCK_UNLOCK":'Unlock',
            "LOCK_LOCK": "Lock",
            "LOCK_INAPR_UNLOCK": "Inappropriate unlock",
            "LOCK_VIBRATION": "Vibration",
            "WATCH_SOS":'SOS Alert',
            "WATCH_HEARTRATE": "Heart Rate",
            "WATCH_BLD_OXYGEN": "Blood Oxygen",
            "WATCH_FALLDOWN": "Fall Alert",
            'CAMERA_DNG_ZONE':'Danger Zone',
            'CAMERA_NO_JACKET':'No Jactket',
            'CAMERA_NO_HELMET':'No Helmet',
            'CAMERA_FALLDOWN':'Falldown',
        }
        return obj[str]
    }
    function moreDetails(e,device,name){
        e.preventDefault()
        const obj = {
            'WATCH': `/smartwatch?search=${name.split(' ').join('+')}`,
            'LOCK':`/smartlock?alert_search=${name.split(' ').join('+')}&locking_search=${name.split(' ').join('+')}`,
            'CAMERA': `/camera/alert?search=${name.split(' ').join('+')}`
        }
        console.log(name)
        navigate(`${obj[device]}`)
    }
    return(
        <div className="w-full flex flex-col text-white cursor-default">
            <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
                    <div className="text-[16px] font-[300]">Latest Alerts</div>
                </div>


                <div className='flex flex-row items-center w-full py-[8px] px-[4px]'>
                    {CHART_TITLE.map((obj, i) => 
                        <div className='text-xs font-bold flex-1'>{obj.title}</div>
                    )}
                </div>
                {alertData.length > 0 && alertData.map((item,index)=>
                    <div key={index} className='flex flex-row items-center w-full py-[8px] px-[4px]' style={{backgroundColor:Number(index)%2===0?'#FFFFFF1D':'transparent'}}>
                        {CHART_TITLE.map((h, i) => 
                        <div className='text-xs flex-1'>{
                            h.field === 'time' ? new Date(item[h.field]).toLocaleString().split(',').join('-')
                            : h.field === 'type' ? formatType(item[h?.field])
                            : item[h?.field]
                        }</div>)}
                        <div onClick={(e) => {moreDetails(e,item.device, item.name)}} className='hover:scale-110'>
                            <DetailsSvg className='w-[20px] cursor-pointer'/>
                        </div>
                    </div>
                )}
        </div>
    )
}
{/* <div key={index} className='flex flex-row items-center w-full py-[8px] px-[4px]' style={{backgroundColor:Number(index)%2===0?'#FFFFFF1D':'transparent'}}> */}

                        {/* <div className='text-xs flex-1'>No Safety Helmet</div>
                        <div className='text-xs flex-1'>NTC_V01_Cam02</div>
                        <div className='text-xs flex-1'>11:29 am | 6-June</div>
                        <DetailsSvg className='w-[20px] cursor-pointer'/> */}
export default connect((store) => {
    return{
        token: store.tokenReducer,
        control: store.controlReducer,
    }
})(Component)