import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31}
    fill="none"
    {...props}
  >
    <rect width={30} height={30} y={0.5} fill="#00202A" rx={7} />
    <g clipPath="url(#HistorySvg)">
      <path
        fill="#fff"
        d="m12.249 7.25-3.667 3.658h2.75v6.425h1.833v-6.425h2.75L12.25 7.25Zm6.416 12.843v-6.426h-1.833v6.426h-2.75l3.667 3.657 3.666-3.657h-2.75Z"
      />
    </g>
    <defs>
      <clipPath id="HistorySvg">
        <path fill="#fff" d="M4 4.5h22v22H4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
