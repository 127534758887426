import { server_ip } from "../config";

async function fetchDangerZones(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/dangerZones/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editDangerZonesRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/dangerZones/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createDangerZonesRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/dangerZones`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteDangerZonesRecord(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/dangerZones/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    fetchDangerZones,
    editDangerZonesRecord,
    createDangerZonesRecord,
    deleteDangerZonesRecord,
}