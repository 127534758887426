import React from "react"

function LoadingPage({type, SA}){ 
    if(SA !== undefined){
        return(
            <div className="select-none" style={{display:'inline-block'}}>
                <div className='rounded-full py-[2px] px-[16px]' style={SA ? {backgroundColor:'#115B4E', color: '#29FFD9'} : {backgroundColor:'#737373'}}>
                    {SA ? 'Connected' : 'Sleep'}
                </div>
            </div>
        )   
    }
    if(type === 'Low battery'){
        return(
        <div className="select-none" style={{display:'inline-block'}}>
            <div className='rounded-full py-[2px] px-[16px]' style={{backgroundColor:'#314147', color: '', color:'#3CC1F6'}}>
                Low battery
            </div>
        </div>
        )
    }else if(type === 'Unlock for too long'){
        return(
        <div className="select-none" style={{display:'inline-block'}}>
            <div className='rounded-full py-[2px] px-[16px]' style={{backgroundColor:'#3D344C', color: '', color:'#BF96FF'}}>
            Unlock for too long
            </div>
        </div>
        );
    }
}

export default LoadingPage 