import React, {useState} from 'react';
import { connect } from 'react-redux';
//svg
import FieldArrow from '../../svgs/permit/FieldArrow'
import FieldBin from '../../svgs/permit/FieldBin'
import DropArrow from '../../svgs/permit/DropDownArrow'
import { PERMIT_FIELD_TYPE } from '../../dto/permit';
function TextInputField({i, k,handleUpdateRequirements, handleFieldDelete, fieldObj, len, handleDataChangeWithKey,localUpdateField, handleFieldReorder }){
    const [fieldDrop, setFieldDrop] = useState(false)
    return(
        <div key={'fieldObj' + fieldObj.feildId + k} className='text-white my-[24px] select-none'>
            <div className='flex flex-row'>
                {/* this is the left Bar */}
                <div className='w-[8px] rounded-full' style={{background: 'linear-gradient(180deg, #654CFF 0%, #3D2E99 100%)'}}></div>
                {/* ^^^ this is the left Bar ^^^ */}
                <div className='flex flex-col flex-1 p-[32px] bg-[#FFFFFF12]'>
                    <div className='flex flex-row justify-between'>
                        <div>Field</div>
                        <div className='flex flex-row gap-2 flex flex-row items-center'>
                        <div className='flex flex-row items-center mr-[32px]'>
                            <div className='mr-[8px]'>Required</div>
                            <div onClick={()=>{handleUpdateRequirements('required', !fieldObj.required)}} className='px-[2px] w-[40px] h-[22px] rounded-full flex flex-row items-center cursor-pointer' style={fieldObj.required ? {justifyContent:'flex-end', backgroundColor:'#63BDFF', border: '2px solid #63BDFF'} : {justifyContent:'flex-start', border: '2px solid #777676'}}>
                                <div className='w-[16px] h-[16px] py-0 bg-white rounded-full' style={fieldObj.required ? {backgroundColor:'#00202A'} : {backgroundColor:'#777676'}}></div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center mr-[32px]'>
                            <div className='mr-[8px]'>Full Width</div>
                            <div onClick={()=>{handleUpdateRequirements('fullWidth', !fieldObj.fullWidth)}} className='px-[2px] w-[40px] h-[22px] rounded-full flex flex-row items-center cursor-pointer' style={fieldObj.fullWidth ? {justifyContent:'flex-end', backgroundColor:'#63BDFF'} : {justifyContent:'flex-start', border: '2px solid #777676'}}>
                                <div className='w-[16px] h-[16px] py-0 bg-white rounded-full' style={fieldObj.fullWidth ? {backgroundColor:'#00202A'} : {backgroundColor:'#777676'}}></div>
                            </div>
                        </div>
                        {k !== 0 && <FieldArrow className='cursor-pointer' onClick={() => {handleFieldReorder('up', i, k)}}/>}
                        {k !== len - 1 && <FieldArrow className='rotate-180 cursor-pointer' onClick={() => {handleFieldReorder('down', i, k)}}/>}
                        <FieldBin className='cursor-pointer' onClick={()=>{handleFieldDelete(i,k)}}/>
                    </div>
                    </div>
                    <div className='flex flex-row justify-between items-center gap-4 mt-[32px]'>
                        <div className='flex flex-col' style={{flex:4}}>
                            <div className='mb-[8px]'>Text Input Title*</div>
                            <input value={fieldObj.fieldData.textInputTitle||""} onChange={(e) => {handleDataChangeWithKey('textInputTitle', e.target.value);handleDataChangeWithKey('data', {})}} className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Enter title for user text input'></input>
                        </div>
                        <div className='relative flex flex-col' style={{flex: 1}}>
                            <div className='mb-[8px]'>Type</div>
                            <div onClick={() => setFieldDrop(!fieldDrop)} className='cursor-pointer border rounded-full px-[16px] py-[2px] flex flex-row items-center justify-between'>{fieldObj.type || 'Text input'}<DropArrow/></div>
                            {fieldDrop && 
                            <div className='z-99'>
                                <div className='w-[100vw] h-[100vh] fixed inset-0 bg-[#00000080]' onClick={() => setFieldDrop(false)}></div>
                                <div className='absolute top-[100%] bg-black w-full rounded border bg-black z-50'>
                                    {PERMIT_FIELD_TYPE.map((text) => (
                                        <div key={text+'div'} className='py-[2px] px-[16px] cursor-pointer hover:text-black hover:bg-white' onClick={() => {localUpdateField(text)}}>{text}</div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(TextInputField)