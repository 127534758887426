import './PopUpBase.css'
import React, {useEffect, useState } from "react";

function PopUpBase(props){
    return (
        <div className="fixed w-[100%] h-[100%] flex flex-row items-center justify-center popUpBase">
            <div className="absolute w-[100%] h-[100%]" onClick={() => props.closePopUp(false)}></div>
                {props.children}
        </div>
    )
}

export default PopUpBase