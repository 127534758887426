import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||21}
    height={props.width||21}
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M15.286 7.166h-.833V5.5c0-2.3-1.867-4.167-4.167-4.167A4.168 4.168 0 0 0 6.119 5.5h1.667c0-1.384 1.116-2.5 2.5-2.5 1.383 0 2.5 1.116 2.5 2.5v1.666h-7.5c-.917 0-1.667.75-1.667 1.667v8.333c0 .917.75 1.667 1.667 1.667h10c.917 0 1.667-.75 1.667-1.667V8.833c0-.917-.75-1.667-1.667-1.667Zm0 10h-10V8.833h10v8.333Zm-5-2.5c.917 0 1.667-.75 1.667-1.666 0-.917-.75-1.667-1.667-1.667s-1.667.75-1.667 1.667c0 .916.75 1.666 1.667 1.666Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.286.5h20v20h-20z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
