export function Log(data,includeTime=true){
	const today = new Date();
	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	return{
		type:"Log",
		payload:includeTime?`[${time}] ${data}`:data,
	}
}
export function Login(login,token=null,usertype=null,username=null){
	return{
		type:"Login",
		payload:login,
		token:token,
		usertype:usertype,
		username:username
	}
}
export function sethomepagePopup(payload){
	return{
		type:"sethomepagePopup",
		payload,
	}
}
export function setlanguage(payload){
	return{
		type:'setlanguage',
		payload
	}
}
export function setprojectId(payload){
	return{
		type:'setprojectId',
		payload
	}
}
export function setpermissions(payload){
	return{
		type:'setpermissions',
		payload
	}
}
export function setuserData(payload){
	return{
		type:'setuserData',
		payload
	}
}
export function settheme(payload){
	return{
		type:'settheme',
		payload
	}
}