import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#ThreeLineSvg)">
      <path fill="#fff" d="M8 18h8v-2H8v2Zm-2-5h12v-2H6v2ZM3 6v2h18V6H3Z" />
    </g>
    <defs>
      <clipPath id="ThreeLineSvg">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
