import { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchLatestSnapshotByDeviceId } from "../../services/cameras.service"

function Component(props){
    const [data,setData]=useState(null)
    const fetchPhoto=async()=>{
        let result = await fetchLatestSnapshotByDeviceId(props.token?.token,props.control?.projectId,props.deviceId)
        if(result?.result?.[0]?.data){
            setData(result?.result?.[0])
        }
    }
    useLayoutEffect(()=>{
        if(props.deviceId)
            fetchPhoto()
    },[props.deviceId])

    const [showImagePopup,setshowImagePopup]=useState(false)
    const showPopup=()=>{setshowImagePopup(true)}
    const closePopup=()=>{setshowImagePopup(false)}

    return(
        <div className='w-full flex flex-col justify-center items-center' style={{aspectRatio:1920/1080,height:undefined}}>
            {data?
                <div className="w-full h-full cursor-pointer">
                <img onClick={showPopup} src={`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                </div>
                :<div className="text-white text-[10px]">No Snapshot</div>
            }

            {showImagePopup&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%]' style={{border:'.5px solid #FFFFFF80'}}>
                   
                    <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none' onClick={closePopup}>X</div>         
                    <img src={`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                </div>
            </div>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)