import React from 'react';
import { connect } from 'react-redux';
//svgs
function FormTextInput({isPreview, sectionIdx, fieldIdx, fieldObj, handleUpdateData}) {
    const {fieldData:{textInputTitle, data}, fieldId, fullWidth, required} = fieldObj
    function handleTextChange(val){
      handleUpdateData(val)
    }
    return (
    <div key={fieldId} className={'flex flex-col px-[12px] mt-[24px]' + (fullWidth ? ' w-full' : ' w-[50%]')}>
        <label className='mb-[8px] text-[16px]'>{textInputTitle}{required ? '*': ''}</label>
        <input value={typeof data === 'object' ? '' : data} onChange={(e) => handleTextChange(e.target.value)} disabled={isPreview} required={required} className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px]'></input>
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormTextInput)