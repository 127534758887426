import './DatePicker.css'
import React, { useEffect, useState } from 'react';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import { LEFTRIGHT_PADDING } from '../config/style.config';

const pastMonth = new Date();

export default function App(props) {
  const defaultSelected = {
    from: (props.from||pastMonth),
    to: (props.to ||addDays(pastMonth, 4))
  };    
  const [range, setRange] = useState(defaultSelected);
  useEffect(() => {
    if(props.setter)props.setter({display: {from: range?.from !== undefined ? format(range?.from, 'PPP') : 'From Date' , to: range?.to !== undefined ? format(range?.to, 'PPP') : 'Date' }, range})
  },[range])

  let footer = <p>Please pick the first day.</p>;
  if (range?.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <div>
            <p>
            {format(range.from, 'PPP')}
            </p>
            <p>|</p>
            <p>{format(range.to, 'PPP')}</p>
            <p className='text-[12px]'>Deselect first date to restart</p>
        </div>
      );
    }
  }

  return (
    <div className='text-white border rounded-xl inline-block p-4 absolute bg-black prevent-select' style={{top: 90, zIndex:99}}>
        <DayPicker
          id="test"
          mode="range"
          defaultMonth={pastMonth}
          selected={range}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today'
          }}
          onSelect={setRange}
        />
    </div>
  );
}