import './DatePicker.css'
import { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export default function Example(props) {
  const [selected, setSelected] = useState();
    useEffect(() => {
        props.setter({display:selected ? format(selected, 'PP'): undefined, selected})
    },[selected])
  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
  }
  return (
    <div className='text-white border rounded-xl inline-block p-4 absolute bg-black prevent-select' style={props.style}>
        <DayPicker
        mode="single"
        selected={selected}
        onSelect={setSelected}
        />   
    </div>
  );
}

