import React, { useRef, useState, useEffect,createRef } from 'react';
import Chart from 'chart.js/auto'
import { connect } from 'react-redux';
import { fetchWatchEvent } from '../../services/watches.service';
//control to remove stright lines to the points
// let filterObjControl = undefined
// function dataFilter(dataObj){
//   filterObjControl = {}
//   if(dataObj === undefined) return
//     dataObj.forEach((obj) => {
//         if(!['bg','test'].includes(obj.label))filterObjControl[obj.label] = true
//     })
// }

//plugins for chart to draw lines the background
const chartAreaBackgroundColor = {
    id:'chartAreaBackgroundColor',
    beforeDraw(chart, args, plugins){
        const {ctx, chartArea: {top, bottom, left, right, width, height}} = chart;
        ctx.save()
        ctx.fillStyle = 'rgba(12, 13, 20, 1)'
        ctx.fillRect(left,top,width,height)
    }
}
//
const WatchChart = ({token, chartDataObj, showLegend, maxValue}) => {
  const chartRef = useRef(null)
  const [myChart, setMyChart] = useState(null);
  const filterObj = useRef({})
  let filterObjControl = filterObj.current
  function dataFilter(dataObj){
    filterObjControl = {}
    if(dataObj === undefined) return
      dataObj?.forEach((obj) => {
          if(!['bg','test'].includes(obj.label))filterObjControl[obj.label] = true
      })
  }
  const datapointLines = {
      id: 'datapointLines',
      beforeDraw(chart, args, plugins){
          const {data, ctx, scales: {x,y}, chartArea:{bottom} } = chart
          ctx.save()
          data.datasets?.forEach((obj,j) => {
              if(filterObjControl[obj.label])obj.data?.forEach((dp, i) => {
                  ctx.beginPath()
                  ctx.strokeStyle = typeof obj.borderColor === 'object' ? obj.borderColor[i%obj.borderColor.length] : obj.borderColor
                  ctx.lineWidth = 2
      
                  ctx.moveTo(x.getPixelForValue(i), chart.getDatasetMeta(j).data[i].y)
                  ctx.lineTo(x.getPixelForValue(i),bottom)
                  ctx.stroke()
                  
              })
          })
          
      }
  }
  useEffect(() => {
    if(!filterObjControl)dataFilter(chartDataObj.data)
  },[chartDataObj])
  useEffect(() => {
    dataFilter(chartDataObj.data)
  },[])
  //useEffect that creates the chart
  useEffect(() => {
    if (!chartRef) return;
    if(!chartDataObj) return;
    const ctx = chartRef.current.getContext("2d");
    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        // labels: chartDataObj.labels,
        labels: (chartDataObj.labels || chartDataObj.data[1].xLabels),
        datasets: chartDataObj.data
      },
      backgroundColor:'white',
      borderColor: 'white',
      options: {  
        responsive: true,
        plugins: {
          legend: {
            display: showLegend !== false,
            position:'bottom',
            labels: {
                  filter: function(legendItem, chartData) {
                    return legendItem.datasetIndex > 1;
                  }
              },
              labels:{
                boxWidth: 7,
                boxHeight: 7
              },
              onClick:(e, legendItem, legend) => {
                  // filteredDataFunc(legendItem.text)
                  filterObjControl[legendItem.text] = !filterObjControl[legendItem.text]
                  Chart.defaults.plugins.legend.onClick(e, legendItem, legend)
            },
          },
          tooltip: {
              filter: function (tooltipItem, data) {
                  return (tooltipItem.datasetIndex !== 0);
              },
              callbacks: {
                label: (val) => {
                  return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  // return `${val.dataset.label}: ${val.parsed.y}`
                  if(val.dataset.label === 'Blood Oxygen Warning') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'No Helmet') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'Inappropriate unlock') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'Vibration') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'SOS') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'Falldown Warning') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                  if(val.dataset.label === 'Heart Rate Warning') return `${val.dataset.label}: ${(val.parsed.y - val.dataset.toSub[val.dataIndex])}`
                }
              }
            },            
          title: {
            display: true,
            text: ''
          }
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            grid:{
              display:false,
              color: '#FFFFFF80',
              borderColor:'red'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Value'
            },
            suggestedMin: 0,
            suggestedMax: 5,
            grid:{
              color: 'rgba(255, 255, 255, 0.5)',
              borderColor:'red'
            }
          },
        }
      },
      plugins: [chartAreaBackgroundColor, datapointLines]
    });
    setMyChart(myChart);
    // console.log('loaded chart',chartRef)
    return () => {
      myChart.destroy()
    }
  }, [chartRef]);

  useEffect(() => {
    if (!myChart) return;
    myChart.data.datasets = chartDataObj.data;
    myChart.data.labels = (chartDataObj.labels || chartDataObj.data[1].xLabels)
    // myChart.data.labels = chartDataObj.labels
    myChart.options.scales.y.max = maxValue + Math.max(1.5, Math.floor((maxValue / 3)))
    myChart.update();
    // console.log(chartDataObj.data)
    // console.log('myChart', myChart)
  }, [chartDataObj, myChart, maxValue]);
  // console.log('look',chartDataObj)
  return <canvas ref={chartRef} className='max-h-[200px]' />;
};
    
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(WatchChart)