import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS , RadialLinearScale,ArcElement } from "chart.js";
import CameraSvg from '../../svgs/map/Camera';
import WatchSvg from '../../svgs/map/Watch';
import LockSvg from '../../svgs/map/Lock';
import ConfinedSpaceSvg from '../../svgs/map/ConfinedSpace';
import DangerZoneSvg from '../../svgs/map/DangerZone';
import { DEVICE_COLOR_DICT } from '../../config/style.config';
import { DEVICE_TYPE } from '../../dto/devices';

ChartJS.register(RadialLinearScale,ArcElement);


const cache = new Map();
const colors = [
  DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA],
  DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH],
  DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK],
  DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE],
  DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE]
];
const data = {
    labels: ['111','222','333','444','555'],
    datasets: [{
        data: [Math.floor(Math.random()*100),Math.floor(Math.random()*100),Math.floor(Math.random()*100),Math.floor(Math.random()*100),Math.floor(Math.random()*100)]
    }],
};
const options={
    plugins: {
      legend: false,
      tooltip: false,
    },
  
    elements: {
      arc: {
        backgroundColor: function(context) {
          let c = colors[context.dataIndex];
          if (!c) {
            return;
          }
          return createRadialGradient3(context, c);
        },
        borderColor: function(context) {
            return colors[context.dataIndex];
        }
        
      }
    },
    
  }


  function createRadialGradient3(context,c) {
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }
  
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (width !== chartWidth || height !== chartHeight) {
      cache.clear();
    }
    let gradient = cache.get(c);
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      var width = chartWidth;
      var height = chartHeight;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const r = Math.min(
        (chartArea.right - chartArea.left) / 2,
        (chartArea.bottom - chartArea.top) / 2
      );
      const ctx = context.chart.ctx;
      const diff= c===colors[0]?.0:c===colors[2]?.13:c===colors[3]?.16:c===colors[4]?.19:.1
      gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
      gradient.addColorStop(0, `white`);
      gradient.addColorStop(.1, `white`);
      gradient.addColorStop(0.1, `${c}00`);
      gradient.addColorStop(0.3, `${c}10`);
      gradient.addColorStop(0.65-diff, `${c}70`);
      gradient.addColorStop(0.65-diff, `${c}00`);
      gradient.addColorStop(0.9, `${c}50`);
      gradient.addColorStop(1, `${c}`);
      cache.set(c, gradient);
    }
  
    return gradient;
  }




export default function Component(props){
    return(
      <div className='flex flex-col  text-white'>
        <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{borderBottom:'.5px solid #aaa'}}>
            <div className="text-[16px] font-[300]">Total Alerts</div>
        </div>
        <div className="flex flex-row items-center py-[16px]" style={{gap:20}}>
                <div className="w-[140px] h-[140px]">
                    <Pie width={140} height={140} data={data} options={options}/>
                </div>
                <div className="flex flex-col flex-1 justify-center items-stretch gap-[8px]">
                  
                    <div className="flex flex-row w-full">
                        <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[0]}}><CameraSvg width={14}/></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">AI Camera</div>
                            <div>{data?.datasets[0]?.data?.[0]} ({Math.floor(100*data?.datasets[0]?.data?.[0]/(data?.datasets[0]?.data?.[0]+data?.datasets[0]?.data?.[1]+data?.datasets[0]?.data?.[2]+data?.datasets[0]?.data?.[3]+data?.datasets[0]?.data?.[4]))}%)</div>
                        </div>
                    </div>

                    <div className="flex flex-row w-full">
                        <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[1]}}><WatchSvg width={14}/></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Smart Watch</div>
                            <div>{data?.datasets[0]?.data?.[1]} ({Math.floor(100*data?.datasets[0]?.data?.[1]/(data?.datasets[0]?.data?.[0]+data?.datasets[0]?.data?.[1]+data?.datasets[0]?.data?.[2]+data?.datasets[0]?.data?.[3]+data?.datasets[0]?.data?.[4]))}%)</div>
                        </div>
                    </div>

                    <div className="flex flex-row w-full">
                        <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[2]}}><LockSvg width={14}/></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Smart Lock</div>
                            <div>{data?.datasets[0]?.data?.[2]} ({Math.floor(100*data?.datasets[0]?.data?.[2]/(data?.datasets[0]?.data?.[0]+data?.datasets[0]?.data?.[1]+data?.datasets[0]?.data?.[2]+data?.datasets[0]?.data?.[3]+data?.datasets[0]?.data?.[4]))}%)</div>
                        </div>
                    </div>

                    <div className="flex flex-row w-full">
                        <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[3]}}><DangerZoneSvg width={14}/></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Danger Zone</div>
                            <div>{data?.datasets[0]?.data?.[3]} ({Math.floor(100*data?.datasets[0]?.data?.[3]/(data?.datasets[0]?.data?.[0]+data?.datasets[0]?.data?.[1]+data?.datasets[0]?.data?.[2]+data?.datasets[0]?.data?.[3]+data?.datasets[0]?.data?.[4]))}%)</div>
                        </div>
                    </div>

                    <div className="flex flex-row w-full">
                        <div className="w-[28px] h-[28px] flex justify-center items-center border" style={{borderColor:colors[4]}}><ConfinedSpaceSvg width={14}/></div>
                        <div className="flex flex-1 flex-row items-center justify-between text-xs" style={{borderBottom:'.5px solid #aaa'}}>
                            <div className="ml-2">Confined Space</div>
                            <div>{data?.datasets[0]?.data?.[4]} ({Math.floor(100*data?.datasets[0]?.data?.[4]/(data?.datasets[0]?.data?.[0]+data?.datasets[0]?.data?.[1]+data?.datasets[0]?.data?.[2]+data?.datasets[0]?.data?.[3]+data?.datasets[0]?.data?.[4]))}%)</div>
                        </div>
                    </div>

                </div>
            </div>
      </div>
    )
}