import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
//svg
import FieldArrow from '../../svgs/permit/FieldArrow'
import FieldBin from '../../svgs/permit/FieldBin'
import BluePlus from '../../svgs/permit/BluePlus'
import BlueThreeDot from '../../svgs/permit/BlueThreeDot'
import DropArrow from '../../svgs/permit/DropDownArrow'

//
import TableColumn from './TableColumn';
import { PERMIT_FIELD_TYPE } from '../../dto/permit';
const createBlock = (type) => {
    const picked = type !== undefined ? type : 'text'
    return {
        type: picked,
        data: ''
    }
}
function DateField({i, k,handleFieldDelete, fieldObj, len, handleDataChangeWithKey,localUpdateField,handleFieldReorder,handleUpdateData }){
    const [fieldDrop, setFieldDrop] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    useEffect(() => {
        if(firstLoad){
            if(!fieldObj.tableTitle)fieldObj.tableTitle = ''
            setFirstLoad(false)
        }
        if(fieldObj.fieldData.length === 0){addColumn()
        addColumn()
        addRow()}
    },[])
    function handleChangeTitle(e){
        const temp = {...fieldObj}
        temp.tableTitle = e.target.value
        handleUpdateData(i,k,'tableTitle', temp)
    }
    function changeColumnType(colIdx, type){
        if(fieldObj.fieldData[colIdx].type === type) return;
        const tempData = [...fieldObj.fieldData]
        tempData[colIdx].type = type
        if(type === 'checkbox'){
            tempData[colIdx].colData.forEach((data,i) =>tempData[colIdx].colData[i] = false) 
        }else{
            tempData[colIdx].colData.forEach((data,i) =>tempData[colIdx].colData[i] = '') 
        }
        handleUpdateData(i,k,'fieldData', tempData)
    }
    function removeColumn(colIdx){
        const tempData = [...fieldObj.fieldData]
        tempData.splice(colIdx, 1)
        handleUpdateData(i,k,'fieldData', tempData)
    }
    function addColumn(){
        const tempData = [...fieldObj.fieldData]
        const newColumn = {type: 'text',title:'',colData:new Array((tempData[0]?.colData?.length || 0)).fill('')}
        tempData.push(newColumn)
        handleUpdateData(i,k,'fieldData', tempData)

    }
    function addRow(){
        const tempData = [...fieldObj.fieldData]
        if(tempData.length === 0) return addColumn()
        const newTempData = tempData.map((obj, i) => {
            if(obj.type === 'checkbox'){
                obj.colData.push(false)
            }else{
                obj.colData.push('')
            }
            return obj
        })
        handleUpdateData(i,k,'fieldData', newTempData)

    }
    function DeleteRow(idx){
        const tempData = [...fieldObj.fieldData]
        const newTempData = tempData.map((obj, i) => {
            obj.colData.splice(idx, 1)
            return obj
        })
        handleUpdateData(i,k,'fieldData', newTempData)

    }
    function updateBlock(colIdx, blockIdx, type, e, isTitle = false){
        const tempData = [...fieldObj.fieldData]
        if(isTitle) {
            tempData[colIdx].title = e.target.value
            return handleUpdateData(i,k,'fieldData', tempData)
        }
        const value = type === 'checkbox' ? e.target.checked : e.target.value
        tempData[colIdx].colData[blockIdx] = value
        // tempData[colIdx][blockIdx].type = type
        handleUpdateData(i,k,'fieldData', tempData)

    }
    return(
        <div key={'fieldObj' + fieldObj.feildId + k} className='text-white my-[24px] select-none'>
            <div key={'section' + k + 'div'} className='flex flex-row'>
                {/* this is the left Bar */}
                <div className='w-[8px] rounded-full' style={{background: 'linear-gradient(180deg, #654CFF 0%, #3D2E99 100%)'}}></div>
                {/* ^^^ this is the left Bar ^^^ */}
                <div className='flex flex-col flex-1 p-[32px] bg-[#FFFFFF12]'>
                    <div className='flex flex-row justify-between'>
                        <div>Field</div>
                        <div className='flex flex-row gap-2'>
                        {k !== 0 && <FieldArrow className='cursor-pointer' onClick={() => {handleFieldReorder('up', i, k)}}/>}
                        {k !== len - 1 && <FieldArrow className='rotate-180 cursor-pointer' onClick={() => {handleFieldReorder('down', i, k)}}/>}
                        <FieldBin className="cursor-pointer" onClick={()=>{handleFieldDelete(i,k)}}/>
                    </div>
                    </div>
                    <div className='flex flex-row justify-between gap-4 mt-[32px] border-b-[1px] border-[#FFFFFF4D] pb-[32px]'>
                        <div className='flex flex-col' style={{flex:4}}>
                            <div className='mb-[8px]'>Table Title*</div>
                            <input id='TDTitle' onChange={(e) => handleChangeTitle(e)} value={fieldObj.tableTitle} className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Please enter title'></input>
                        </div>
                        <div className='relative flex flex-col' style={{flex: 1}}>
                            <div className='mb-[8px]'>Type*</div>
                            <div onClick={() => setFieldDrop(!fieldDrop)} className='cursor-pointer border rounded-full px-[16px] py-[2px] flex flex-row items-center justify-between'>{fieldObj.type || 'Text input'}<DropArrow/></div>
                            {fieldDrop && 
                            <div className='z-99' style={{zIndex:999999999}}>
                                <div className='w-[100vw] h-[100vh] fixed inset-0 bg-[#00000080]' onClick={() => setFieldDrop(false)}></div>
                                <div className='absolute top-[100%] bg-black w-full rounded border bg-black z-50'>
                                    {PERMIT_FIELD_TYPE.map((text) => (
                                       <div key={text+'div'} className='py-[2px] px-[16px] cursor-pointer hover:text-black hover:bg-white' onClick={() => {localUpdateField(text)}}>{text}</div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='mt-[32px]' style={{flex:4}}>
                        <div className='mb-[8px] flex justify-end'>
                            <div className='flex flex-row items-center text-[#63BDFF] bg-[#00202A] py-[8px] px-[16px] rounded-full cursor-pointer hover:font-bold' onClick={() => addColumn()}><BluePlus className='mr-[2px]'/> Add Column</div>
                        </div>
                        <div className='flex flex-row'>
                            {fieldObj?.fieldData?.length > 0 && fieldObj?.fieldData?.map((objCol, colIdx) => {
                                return (
                                    <TableColumn key={'col'+colIdx + 'comp'}objCol={objCol} colIdx={colIdx} changeColumnType={changeColumnType} removeColumn={removeColumn} updateBlock={updateBlock}/>
                                )
                            })}
                            <div>
                                <div className='px-[8px] flex flex-row items-center' style={{visibility:'hidden'}}><BlueThreeDot className='mr-[2px]'/></div>
                                <div className='py-[1px]' style={{visibility:'hidden'}}>
                                    <div type='text' className='border rounded-full w-[10px] bg-transparent py-[16.5px] text-[14px]'></div>
                                </div>                                
                                {fieldObj.fieldData[0] && fieldObj.fieldData[0].colData.map((objCol, colIdx) => {
                                    return (
                                    <div key={'bin' + colIdx} className='flex-1 py-[18.5px] px-[8px] cursor-pointer' style={{background: colIdx % 2 === 0 ? 'transparent' : '   '}}>
                                        <FieldBin onClick={() => DeleteRow(colIdx)}/>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div onClick={addRow} className='flex flex-row items-center justify-center w-full px-[8px] bg-[#00202A] rounded-full mt-[24px] text-[#63BDFF] cursor-pointer hover:'><BluePlus /> Add Row</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(DateField)