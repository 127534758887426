import { server_ip } from "../config";

async function fetchSmartWatch(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/watches/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editWatchRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/watches/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createWatchRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/watches`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteWatchRecord(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/watches/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteWatchAlert(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/events/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function fetchWatchEvent(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"WATCH"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchWatchEventStats(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/statistic`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"WATCH"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function sendWatchMessage(token,projectId,deviceId,title,message) {
    try {
        const response = await fetch(`${server_ip}/api/watches/message`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                projectId,
                title,
                deviceId,
                message
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchEventStatsByType(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/statistic`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function updateWatchSetting(token,projectId,deviceId,type,data) {
    try {
        const response = await fetch(`${server_ip}/api/watches/setting`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                projectId,
                type,
                deviceId,
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    fetchSmartWatch,
    editWatchRecord,
    createWatchRecord,
    deleteWatchRecord,
    fetchWatchEvent,
    deleteWatchAlert,
    fetchWatchEventStats,
    sendWatchMessage,
    fetchEventStatsByType,
    updateWatchSetting
}