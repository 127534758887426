import React, {useState} from 'react';
import { connect } from 'react-redux';
//svgs
import DropDownArrow from '../../../../svgs/permit/DropDownArrow'
function FormDropDown({isPreview, sectionIdx, fieldIdx, fieldObj,handleUpdateData}) {
    const {fieldData:{title,options, data}, fieldId, fullWidth, required} = fieldObj
    const optionsArr = (options?.split(';') || false)
    const [isDropped, setIsDropped] = useState(false)
    function handleUpdateDropdown(val){
        if(isPreview) return
        handleUpdateData(val)
        setIsDropped(false)
    }
    return (
    <div key={fieldId} className={'relative flex flex-col px-[12px] mt-[24px] w-[50%]'}>
        <div className='mb-[8px] text-[16px]'>{title}</div>
        <div onClick={() => setIsDropped(true)} className='h-[33px] border border-[#FFFFFF80] rounded-full px-[16px] flex flex-row items-center justify-between'>
            {data || 'pick one'}<DropDownArrow />
        </div>
        {isDropped && 
        <div className='select-none' style={{zIndex:9999}}>
            <div className='w-[100vw] h-[100vh] fixed inset-0 bg-[#00000080]' onClick={() => setIsDropped(false)}></div>
            <div className='absolute top-[100%] bg-black w-full rounded border border-[#FFFFFF80] bg-black z-50'>
                {optionsArr && optionsArr.map((text,i) => (
                    <div key={text+'drop'+i} className='py-[2px] px-[12px] cursor-pointer hover:text-black hover:bg-white' onClick={() => handleUpdateDropdown(text)}>{text}</div>
                ))}
            </div>
        </div>
        }
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormDropDown)