import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color||"#FFCD1A"}
      d="M1.5 14.917c0 1.008.825 1.833 1.833 1.833h7.334a1.839 1.839 0 0 0 1.833-1.833v-11h-11v11ZM3.333 5.75h7.334v9.167H3.334V5.75Zm6.876-4.583L9.292.25H4.709l-.917.917H.583V3h12.834V1.167h-3.208Z"
    />
  </svg>
)
export default SvgComponent
