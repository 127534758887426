import React from "react";
import {getDateOnly,getTimeOnly} from '../../actions/actionsDateTime'
function createHistory(obj, idx, arrLen){
    const detailArr = []
    const {modifyDate} = obj
    for(const [type, val] of Object.entries(obj.data)){
        if(obj.data.permit_id) continue;
        if(type === 'created'){
            detailArr.push((<div key={'CREATE' + idx} className=''>/ Permit created<span className=""></span></div>))
        }else if(type === 'remark'){
            detailArr.push((<div key={'CREATE' + idx} className=''>/ Added Remark: <br/> <span className="">{val}</span></div>))
        }else if(type === 'data'){
            const changedArr = []
            for(const [dataKey, dataVal] of Object.entries(val)){
                console.log(dataKey)
                if(['newlyApproved', 'newlyRead'].includes(dataKey)){
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'>/ {dataKey.split('newly')[1]} Permit <br/> <span className=""></span></div>))
                }else if(['added_approvers','added_receivers','added_viewers'].includes(dataKey)){
                    const nameJSX = dataVal.map(name => <span key={name + Math.random()}>- {name} <br/></span>)
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'> / Added {dataKey.split('_')[1]}:<br/> <span className="font-normal">{nameJSX}</span></div>))
                }else if(['removed_approvers','removed_receivers','removed_viewers'].includes(dataKey)){
                    const nameJSX = dataVal.map(name => <span key={name + Math.random()}>- {name} <br/></span>)
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'> / Removed {dataKey.split('_')[1]}:<br/> <span className="font-normal">{nameJSX}</span></div>))
                }else if(dataKey === 'permit_newAttachement'){
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'> / Added Attachment{'(s)'}</div>))
                }else if(dataKey.split('_')[0] === 'remove'){
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'>/ Removed Attachment{'(s)'}</div>))
                }else if(['Permit_validFrom', 'Permit_validTo'].includes(dataKey)){
                    detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'>/ Changed {dataKey.split('_')[1]}:<br/> <span className="font-normal">-{dataVal}</span></div>))
                }else{
                    const [section, fieldType, title] = dataKey.split('_')
                    if(fieldType === 'Select Box'){
                        changedArr.push(<span key={Math.random()} className="ml-[6px]">
                            {section + ' ' + title}:<br/><span className="ml-[24px]">- {dataVal[0].join(' / ')}</span><br/>
                        </span>)
                    }else if(fieldType === 'Text input'){
                        changedArr.push(<span key={Math.random()} className="ml-[6px]">
                            {section + ' ' + title}:<br/><span className="ml-[24px]">- {dataVal}</span><br/>
                        </span>)
                    }else if(fieldType === 'Date'){
                        changedArr.push(<span key={Math.random()} className="ml-[6px]">
                            {section + ' Date ' + title}:<br/><span className="ml-[24px]">- {new Date(dataVal).toLocaleString()}</span><br/>
                        </span>)
                    }else if(dataKey === 'status'){
                        changedArr.push(<span key={Math.random()} className="ml-[6px]">
                            {dataKey.split('_').join(' ')}:<span className=""> {dataVal}</span><br/>
                        </span>)
                    }else{
                        changedArr.push(<span key={Math.random()} className="ml-[6px]">
                            <span className="">{dataKey.split('_').join(' ')}</span>:<br/><span className="ml-[24px]">- {typeof dataVal === 'string' ? dataVal : dataVal.length === 1 ? dataVal[0] : dataVal.join(' ') || 'no details'}</span><br/>
                        </span>)
                    }
                }
            }
            if(changedArr.length > 0)detailArr.push((<div key={'dataVal' + Math.random()} className='font-bold'> / Changed:<br/> <span className="font-normal">{changedArr}</span></div>))
        }
    }
    //detailArr.push((<div key={'NO' + idx} className=''><span className="font-bold">/ No Records</span></div>)
    if(detailArr.length === 0) detailArr.push((<div key={'NO' + idx} className=''><span className="font-bold">/ No Changes or History</span></div>))
    return (
        <div key={obj._id + idx + 'main'} className='flex flex-row overflow-y-hidden'>
            <div className='flex flex-row flex-1'>
                <div className='flex flex-col items-center relative mt-[16px]'>
                    <div className='text-[20px] font-bold text-white py-[5px] px-[14px] border rounded-full border-[#FFFFFF80]'>{idx + 1}</div>
                    {arrLen - 1 !== idx && <div className='mt-2 w-[1px] flex-1 border border-[#FFFFFF40]'></div>}
                </div>
                <div className='bg-[#FFFFFF12] p-[24px] flex flex-row ml-[32px] flex-1 mb-[32px]'>
                    <div>
                        <div className='flex flex-row items-center py-[6px] px-[16px] gap-2 rounded-full' style={{borderWidth:1, borderColor:'#FFFFFF80'}}>
                            <div className='text-white text-nowrap'>{getDateOnly(modifyDate)}</div>
                            <div className='text-white'>{getTimeOnly(modifyDate)}</div>
                        </div>
                        <div className='text-[#A1A1A1] mt-[8px]'>Updated by {(obj.modifiedByUser[0].username || 'Unknown')}</div>
                    </div>
                    <div className='text-white ml-[72px] flex-1'>
                        {detailArr}
                    </div>
                </div>
            </div>
        </div>
    )
}

function DetailHistory({historyArr, isReversed}){
    const detailJSX = generateHistory()
    function generateHistory(){
        if(!historyArr) return
        const newArr = []
        const reversedHistory = isReversed ? [...historyArr].reverse() : [...historyArr]

        for(let i = 0 ; i < reversedHistory.length; i++){
            const hasInfo = createHistory(reversedHistory[i], i, reversedHistory.length)
            newArr.push(hasInfo)
        }
        return newArr
    }
    return(
        <div className='mt-[24px]'>
            {detailJSX}
        </div>
    )
}
export default DetailHistory 