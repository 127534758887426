import React from "react"

function LoadingPage({TF, SA}){ 
    if(TF !== undefined){
        return(
        <div className="" style={{display:'inline-block'}}>
            <div className='rounded-full py-[2px] px-[16px]' style={TF ? {backgroundColor:'#29FFD940', color:'#29FFD9'} : {backgroundColor:'#FFC32940', color:'#FFC329'}}>
                {TF ? 'Wearing' : 'Not Wearing'}
            </div>
        </div>
        )
    }else{
        return(
            <div className="" style={{display:'inline-block'}}>
                <div className='rounded-full py-[2px] px-[16px]' style={SA ? {backgroundColor:'#115B4E', color:'#29FFD9'} : {backgroundColor:'#737373'}}>
                    {SA ? 'Active' : 'Sleep'}
                </div>
            </div>
        );
    }
}

export default LoadingPage 
