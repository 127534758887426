import '../tracking/CreatePopUp.css'
import React, {useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
//service
import {fetchTemplateList} from '../../services/permit.service'
//svg
import WhiteCloseSvg from '../../svgs/WhiteCloseSvg'

function CreatePopUp(props){
    const navigate = useNavigate();
    const [filterType, setFilterType] = useState('All')
    const [permitData, setPermitData] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({})
    useEffect(() => {
        fetchPermitData()
    },[filterType])
    async function fetchPermitData(){
        const filter = {}
        if(filterType !== 'All') filter['type'] = {"$eq": `${filterType}`}
        filter.projectId = props.control.projectId
        const res = await fetchTemplateList(props.token.token, filter, {})
        if(res.errno) return console.log('error fetching data')

        setPermitData(res.result)
        // console.log(res.result)
    }
    function handleSelectedTemplate(obj){
        const temp = {...obj}
        temp.createdBy = props.control.userData._id
        setSelectedTemplate(temp)
    }
    return (
        <div className="w-[35%] min-w-[684px] h-[646px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
            <div className="flex flex-row justify-between mb-8">
                <div className="text-[18px] font-bold">Select Template</div>
                <WhiteCloseSvg className='cursor-pointer' onClick={() => {props.handlePermPop(false)}}/>
            </div>
            <div className='flex flex-row items-center'>
                {['All', 'Permit to Work', 'Permit to Move', 'Permit to Operate', 'Other Permit'].map((type) => <div key={type} onClick={() => setFilterType(type)}className='p-[8px] text-nowrap flex-1 cursor-pointer select-none' style={filterType === type ? {borderBottomColor:'white', borderBottomWidth:1} : {borderBottomColor:'#FFFFFF80', borderBottomWidth:1}}>
                    {type}
                </div>)}
            </div>
            <div className='w-full h-[354px] overflow-y-scroll mt-[32px]'>
                {permitData.length > 0 && permitData.map((permitObj, i) => <div key={permitObj._id} className='flex flex-row items-center mb-[16px]'>
                        <label className='relative w-[16px] h-[16px] p-[1px] border mr-[12px] rounded-full'>
                            <input className='absolute opacity-0 cursor-pointer' onChange={() => handleSelectedTemplate(permitObj)} type="checkbox" />
                            <div style={{backgroundColor: selectedTemplate._id === permitObj._id ? 'white' : 'transparent'}}className='h-full w-full rounded-full aspect-square'></div>
                        </label>
                        <div>{permitObj.name} <span className='text-[#949494]'>| {permitObj.templateType || permitObj?.type}</span></div>
                </div>)}
            </div>
            <div className="flex flex-col mb-4">
                <div onClick={() => navigate('/permit/create', {state:{data:selectedTemplate}})} type="submit" className='flex flex-row items-center justify-center bg-[#00202A] text-[#63BDFF] border-none h-[40px] rounded-full cursor-pointer mt-[32px]'>Select</div>
            </div>
        </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(CreatePopUp)