import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
//svg
import FieldArrow from '../../svgs/permit/FieldArrow'
import FieldBin from '../../svgs/permit/FieldBin'
import ImgClose from '../../svgs/permit/ImgClose'
import { server_ip } from '../../config';
import { PERMIT_FIELD_TYPE } from '../../dto/permit';
function DateField({isEdit, i, k, handleUpdateRequirements, sectionId, handleFieldDelete, fieldObj, len, handleDataChangeWithKey,localUpdateField,handleFieldReorder }){
    
    const [fieldDrop, setFieldDrop] = useState(false)
    const fileUploadRef = useRef()
    const [imgEle, setImgEle] = useState(undefined)
    const [existingImgIdArr, setExistingImgIdArr] = useState([])
    const [toDeleteImgArr, setToDeleteImgArr] = useState([])
    useEffect(() => {
        if(fieldObj.fieldData?.imgId && isEdit) setExistingImgIdArr(fieldObj.fieldData?.imgId)
        if(fieldObj.fieldData.toDelete === undefined)handleDataChangeWithKey('toDelete', [])
        if(fieldObj.fieldData.newImgId === undefined)handleDataChangeWithKey('newImgId', [])
        if(fieldObj.fieldData.imgId === undefined)handleDataChangeWithKey('imgId', [])
        const tempImg = []
        if(fieldObj.fieldData.formData instanceof FormData){
            for(const obj of fieldObj.fieldData.formData.entries()){
                const objectURL = URL.createObjectURL(obj[1]);
                tempImg.push(objectURL)
            }
        }
        setImgEle(tempImg)
    },[])
    function handleDeleteExistingFile(i){
        const target = existingImgIdArr[i]
        const temp = [...existingImgIdArr]
        temp.splice(i,1)
        setToDeleteImgArr([...toDeleteImgArr, target])
        setExistingImgIdArr(temp)
        handleDataChangeWithKey('toDelete', [...toDeleteImgArr, target])
        handleDataChangeWithKey('imgId', temp)
    }
    function handleDeleteFile(i){
        const newItem = new FormData();
        const imgElement = []
        let idx = 0
        for(const obj of fieldObj.fieldData.formData.entries()){
            if(idx !== i){
                newItem.append(obj[0], obj[1])
                const objectURL = URL.createObjectURL(obj[1]);
                imgElement.push(objectURL)
            }
            idx++
        }
        const newId = [...fieldObj.fieldData.newImgId]
        newId.splice(i,1)
        // for(const [name,img] of newItem.entries()){
        //     console.log('new',name, img)
        // }
        setImgEle(imgElement)
        handleDataChangeWithKey('formData', newItem)
        handleDataChangeWithKey('newImgId', newId)
    }
    function handleFileChange(e){
        const newItem = new FormData();
        const imgElement = []
        const imgIds = []
        for(let i = 0; i < e.target.files.length; i++){
            newItem.append(e.target.files[i].name, e.target.files[i])
            const objectURL = URL.createObjectURL(e.target.files[i]);
            imgElement.push(objectURL)
            imgIds.push({id:`${sectionId}_${fieldObj.fieldId}_${i}_${new Date().getTime()}`,type: e.target.files[i].type,formAction: null})
        }
        setImgEle(imgElement)
        handleDataChangeWithKey('formData', newItem)
        handleDataChangeWithKey('newImgId', imgIds)
    }
    return(
        <div key={'fieldObj' + fieldObj.feildId + k} className='text-white my-[24px]'>
            <div className='flex flex-row'>
                {/* below is the left Bar */}
                <div className='w-[8px] rounded-full bg-[#FFFF00]' style={{background: 'linear-gradient(180deg, #FFE454 0%, #998932 100%)'}}></div>
                {/* ^^^ this is the left Bar ^^^ */}
                <div className='flex flex-col flex-1 p-[32px] bg-[#FFFFFF12]'>
                    <div className='flex flex-row justify-between'>
                        <div>Image</div>
                        <div className='flex flex-row gap-2'>
                        <div className='flex flex-row items-center mr-[32px]'>
                        <div className='mr-[8px]'>Full Width</div>
                            <div onClick={()=>{handleUpdateRequirements('fullWidth', !fieldObj.fullWidth)}} className='px-[2px] w-[40px] h-[22px] rounded-full flex flex-row items-center cursor-pointer' style={fieldObj.fullWidth ? {justifyContent:'flex-end', backgroundColor:'#63BDFF', border: '2px solid #63BDFF'} : {justifyContent:'flex-start', border: '2px solid #777676'}}>
                                <div className='w-[16px] h-[16px] py-0 bg-white rounded-full' style={fieldObj.fullWidth ? {backgroundColor:'#00202A'} : {backgroundColor:'#777676'}}></div>
                            </div>
                        </div>
                        {k !== 0 && <FieldArrow className='cursor-pointer' onClick={() => {handleFieldReorder('up', i, k)}}/>}
                        {k !== len - 1 && <FieldArrow className='rotate-180 cursor-pointer' onClick={() => {handleFieldReorder('down', i, k)}}/>}
                        <FieldBin onClick={()=>{handleFieldDelete(i,k)}}/>
                    </div>
                    </div>
                    <div className='flex flex-row justify-between gap-4 mt-[32px]'>
                        <div className='flex flex-col' style={{flex:4}}>
                            <div className='mb-[8px]'>Image Title*</div>
                            <input onChange={(e) => handleDataChangeWithKey('imageTitle',e.target.value)} value={fieldObj.fieldData.imageTitle} className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Please enter section title'></input>
                        </div>
                        <div className='relative flex flex-col' style={{flex: 1}}>
                            <div className='mb-[8px]'>Type*</div>
                            <div onClick={() => setFieldDrop(!fieldDrop)} className='cursor-pointer border rounded-full px-[16px] py-[2px]'>{fieldObj.type || 'Text input'}</div>
                            {fieldDrop && <div className='absolute top-[100%] bg-black w-full rounded border bg-black z-50'>
                                {PERMIT_FIELD_TYPE.map((text) => (
                                    <div key={text+'div'} className='py-[2px] px-[16px] cursor-pointer hover:text-black hover:bg-white' onClick={() => localUpdateField(text)}>{text}</div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    <div className='flex flex-col mt-[32px]' style={{flex:1}}>
                        <div className='mb-[8px]'>Image Description</div>
                        <input onChange={(e) => handleDataChangeWithKey('imageDescription',e.target.value)} value={fieldObj.fieldData.imageDescription} className='border bg-transparent rounded-full px-[16px] py-[2px]' placeholder='Please enter section title'></input>
                    </div>
                    <div className='mt-[32px]'>
                        <div>{"File Attachent(0)"}</div>
                        <div className='flex flex-row items-center flex-wrap'>
                            <div className='mr-[24px] mt-[8px] h-[34px] py-[8px] px-[140px] border rounded-full inline-block cursor-pointer flex flex-row items-center' onClick={() => {fileUploadRef.current.click()}}>Upload File</div>
                            <form onChange={(e) => {handleFileChange(e)}}>
                                <input ref={fileUploadRef} type='file' multiple accept="image/png, image/jpeg" className='mt-[8px] py-[8px] px-[140px] border rounded-full inline-block hidden'></input>
                            </form>
                            {imgEle && imgEle.map((im,i) => (
                                <div key={'img' + i} className='overflow-visible relative w-[76px] h-[78px]'>
                                    <img src={im} className='absolute bottom-0 w-[68px] h-[68px] blur-sm'></img>
                                    <div onClick={() => handleDeleteFile(i)} className='absolute right-0 cursor-pointer w-[20px] h-[20px] bg-[#5F5F5FD1] flex flex-row items-center justify-center rounded-full'>
                                        <ImgClose className=''/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <div className='mt-[12px]'>Existing Files</div>
                            <div className='flex flex-row mt-[12px] gap-2'>
                                {existingImgIdArr.length > 0 && existingImgIdArr.map(({id, type}, i) => <div key={'exist'+i} className='overflow-visible relative w-[76px] h-[78px]'>
                                        <a href={server_ip+'/api/documents/'+id+'.'+ type.split('/')[1]} target="_blank">
                                            <img target="_blank" rel="noreferrer" key={id} className='absolute bottom-0 w-[68px] h-[68px]' src={server_ip+'/api/documents/'+id+'.'+ type.split('/')[1]}></img>
                                        </a>
                                        <div onClick={() => handleDeleteExistingFile(i)} className='absolute right-0 cursor-pointer w-[20px] h-[20px] bg-[#5F5F5FD1] flex flex-row items-center justify-center rounded-full'>
                                            <ImgClose className=''/>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                        <div className='mt-[16px] text-[#8E8E8E] text-[14px]'>*File format accepted: JPG, JPEG, PNG</div>
                        <div className='text-[#8E8E8E] text-[14px]'>*Maximum file Size: 10 MB</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(DateField)