import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={props.color||"#717171"}
      d="M11.999 6.167h-.834V4.5C11.165 2.2 9.3.333 7 .333A4.168 4.168 0 0 0 2.832 4.5v1.667h-.833c-.917 0-1.667.75-1.667 1.666v8.334c0 .916.75 1.666 1.667 1.666h10c.916 0 1.666-.75 1.666-1.666V7.833c0-.916-.75-1.666-1.666-1.666ZM4.499 4.5c0-1.383 1.116-2.5 2.5-2.5 1.383 0 2.5 1.117 2.5 2.5v1.667h-5V4.5Zm7.5 11.667h-10V7.833h10v8.334Zm-5-2.5c.916 0 1.666-.75 1.666-1.667S7.915 10.333 7 10.333c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667Z"
    />
  </svg>
)
export default SvgComponent
