import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <g fill="#FF969C" clipPath="url(#aerror)">
      <path d="M8.501 14.499a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667ZM7.168 2.5h2.667v8H7.168v-8Z" />
    </g>
    <defs>
      <clipPath id="aerror">
        <path fill="#fff" d="M.5.5h16v16H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
