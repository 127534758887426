import { server_ip } from "../config";

async function fetchSmartLock(token,filter={},options={}) {
    try {
        const response = await fetch(`${server_ip}/api/locks/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter},
                "options":{"limit":99,...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function editLockRecord(token,_id,data) {
    try {
        const response = await fetch(`${server_ip}/api/locks/${_id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function createLockRecord(token,data) {
    try {
        const response = await fetch(`${server_ip}/api/locks`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function deleteLockRecord(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/locks/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}

async function remoteUnlock(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/locks/unlock/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function remoteLock(token,id) {
    try {
        const response = await fetch(`${server_ip}/api/locks/lock/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchLockingEvent(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/fetch`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"LOCK"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
async function fetchLockEventStats(token, filter, options){
    try {
        const response = await fetch(`${server_ip}/api/events/statistic`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "filter":{...filter, device:"LOCK"},
                "options":{...options}
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    fetchSmartLock,
    editLockRecord,
    createLockRecord,
    deleteLockRecord,
    remoteUnlock,
    remoteLock,
    fetchLockingEvent,
    fetchLockEventStats,
}