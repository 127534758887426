import React, {useState} from 'react';
import { connect } from 'react-redux';
import BlueThreeDot from '../../svgs/permit/BlueThreeDot'
import ColumnPen from '../../svgs/permit/ColumePen'
import ColumnBin from '../../svgs/permit/ColumnBin'
function TableColumn({objCol, colIdx, changeColumnType,removeColumn,updateBlock}){
    const [blueToggle, setBlueToggle] = useState(false)
    return (
    <div key={'col' + colIdx + objCol.type} className='flex-1 relative min-w-[200px]'>
        <div className='px-[8px] flex flex-row items-center'><BlueThreeDot className='mr-[2px] cursor-pointer' onClick={() => setBlueToggle(!blueToggle)}/>
            <div key={colIdx + 'title'} className='py-[16px] px-[8px] flex-1'>
                <input type='text' value={objCol.title} onChange={(e) => {updateBlock(colIdx, null, objCol.type, e, true)}} className='border rounded-full w-full bg-transparent px-[24px] py-[6px] text-[14px]' placeholder={'Please input title'} ></input>
            </div>
        </div>
        {blueToggle && 
        <div className='' style={{zIndex:999}}>
            <div style={{zIndex:1}} className='w-[100vw] h-[100vh] fixed inset-0 bg-[#00000080]' onClick={()=>setBlueToggle(false)}></div>
            <div className='absolute px-[8px] py-[10px]  border border-[#FFFFFF80] w-[170px]' style={{zIndex:2,background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(7, 28, 52, 0.7) 100%)'}}>
                <div className='ml-[8px] text-[14px] cursor-pointer hover:font-bold flex flex-row items-center justify-between' onClick={() => {changeColumnType(colIdx, 'text');setBlueToggle(false)}}><div>Text</div><ColumnPen /></div>
                <div className='w-full h-[1px] bg-[#FFFFFF80]'/>
                <div className='ml-[8px] text-[14px] cursor-pointer hover:font-bold flex flex-row items-center justify-between' onClick={() => {changeColumnType(colIdx, 'Text input');setBlueToggle(false)}}><div>Text input</div><ColumnPen /></div>
                <div className='w-full h-[1px] bg-[#FFFFFF80]'/>
                <div className='ml-[8px] text-[14px] cursor-pointer hover:font-bold flex flex-row items-center justify-between' onClick={() => {changeColumnType(colIdx, 'checkbox');setBlueToggle(false)}}><div>CheckBox</div><ColumnPen /></div>
                <div className='w-full h-[1px] bg-[#FFFFFF80]'/>
                <div className='ml-[8px] text-[14px] cursor-pointer hover:font-bold flex flex-row items-center justify-between' onClick={() => {removeColumn(colIdx);setBlueToggle(false)}}><div>Remove Column</div><ColumnBin /></div>
            </div>
        </div>
        }
        <div key={objCol + colIdx + 'colume'} className='flex flex-col flex-1'>
            {objCol.colData.map((colData, blockIdx) => {
                return (
                <div key={'block' + blockIdx} className={objCol.type !== 'checkbox' ? 'py-[16px] px-[8px]' : 'py-[21.5px] px-[8px]'} style={{background: blockIdx % 2 === 0 ? 'transparent' : '#FFFFFF0D'}}>
                    {objCol.type === 'text' && <input type='text' value={colData} onChange={(e) => {updateBlock(colIdx, blockIdx, objCol.type, e)}} className='border rounded-full w-full bg-transparent px-[24px] py-[6px] text-[14px]' placeholder={'Please input text'} ></input>}
                    {objCol.type === 'checkbox' && <input type='checkbox' checked={colData} onChange={(e) => {updateBlock(colIdx, blockIdx, objCol.type, e)}} className='border rounded-full w-full bg-transparent px-[24px] py-[120px] text-[14px]'></input>}
                    {objCol.type === 'Text input' && <input disabled type='text' value={colData} onChange={(e) => {updateBlock(colIdx, blockIdx, objCol.type, e)}} className='border rounded-full w-full bg-transparent px-[24px] py-[6px] text-[14px]' placeholder={'Input for user'} />}
                </div>)
            })}
        </div>
    </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(TableColumn)